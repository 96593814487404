import React, { useEffect, useState } from "react";
import WhiteContainer from "../../../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./AudienceList.module.css";
import RadioButton from "../../../../../Atoms/Inputs/RadioButtons/RadioButton";
import { useFetchAudience } from "../../../../../../Hooks/Audience";
import { useFormikContext } from "formik";
import Loader from "../../../../Loader/Loader";

const AudienceList = () => {
  const formik = useFormikContext();
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    data: audienceData,
    isLoading,
    refetch: refetchAudience,
  } = useFetchAudience({
    pageSize: 2000,
    pageNumber: 1,
  });

  const handleRadioChange = (index, item) => {
    setSelectedOption(index);
    formik.setFieldValue("audienceId", item);
  };
  useEffect(() => {
    if (audienceData && formik.values.audienceId) {
      const selectedAudienceIndex = audienceData.data?.items.findIndex(
        (item) => item.campaignAudienceId === formik.values.audienceId
      );
      setSelectedOption(selectedAudienceIndex);
    }
  }, [audienceData, formik.values.audienceId]);
  useEffect(() => {
    refetchAudience(formik.values.audienceId);
  }, [formik.values.audienceId]);
  return (
    <div className={styles["container"]}>
      <p className={styles["title"]}>Audience Name</p>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles["audience_list_container"]}>
          {audienceData?.data?.items?.map((item, index) => (
            <div
              key={item.campaignAudienceId}
              className={`${styles["row_name"]} ${
                index % 2 !== 0 ? styles["blue"] : ""
              }`}
            >
              <div
                className={selectedOption === index ? styles["leftBorder"] : ""}
              >
                <p></p>
              </div>
              <RadioButton
                name='audience'
                label={item.audienceName}
                value={index}
                checked={selectedOption === index}
                handleOnChange={() =>
                  handleRadioChange(index, item.campaignAudienceId)
                }
                red
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AudienceList;

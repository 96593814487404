import React, { useState, useRef, useEffect } from "react";
import styles from "./Navhead.module.css";
import TitlePageText from "../../Atoms/Texts/TitlePageText";
import signout from "../../../assets/Imagess/signout.svg";
import profileicon from "../../../assets/Imagess/sigmouticon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import MoreButton from "../Custom/PopOver/MoreButton";
import backICon from "../../../assets/Imagess/backArrow.svg";
import PopOver from "../Custom/PopOver/PopOver";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import BurgerSideBar from "../SideBar/BurgerSideBar";
import { useAuthenticatedUser } from "../../../Hooks/Profile";
import USerProfileIcon from "../../../assets/Imagess/usreprofileicon.svg";

const Navhead = ({ title, isArrow }) => {
  const [ShowNotifications, setShowNotifications] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const pathName = location?.pathname;
console.log("cdsjnvd",location)
  const menuRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const {
    data: authenticatedUser,
    isLoading: isLoadingAuthenticatedUser,
    refetch: refetchUser,
  } = useAuthenticatedUser();
  const handleNavigate = () => {
    navigate("/home/profile");
  };

  const handleNavigateBack = () => {
    if (title === "Email Template") {
      navigate(-1);
    } else if (title === "Contact Details" || title === "Settings") {
      navigate("/home/external-contact", {
        state: {
          selected: location?.state?.selected,
        },
      });
    } else if (title === "Audience") {
      navigate(-1);
    } else if (title === "Campaign Management") {
      navigate(-1);
    } else if (title === "Campaigns") {
      navigate(-1);
    } else {
      const routeState =
        title === "Leads Details"
          ? { selected: "inquires" }
          : { selected: "applicants" };
      navigate("/home/applicants", { state: routeState });
    }
  };

  const handleDocumentClick = (e) => {
    if (menuRef.current && !menuRef.current?.contains(e.target)) {
      setShowMenu(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current?.contains(e.target)
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className={styles["navhead-container"]}>
      <div className={styles["sidebar_subcont"]}>
        <BurgerSideBar />
      </div>
      <div className={styles["navhead-title-arrowContainer"]}>
        {isArrow && (
          <Tooltip content='Go Back'>
            <img
              src={backICon}
              alt='back'
              onClick={handleNavigateBack}
              className={styles["backIcon"]}
            />
          </Tooltip>
        )}
        <TitlePageText title={title} />
      </div>

      <div className={styles["navhead-subcontainer"]}>
        {/* <MoreButton icon={bellIcon} width='350px' toolTipText='Notifications'>
          <div className={styles["notifications-container"]}>
            <h3>Notifications</h3>
            <div>
              <h4>Lorem ipsum dolor</h4>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr</p>
            </div>
          </div>
        </MoreButton> */}
        <MoreButton
          image={
            authenticatedUser?.data?.userImage?.imageContent ? (
              <img
                src={`data:image/jpeg;base64,${authenticatedUser?.data?.userImage?.imageContent}`}
                className={styles["userProfile"]}
              />
            ) : (
              <img
                src={USerProfileIcon}
                alt='back'
                className={styles["backIcon"]}
                style={{ width: "50px" }}
              />
            )
          }
          profileImg='profile-img'
          width='200px'
          toolTipText='Profile'
        >
          <PopOver
            info={[
              ...(pathName != "/home/profile"
                ? [
                    {
                      title: "My Profile",
                      icon: profileicon,
                      handleFunction: handleNavigate,
                    },
                  ]
                : []),
              {
                title: "Sign Out",
                icon: signout,
                handleFunction: handleSignOut,
              },
            ]}
          />
        </MoreButton>
      </div>
    </div>
  );
};

export default Navhead;

import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import MainRoutes from "./routes/MainRoutes";
import { BrowserRouter } from "react-router-dom";
import { EmailProvider } from "./Context/EmailContext";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from "react-query/devtools";
import { SearchFilterProvider } from "./Context/SearchFilterContext";
import { ApplicantProvider } from "./Context/ApplicantContext";
import { TableFilterValuesProvider } from "./Context/TableFilterValues";

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
      <TableFilterValuesProvider>
      <SearchFilterProvider>
          <ApplicantProvider>
            <EmailProvider>
              <MainRoutes />
              <ToastContainer
                position='top-center'
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
              />
            </EmailProvider>
          </ApplicantProvider>
        </SearchFilterProvider>
      </TableFilterValuesProvider>
   
      </BrowserRouter>
      <ReactQueryDevtools initialISOpen={false} position='bottom-right' />
    </QueryClientProvider>
  );
}

export default App;

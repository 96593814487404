import React, { useRef, useState } from "react";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import CreateOverview from "../../../Moecules/Campaign/NewCampaign/Overview/CreateOverview";
import SelectedAudience from "../../../Moecules/Campaign/NewCampaign/SelectedAudience/SelectedAudience";
import CreateEmailWorkFlow from "../../../Moecules/Campaign/NewCampaign/CreateEmailWorkflow/CreateEmailWorkFlow";
import Header from "../../../Moecules/Campaign/NewCampaign/NewCampaignComponents/Header";
import CampaignContainer from "../../../Moecules/Campaign/NewCampaign/NewCampaignComponents/CampaignContainer";
import { useNavigate, useParams } from "react-router-dom";
import saveIcon from "../../../../assets/Imagess/Users/UsersAssignment/runIcon.svg";

import { toast } from "react-toastify";
import styles from "./NewCampaign.module.css";
import Buttons from "../../../Atoms/Buttons/Buttons";
import { useFetchCampaignById } from "../../../../Hooks/Campaign";
const NewCampaignPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const setp1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();

  const [activeStep, setActiveStep] = useState(0);

  const { data: campaignById, isLoading: isLoadingdata } =
    useFetchCampaignById(id);

  const steps = [
    {
      step: 0,
      title: "Overview",
      content: (
        <CreateOverview
          ref={setp1Ref}
          campaignDetails={
            campaignById?.data?.campaign && campaignById?.data?.campaign[0]
          }
          isLoadingdata={isLoadingdata}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      step: 1,
      title: "Select Audience",
      content: (
        <SelectedAudience
          ref={step2Ref}
          campaignDetails={
            campaignById?.data?.campaign && campaignById?.data?.campaign[0]
          }
          isLoadingdata={isLoadingdata}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      step: 2,
      title: "Email Workflow",
      content: (
        <CreateEmailWorkFlow
          ref={step3Ref}
          campaignDetails={campaignById?.data}
          isLoadingdata={isLoadingdata}
        />
      ),
    },
  ];

  const handleChangeStep = async () => {
    let formRef;
    if (activeStep === 0) formRef = setp1Ref;
    if (activeStep === 1) formRef = step2Ref;
    if (activeStep === 2) formRef = step3Ref;

    if (activeStep < 2) {
      await formRef.current.submitForm();

      const formIsValid = formRef.current?.isValid;
      if (!formIsValid) {
        toast.error(
          activeStep === 1
            ? "Please choose an audience"
            : "Fill all required fields"
        );
      }
    } else {
      formRef.current.handleAddStep3();
    }
  };

  return (
    <WrappingContainer>
      <Header
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNavigation={handleChangeStep}
      />
      <CampaignContainer step={steps[activeStep]} />

      <div className={styles["mobile_buttons"]}>
        <div></div>
        <Buttons
          text={activeStep == 2 ? (id ? "Update" : "Save") : "Next"}
          rounded={true}
          color='#F3223C'
          icon={saveIcon}
          handleOnClick={handleChangeStep}
        />{" "}
      </div>
    </WrappingContainer>
  );
};

export default NewCampaignPage;

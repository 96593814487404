import React, { useEffect, useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import Logs from "../../Custom/Logs/Logs";
import styles from "./CampaignLogs.module.css";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";

const options = [
  { value: 0, text: "Latest" },
  { value: 1, text: "Earliest" },
];

const CampaignLogs = ({ campaignDetails }) => {
  const [sortOrder, setSortOrder] = useState(0); 
  const [logsToMap, setLogsToMap] = useState([]);

 
  useEffect(() => {
    if (campaignDetails) {
      const transformedLogs = campaignDetails.map((item) => ({
        applicantLogId: item.campaignLogId,
        logId: item.logType,
        logStatus: item.logStatus,
        logDate: item.log.createdOn,
      }));

     
      const sortedLogs = transformedLogs.sort((a, b) => {
        return new Date(b.logDate) - new Date(a.logDate);
      });

      setLogsToMap(sortedLogs);
    }
  }, [campaignDetails]);


  const handleSortChange = (selectedOption) => {
    const order = selectedOption.value;
    setSortOrder(order);

    const sortedLogs = [...logsToMap].sort((a, b) => {
      if (order === 0) {
        return new Date(b.logDate) - new Date(a.logDate); 
      } else {
        return new Date(a.logDate) - new Date(b.logDate); 
      }
    });

    setLogsToMap(sortedLogs);
  };

  return (
    <WhiteContainer white>
      <div className={styles["history"]}>
        <h4>History Logs</h4>
        <CustomizedSelect
          noborder={true}
          placeholder='Most Recent'
          options={options}
          value={sortOrder}
          onChange={handleSortChange}
          unClear
        />
      </div>

      <Logs logs={logsToMap} />
    </WhiteContainer>
  );
};

export default CampaignLogs;

import React from "react";
import styles from "./ApplicantLogs.module.css";
import moment from "moment";
import HorizantalLine from "../../../Atoms/Lines/HorizantalLine";

const EmailSent = ({ selectedEmail }) => {
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // const hdcb = (newContent) => {
  //   const wrappedContent = `<!DOCTYPE html>
  // <html>
  // <head>
  //     <meta charset="utf-8" />
  //     <title></title>
  // </head>
  // <body>
  //     ${newContent}
  // </body>
  // </html>`;
  //   return wrappedContent;
  // };
  console.log("vdsnkjvnds", selectedEmail);
  const isLog =
    selectedEmail && selectedEmail.logDate && selectedEmail.logStatus;

  return (
    <div className={styles["email-sent-cont"]}>
      <div className={styles["email-sent-header"]}>
        <div>
          <h4>
            {isLog ? "Log Status" : "Subject: "}
            <span>
              {isLog ? selectedEmail.logStatus : selectedEmail.subject}
            </span>
          </h4>
        </div>
        {isLog ? (
          <h4>{moment(selectedEmail.logDate).format("MMM D, YYYY h:mm A")}</h4>
        ) : (
          ""
        )}
      </div>
      <HorizantalLine />
      {!isLog && (
        <div className={styles["email-content"]}>
          <p dangerouslySetInnerHTML={{ __html: selectedEmail.content }}></p>
        </div>
      )}
      {/* <div className={styles["attachment-cont"]}>
        <h4>Attachment</h4>
        <img src={fileSttachment} alt='fileattachemnt' />
        {selectedEmail.attachment ? (
          <>
            {selectedEmail.attachment}{" "}
            <img src={availableIcon} alt='available' />{" "}
          </>
        ) : (
          <>
            No Attachments <img src={UnavailableIcon} alt='unavailable' />
          </>
        )}
      </div> */}
    </div>
  );
};

export default EmailSent;

import React, { forwardRef } from "react";
import TableComponent from "../../TableComponent/TableComponent";
import styles from "./ExportComponent.module.css";

const PrintComponent = forwardRef(({ title, data, children }, ref) => {
  return (
    <div ref={ref} className={`${styles.export_container} ${styles['printOnlyCont']}`}>
      <div className={styles.print_only}>
        <h1>{title}</h1>
      </div>

      {data ? (
        <TableComponent tableData={data} noScroll />
      ) : (
        <div className={`${styles.export_container} ${styles['printOnlyCont']}`}>
          {children}
        </div>
      )}
    </div>
  );
});

export default PrintComponent;

import React from "react";
import styles from "./ApplicationAnalysis.module.css";
import Rate from "./Rate/Rate";
import SourceTypeChart from "./SourceTypePieChart/SourceTypeChart";
import SourceChannels from "./SourceChannels/SourceChannels";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import AboutAudChart from "./AboutAud/AboutAudChart";
import ApplicationCategories from "./ApplicationCategories/ApplicationCategories";
import DashboardCategories from "./Categories/DashboardCategories";
import TimeOfAcceptance from "./TimeOfAcceptance/TimeOfAcceptance";
import FieldOfInterest from "./FieldOfInterest/FieldOfInterest";
const ApplicationAnalysis = () => {
  const data = [
    {
      title: "Applicants Retention",
      text: "Percentage of applicants who complete the entire application process",
      percentage: "0",
      color: "#3886BD",
    },
    {
      title: "Time Response",
      text: "Average time taken to respond to an applications",
      percentage: "0",
      color: "#F3223C",
    },
    {
      title: "Source Rate",
      text: "Effectiveness of communication and for folloe up strategies",
      percentage: "0",
      color: "#FFB202",
    },
  ];
  return (
    <WrappingContainer none noScroll>
      <div className={styles["gridThree"]}>
        <Rate data={data} />
        {/* <TimeOfAcceptance /> */}
        <SourceChannels />
      </div>
      <div className={styles["gridThreeOfThree"]}>
        <AboutAudChart />
        <ApplicationCategories />
        <DashboardCategories />
      </div>
      <FieldOfInterest />
    </WrappingContainer>
  );
};

export default ApplicationAnalysis;

import React, { createContext, useContext, useState } from "react";

const SearchFilterContext = createContext();

export const SearchFilterProvider = ({ children }) => {
  const initialFilterState = {
    searchValue: "",
    orderByValue: "",
    orderDirValue: "desc",
    page: 1,
    pageSize: 10,
    filteredColumn: [],
    isFilter: false,
    filteredByField: {
      filterByField: "",
      filterBy: "",
    },
  };

  const [filterState, setFilterState] = useState(initialFilterState);

  const updateFilterState = (field, value) => {
    setFilterState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const resetFilterState = () => {
    setFilterState(initialFilterState);
  };

  return (
    <SearchFilterContext.Provider
      value={{
        filterState,
        updateFilterState,
        resetFilterState,
      }}
    >
      {children}
    </SearchFilterContext.Provider>
  );
};

export const useSearchFilterContext = () => useContext(SearchFilterContext);

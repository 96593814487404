import { Form, Formik } from "formik";
import React from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import DateInput from "../../../Atoms/Inputs/DateInput";
import Buttons from "../../../Atoms/Buttons/Buttons";
import * as Yup from "yup";

const CustomDateModal = ({ handleOnClose, onSubmit }) => {
  const initialValues = { startDate: "", endDate: "" };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("startDate is required"),
    endDate: Yup.date()
      .required("endDate is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values.startDate, values.endDate);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal onClose={handleOnClose} width='40%' title='Custom Date'>
              <ModalChildrenContainer>
                <DateInput
                  label='From'
                  name='startDate'
                  value={values.startDate}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.startDate}
                  touched={touched.startDate}
                />{" "}
                <DateInput
                  label='To'
                  name='endDate'
                  value={values.endDate}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.endDate}
                  touched={touched.endDate}
                  startDate={values.startDate}
                />{" "}
                <Buttons
                  text='Select'
                  type='Submit'
                  handleOnClick={handleSubmit}
                />
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CustomDateModal;

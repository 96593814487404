import React, { useState } from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import SingleTabGroup from "../../SingleApplicant/SingleTabGroup";
import styles from "./EmailWorkflow.module.css";
import EmptyText from "../../Custom/EmptyText";
const MobileEmailPreview = ({ data, handleOnClose }) => {
  const [selected, setSelected] = useState(0);
  const [selectedNodeId, setSelectedNodeId] = useState("1");

  const tabContainer = [
    {
      text: "Receiver",
      value: 0,
    },
    {
      text: "Email Preview",
      value: 1,
    },
  ];
  return (
    <Modal onClose={handleOnClose}>
      <div>
        <SingleTabGroup
          tabContainer={tabContainer}
          selected={selected}
          setSelected={setSelected}
          noPadding
        />
      </div>
      <ModalChildrenContainer>
        <div>
          {selected === 0 ? (
            <div className={styles["receiver_cont"]}>
              <ul>
                {data?.length > 0 ? (
                  data?.map((user, index) => (
                    <li
                      key={user.id}
                      className={index % 2 == 0 ? styles["receiver_even"] : ""}
                    >
                      {user.fullName}
                    </li>
                  ))
                ) : (
                  <EmptyText text='Received Users' />
                )}
              </ul>
            </div>
          ) : (
            <div>
              <ul>
                {data.length > 0 ? (
                  data && (
                    <li>
                      <h4> {data[0]?.fullName}</h4>
                      {/* <p>{user.emailContent}</p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0]?.emailContent,
                        }}
                      ></p>
                    </li>
                  )
                ) : (
                  <EmptyText text='Emails' />
                )}
              </ul>
            </div>
          )}
        </div>
      </ModalChildrenContainer>
    </Modal>
  );
};

export default MobileEmailPreview;

import React, { useState } from "react";
import styles from "./RecomendationPage.module.css";
import RecomemdationPage from "./RecomemdationPage";
import Logo from "../../../assets/Imagess/logo.png";
import LoginVideo from "../../../assets/videos/background-video.mp4";
import REferenceSuccessForm from "./REferenceSuccessForm";
const ReferencePage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <div className={styles["login-container"]}>
      <video autoPlay loop muted id={styles["background-video"]}>
        <source src={LoginVideo} type='video/mp4' />
      </video>
      <div className={styles["login-subContainer"]}>
        <div className={styles["login-sub-Container"]}>
          <img src={Logo} alt='logo' className={styles["logo-login"]} />
          {isSuccess ? (
            <REferenceSuccessForm />
          ) : (
            <RecomemdationPage setIsSuccess={setIsSuccess} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferencePage;

import React, { useContext, useState } from "react";
import alltypesIcon from "../../../../assets/Imagess/Dashboard/allTypesIcon.svg";
import dateIcon from "../../../../assets/Imagess/Dashboard/dateIcon.svg";
import ColoredSelect from "../../../Atoms/Inputs/Select/ColoredSelect";
import styles from "./DAshboardOptions.module.css";
import ExportDashboardButton from "../ExportButton/ExportDashboardButton";
import { ApplicationContext } from "../../../../Context/DashbaordContext";
import { getDateRange } from "../DashboardFunctions/FormatDateFunction";
import CustomDateModal from "../DashboardModal/CustomDateModal";

const DashboardOptions = ({ handlePrint }) => {
  const { applicationStart, setApplicationStart, date, setDate } =
    useContext(ApplicationContext);
  const [isCustomDate, setIsCustomDate] = useState(false);

  const handleApplicationTypeChange = (option) => {
    setApplicationStart(option.value);
  };

  const handleDateChange = (option) => {
    if (option.value === "custom") {
      setIsCustomDate(true);
    } else {
      const { startDate, endDate } = getDateRange(option.value);
      setDate({ startDate, endDate });
    }
  };

  const handleCustomDateSubmit = (startDate, endDate) => {
    setDate({ startDate, endDate });
    setIsCustomDate(false);
  };

  const handleCustomDateCancel = () => {
    setIsCustomDate(false);
  };

  return (
    <>
      <div className={styles["welcome-subdiv"]}>
        <ColoredSelect
          options={[
            { label: "All Types", value: -1 },
            { label: "Undergraduate", value: 0 },
            { label: "Graduate", value: 1 },
            { label: "Visiting", value: 2 },
          ]}
          icon={alltypesIcon}
          label={"All types"}
          onChange={handleApplicationTypeChange}
          initialLabel='All Types'
        />
        <ColoredSelect
          options={[
            { label: "Today", value: "today" },
            { label: "This Week", value: "week" },
            { label: "This Month", value: "month" },
            { label: "This Year", value: "year" },
            { label: "Custom", value: "custom" },
          ]}
          icon={dateIcon}
          label={"Date"}
          onChange={handleDateChange}
          initialLabel='This Year'
        />
        <div className={styles["noPrint"]}>
          <ExportDashboardButton handlePrint={handlePrint} />
        </div>
      </div>
      {isCustomDate && (
        <CustomDateModal
          onSubmit={handleCustomDateSubmit}
          handleOnClose={handleCustomDateCancel}
        />
      )}
    </>
  );
};

export default DashboardOptions;

import React, { useRef, useState } from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import folder from "../../../../assets/Imagess/folderICon.svg";
import hash from "../../../../assets/Imagess/hashicon.svg";
import TextInput from "../../../Atoms/Inputs/TextInput";
import linkIcon from "../../../../assets/Imagess/linkIcon.svg";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import Buttons from "../../../Atoms/Buttons/Buttons";
import CustomizedSelectwithCheckbox from "../../../Atoms/Inputs/CustomizedSelectwithCheckbox";
import {
  useAddValuesForType,
  useEditValuesForTypes,
} from "../../../../Hooks/Settings";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import Loader from "../../Loader/Loader";
import LoaderButton from "../../Loader/LoaderButton";
const FieldOfInterestModa = ({
  handleOnClose,
  mode,
  type,
  refetchFieldOfInterest,
  selectedItem,
}) => {
  const { mutate: addValuesForTypeMutation, isLoading: isAddingValues } =
    useAddValuesForType();
  const { mutate: editValueForType, isLoading: isEditting } =
    useEditValuesForTypes();
  const arrayOfObjects = selectedItem?.applicationsCS
    ?.split(",")
    .map((value) => {
      switch (parseInt(value)) {
        case 0:
          return { value: 0, text: "Undergraduate" };
        case 1:
          return { value: 1, text: "Graduate" };
        case 2:
          return { value: 2, text: "Visiting" };
        default:
          return { value: parseInt(value), text: "Unknown" };
      }
    });
  const initialValues = {
    name: selectedItem?.text || "",
    brochureLink: selectedItem?.brochureLink || "",
    applicationsCS: arrayOfObjects || "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    brochureLink: Yup.string().required(),
    applicationsCS: Yup.array().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const applicationsCS = values.applicationsCS
          .map((item) => {
            return item.value;
          })
          .join(",");

        try {
          if (mode === "add") {
            await addValuesForTypeMutation(
              {
                typeID: type,
                text: values.name,
                value: values.name,
                brochureLink: values.brochureLink,
                applicationsCS: applicationsCS,
              },
              {
                onSuccess: () => {
                  handleOnClose();
                  refetchFieldOfInterest();
                  toast.success(`${values.name} is successfully added`);
                },
                onError: (error) => {
                  toast.error(error.response?.data?.message);
                },
              }
            );
          } else {
            await editValueForType(
              {
                typeID: selectedItem?.ddid,
                text: values.name,
                brochureLink: values.brochureLink,
                applicationsCS: applicationsCS,
              },
              {
                onSuccess: () => {
                  handleOnClose();
                  refetchFieldOfInterest();
                  toast.success(`${values.name} is successfully updated`);
                },
              },
              {
                onError: (error) => {
                  handleOnClose();
                  toast.error(error.response?.data?.message);
                  refetchFieldOfInterest();
                },
              }
            );
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              width='40%'
              onClose={handleOnClose}
              title={
                mode == "add"
                  ? "Add Field of Interest"
                  : "Edit Field of Interest"
              }
            >
              {/* <TextInput
                  label='ID'
                  disabled={true}
                  icon={hash}
                  placeholder={type}
                /> */}

              <ModalChildrenContainer>
                {" "}
                <TextInput
                  label='Name'
                  icon={folder}
                  placeholder='Enter Name'
                  // value={name}
                  // text={true}
                  // onChange={(e) => setName(e.target.value)}
                  required={true}
                  errors={errors.name}
                  touched={touched.name}
                  name='name'
                  value={values.name}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                />
                <TextInput
                  label='Brochure URL Link'
                  icon={linkIcon}
                  placeholder='Enter URL Link'
                  // value={brochureLink}
                  // text={true}
                  // onChange={(e) => setbrochureLink(e.target.value)}
                  errors={errors.brochureLink}
                  touched={touched.brochureLink}
                  required={true}
                  name='brochureLink'
                  value={values.brochureLink}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                />
                <CustomizedSelectwithCheckbox
                  label='Application Type'
                  icon={linkIcon}
                  placeholder='Select'
                  options={[
                    { value: 0, text: "Undergraduate" },
                    { value: 1, text: "Graduate" },
                    { value: 2, text: "Visiting" },
                  ]}
                  // selectedValues={applicationsCS}
                  // onChange={setApplicationCs}
                  // errors={errorApplicationsCS}
                  name='applicationsCS'
                  value={values.applicationsCS}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.applicationsCS}
                  touched={touched.applicationsCS}
                />
                {isAddingValues || isEditting ? (
                  <LoaderButton />
                ) : (
                  <>
                    {mode === "add" ? (
                      <Buttons
                        text='Save'
                        handleOnClick={handleSubmit}
                        type='Submit'
                      />
                    ) : (
                      <Buttons
                        text='Update'
                        handleOnClick={handleSubmit}
                        type='Submit'
                      />
                    )}
                  </>
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FieldOfInterestModa;

import { useQuery } from "react-query";
import axiosInstance from "./axiosInstance";

export const useGetData = (url, queryKey, enabled = true) => {
  return useQuery({
    queryKey: [queryKey, url],
    queryFn: async () => {
      return axiosInstance.get(url);
    },
    enabled: enabled && !!url,
  });
};

import React, { useEffect, useRef, useState } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import * as tableHeader from "../../Moecules/TableHeader/SettingsHeader";
import SettingsTopHeader from "../../Moecules/Settings/SettingsMolecules/SettingsTopHeader";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import { toast } from "react-toastify";
import blockICon from "../../../assets/Imagess/Campaign/blockIcon.svg";
import unblockICon from "../../../assets/Imagess/Campaign/unblockIcon.svg";
import {
  useBlockUnblockExternalContact,
  useDeleteExternalContactById,
  useFetchExternalContact,
} from "../../../Hooks/Externalcontact";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ExternalContactPage.module.css";
import SingleTabGroup from "../../Moecules/SingleApplicant/SingleTabGroup";
import TableComponent from "../../Moecules/TableComponent/TableComponent";
import DeletePopup from "../../Moecules/Custom/DeletePopup";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";
import ApplicantGroupTab from "../../Atoms/Tabs/ApplicantGroupTab";
const ExternalContactPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const printRef = useRef();

  const [isBlock, setIsBlock] = useState(false);
  const [block, setBlock] = useState(false);
  const [deletMode, setDeleteMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedContacts, setSelectedContacts] = useState(
    location?.state?.selected || "All"
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("desc");
  const [search, setSearch] = useState("");
  const {
    data: externalContactData,
    refetch: refetchExternalContact,
    isLoading: isExternalContactLoading,
  } = useFetchExternalContact({
    typeId: 21,
    pageSize: pageSize,
    pageNumber: page,
    searchBy: search,

    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
    isBlock: isBlock,
  });
  const { mutate: blockUnblock, isLoading: isBlockingContact } =
    useBlockUnblockExternalContact();
  const {
    mutate: deleteExternalContact,
    isLoading: isDeletingExternalContact,
  } = useDeleteExternalContactById();

  const handleEditClick = (e, item) => {
    e.stopPropagation();
    navigate(`/home/edit-externalContact/${item.externalContactId}`, {
      state: { mode: "edit", selected: selectedContacts },
    });
  };

  const handleNavigateToContactDetail = (e, id) => {
    e.stopPropagation();
    navigate(`/home/contact-details/${id}`, {
      state: {
        selected: selectedContacts,
      },
    });
  };

  const handleDelete = () => {
    deleteExternalContact(selectedItem?.externalContactId, {
      onSuccess: () => {
        setDeleteMode(false);
        refetchExternalContact();
        toast.success("Deleted successfully");
        setSelectedItem({});
      },
      onError: (error) => {
        console.error("Error deleting email", error);
        toast.error(
          "This option has been used by an applicant and cannot be deleted."
        );
        setDeleteMode(false);
        setSelectedItem({});
      },
    });
  };

  const handleBlockUnBLock = () => {
    blockUnblock(
      {
        contactId: selectedItem.externalContactId,
        isBlock: !selectedItem.isBlocked,
      },
      {
        onSuccess: () => {
          refetchExternalContact();
          toast.success(
            selectedItem.isBlocked ? "Unblocked Contact" : "Blocked Contact"
          );
          setBlock(false);
          setSelectedItem({});
        },
      }
    );
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const tableData = {
    data: {
      ...externalContactData?.data,
      items: externalContactData?.data?.items
        ?.filter((item) => item.isBlocked === (selectedContacts !== "All"))
        .map((item) => ({
          ...item,
          id: item.externalContactId,
        })),
    },
    refetch: refetchExternalContact,
    isLoading: isExternalContactLoading || isBlockingContact,
    tableHeader: tableHeader.externalContactsTableHeader,
  };

  const blockAndUnBlock = (item) => (
    <Tooltip
      content={selectedContacts == "All" ? "Block" : "Unblock"}
      red={selectedContacts == "All"}
    >
      <img
        src={selectedContacts == "All" ? blockICon : unblockICon}
        onClick={(e) => (
          e.stopPropagation(), setBlock(true), setSelectedItem(item)
        )}
      />
    </Tooltip>
  );

  const tabContainer = [
    {
      text: "All Contacts",
      value: "All",
    },
    {
      text: "Blocked Contacts",
      value: "Blocked Contacts",
    },
  ];

  useEffect(() => {
    if (selectedContacts == "All") {
      setIsBlock(false);
    } else {
      setIsBlock(true);
    }
  }, [selectedContacts]);

  useEffect(() => {
    refetchExternalContact();
  }, [isBlock]);

  return (
    <WrappingContainer>
      <WhiteContainer white>
        <SingleTabGroup
          tabContainer={tabContainer}
          selected={selectedContacts}
          setSelected={setSelectedContacts}
        />

        <SettingsTopHeader
          title='External Contact'
          search={search}
          setSearch={setSearch}
          handleAddClick={() => navigate("/home/add-externalContact")}
          data={tableData}
          handlePrint={handlePrint}
          canAdd
        />
        <TableComponent
          tableData={tableData}
          handleEditClick={handleEditClick}
          handleDelete={(e, item) => (
            setDeleteMode(true), setSelectedItem(item), e.stopPropagation()
          )}
          isOrder
          orderBy={orderBy}
          orderDir={orderDir}
          setOrderBy={setOrderBy}
          setOrderDir={setOrderDir}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          editAdd={true}
          additionalIcon={blockAndUnBlock}
          pagination
          handleRowClick={handleNavigateToContactDetail}
        />
        {block && (
          <DeletePopup
            handleOnClose={() => setBlock(!block)}
            mode={selectedContacts == "All" ? "Block" : "Unblock"}
            handleDelete={handleBlockUnBLock}
            text={
              selectedContacts == "All"
                ? "Are you sure you want to block this"
                : "Are you sure you want to Unblock this"
            }
            title='user'
            subText={
              selectedContacts == "All"
                ? "User will not be included in any campaign."
                : "User will be included in any campaign."
            }
            isLoading={isBlockingContact}
          />
        )}

        {deletMode && (
          <DeletePopup
            handleOnClose={() => (setDeleteMode(false), setSelectedItem({}))}
            title='External Contact'
            handleDelete={handleDelete}
            isLoading={isDeletingExternalContact}
          />
        )}
        <div style={{ display: "none" }}>
          <PrintComponent
            ref={printRef}
            title='External Contact'
            data={tableData}
          />
        </div>
      </WhiteContainer>
    </WrappingContainer>
  );
};

export default ExternalContactPage;

import React, { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import styles from "./DiamonBox.module.css";
import emailIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/emailIconNode.svg";
import awaitIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/waitIconNode.svg";
import actionIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/actionIconNode.svg";
import AdditionalList from "./AdditionalList";
import { useFetchActiveNotifications } from "../../../../../../Hooks/Notification";

const handleStyle = { backgroundColor: "red", width: "11px", height: "11px" };
const statusOptions = [
  { value: 0, text: "Interested" },
  { value: 1, text: "Not Interested" },
];

const RectangularNode = ({
  data,
  isLastSourceNode,
  onAddNode,
  id,
  xPos,
  yPos,
  readOnly,
  ...props
}) => {
  const [email, setEmail] = useState("");
  const [action, setAction] = useState("");
  const { data: emailCampaign } = useFetchActiveNotifications(true);

  useEffect(() => {
    if (data.label.email && emailCampaign?.data) {
      const emailNotification = emailCampaign.data.find(
        (item) => item.notificationId == data.label.email
      );
      if (emailNotification) {
        setEmail(emailNotification.notificationName);
      }
    }
  }, [data.label.email, emailCampaign]);

  useEffect(() => {
    if (data.label.changeTo != null) {
      const status = statusOptions?.find(
        (item) => item.value == data.label.changeTo
      );
      setAction(status?.text);
    }
  }, [data.label.changeTo]);
  return (
    <>
      <Handle type='target' position={Position.Top} style={handleStyle} />
      <div
        className={`${styles["emailContainer"]} ${
          props.selected ? styles.selected : ""
        }`}
        style={{ width: data?.action === "email" ? "230px" : "" }}
      >
        {data.action === "email" && data.label.emailCategory != null ? (
          <div className={styles["emailTitle"]}>
            <h4 className={styles["emailCategory_label"]}>
              {data.label.emailCategory}
            </h4>
          </div>
        ) : null}

        <h2 style={{ fontSize: "16px" }}>{id - 1} .</h2>
        <img
          src={
            data.action == "wait"
              ? awaitIcon
              : data.action == "email"
              ? emailIcon
              : actionIcon
          }
        />
        <div>
          <h2>
            {data.action == "wait"
              ? "Wait"
              : data.action == "email"
              ? "Email"
              : "Action"}
            {data.action == "wait" && (
              <span style={{ color: "#f3223c" }}>
                {data.label.wait
                  ? " for " + data.label.wait + " " + "days"
                  : ""}
              </span>
            )}
          </h2>

          <h4 className={styles["emailCategory_label"]}>
            {data.action == "email"
              ? data.label.email
                ? email
                : "Choose"
              : data.action == "wait"
              ? data.label.wait
                ? ""
                : "Configure"
              : data.action == "action"
              ? data.label.changeTo != null
                ? action
                : "Configure"
              : ""}
          </h4>
        </div>
      </div>
      {!readOnly ? (
        isLastSourceNode(id, null, xPos, yPos) ? (
          <AdditionalList
            onAddNode={(type, action) =>
              onAddNode(type, action, null, id, xPos, yPos)
            }
          />
        ) : (
          <Handle
            type='source'
            position={Position.Bottom}
            id='a'
            style={handleStyle}
          />
        )
      ) : (
        <Handle
          type='source'
          position={Position.Bottom}
          id='a'
          style={handleStyle}
        />
      )}
    </>
  );
};

export default RectangularNode;

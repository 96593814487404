import React from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import CampaignDetailedBox from "../../Campaign/CampaignDetails/CampaignDetailedBox";
import { useNavigate } from "react-router-dom";
import NoDataComponent from "../../Custom/NoData/NoDataComponent";

const ApplicantCampaign = ({ campaign }) => {
  const navigate = useNavigate();

  return (
    <WhiteContainer white height='50vh'>
      {campaign?.length > 0 ? (
        campaign?.map((item) => {
          return <CampaignDetailedBox data={item} contact />;
        })
      ) : (
        // <EmptyText text='Campaigns' />
        <NoDataComponent text="Campaign"/>
      )}
    </WhiteContainer>
  );
};

export default ApplicantCampaign;

import React from "react";
import styles from "./EmailsREceived.module.css";
import HorizantalLine from "../../../../../Atoms/Lines/HorizantalLine";
import USerProfileIcon from "../../../../../../assets/Imagess/usreprofileicon.svg";
import calendarIcon from "../../../../../../assets/Imagess/Contact/calendaricon.svg";
import EmailSent from "../../../../Applicants/ApplicantLogs/EmailSent";
import moment from "moment";
const EmailsReceived = ({ email }) => {

  return (
    <div className={styles["email_container"]}>
      <div
        className={`${styles["email_header_container"]} ${styles["header_subContainer"]}`}
      >
        <div className={styles["email_header_container"]}>
          <img src={USerProfileIcon} alt='user' />
          <div
            className={`${styles["email_container"]} ${styles["subContainer"]}`}
          >
            <h3>
              From: <span>AUD</span>
            </h3>
            <h3>
              To: <span>{email?.toEmail}</span>
            </h3>
          </div>
        </div>
        <div className={styles["email_header_container"]}>
          {" "}
          <img src={calendarIcon} alt='user' style={{ width: "20px" }} />
          <p> Date: {moment(email.dateSent).format("MMM D, YYYY h:mm A")}</p>
        </div>
      </div>
      <HorizantalLine />

      <div className={styles["logsEmail-cont"]}>
        <EmailSent selectedEmail={email} />
      </div>
    </div>
  );
};

export default EmailsReceived;

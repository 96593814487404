import React, { useEffect, useState } from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import StatusContainer from "../../Custom/StatusContainer";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import statisicon from "../../../../assets/Imagess/statusIconmodal.svg";
import Buttons from "../../../Atoms/Buttons/Buttons";
import * as Yup from "yup";
import {
  useBulkCancelApplicant,
  useChangeApplicantsStatus,
  useFetchApplicantStatus,
} from "../../../../Hooks/Applicant";
import { useFetchInterestedStatus } from "../../../../Hooks/Interested";
import LoaderButton from "../../Loader/LoaderButton";
import { toast } from "react-toastify";
import TextArea from "../../../Atoms/Inputs/TextArea";
import { Formik, Form } from "formik";
const StatusModal = ({
  handleOnClose,
  title,
  isApplicant,
  selected,
  setSelected,
  refetch,
  status,
  activeStatus,
}) => {
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { mutate: changeStatusMutation, isLoading: isChangingStatus } =
    useChangeApplicantsStatus();
  const { mutate: bulkCancelStatus, isLoading: isCancelling } =
    useBulkCancelApplicant();

  const { data: applicantOptions, refetch: refetchApplicantStatus } =
    useFetchApplicantStatus();
  const { data: interestedOptions, refetch: refetchInterestedStatus } =
    useFetchInterestedStatus();

  const initialValues = {
    selectedStatus: "",
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    selectedStatus: Yup.string().required("required"),
    notes: Yup.string().when("selectedStatus", {
      is: (value) => value == 5,
      then: (schema) => schema.required("Reason is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const selectApplicantOptions = applicantOptions?.data?.map(
    ({ statusId, statusText }) => ({
      value: statusId,
      text: statusText,
    })
  );
  const selectInterestedOptions = interestedOptions?.data?.map(
    ({ statusId, statusText }) => ({
      value: statusId,
      text: statusText,
    })
  );
  console.log("activeStatus", activeStatus);
  useEffect(() => {
    setSelectedOptions(
      isApplicant ? selectApplicantOptions : selectInterestedOptions
    );
  }, [isApplicant, selected, applicantOptions, interestedOptions]);

  useEffect(() => {
    refetchApplicantStatus();
    refetchInterestedStatus();
  }, [isApplicant]);


  const handleChangeStatus = (values) => {

      if (values?.selectedStatus == activeStatus) {
        toast.warning("You can't change to the same status");
        return;
      }
 

    const selectedIds = isApplicant
      ? selected?.map((item) => item.foreignId)
      : selected?.map((item) => item.id);
    const updated = {
      isApplicant: isApplicant,
      applicantIds: selectedIds,
      newStatus: values.selectedStatus,
    };
    {
      values.selectedStatus == 5
        ? bulkCancelStatus(
            {
              applicantIds: selectedIds,
              notes: values.notes,
            },
            {
              onSuccess: () => {
                refetch();
                handleOnClose();
                if (setSelected) {
                  setSelected([]);
                }
                toast.success("Status is changed to cancel");
              },
            }
          )
        : changeStatusMutation(updated, {
            onSuccess: () => {
              refetch();
              handleOnClose();
              if (setSelected) {
                setSelected([]);
              }
              toast.success("Status is changed successfully");
            },
          });
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleChangeStatus(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal onClose={handleOnClose} width='40%' title={title}>
              <ModalChildrenContainer>
                {status ? (
                  <>
                    <h4>Current Status</h4>
                    <div>
                      <StatusContainer status={status} />
                    </div>
                  </>
                ) : (
                  <>
                    <h4>
                      Change the current status for the selected{" "}
                      {isApplicant ? "prospects" : "leads"}
                    </h4>
                  </>
                )}

                <CustomizedSelect
                  options={selectedOptions}
                  icon={statisicon}
                  placeholder='Change Status'
                  name='selectedStatus'
                  value={
                    values.selectedStatus == ""
                      ? undefined
                      : values.selectedStatus
                  }
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.selectedStatus}
                  touched={touched.selectedStatus}
                  notAbsolute
                  status
                />
                {values.selectedStatus == 5 ? (
                  <TextArea
                    placeholder='Enter the reason'
                    label='Reason for Cancellation'
                    rows='8'
                    name='notes'
                    value={values.notes}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.notes}
                    touched={touched.notes}
                  />
                ) : (
                  ""
                )}

                {isChangingStatus || isCancelling ? (
                  <LoaderButton />
                ) : (
                  <Buttons text='Update' handleOnClick={handleSubmit} />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StatusModal;

import React, { useContext } from "react";
import styles from "./ApplicantDemographics.module.css";
import GenderGraph from "./GenderGraph/GenderGraph";
import LevelOfEducation from "./LevelOfEducation/LevelOfEducation";
import Nationalities from "./Nationalities/Nationalities";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import GeoChart from "./GeoChart/GeoChart";
import AgeGraphComponent from "./AgeGraph/AgeGraphComponent";
import GenderGraphComponent from "./GenderGraph/GenderGraphComponent";
import LevelOfEducationComponent from "./LevelOfEducation/LevelOfEducationComponent";
const ApplicantDemographics = () => {
  return (
    <WrappingContainer none noScroll>
      <div className={styles["grid_three"]}>
        <AgeGraphComponent />
        <GenderGraphComponent />
        <LevelOfEducationComponent />
      </div>

      <div className={styles["grid_two"]}>
        <GeoChart />
        <Nationalities />
      </div>
    </WrappingContainer>
  );
};

export default ApplicantDemographics;

import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import styles from "./SettingsModa.module.css";
import TextInput from "../../../Atoms/Inputs/TextInput";
import folder from "../../../../assets/Imagess/folderICon.svg";
import DateInput from "../../../Atoms/Inputs/DateInput";
import CheckBox from "../../../Atoms/Inputs/CheckBox";
import Buttons from "../../../Atoms/Buttons/Buttons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddTerm, useFetchAcademicYears } from "../../../../Hooks/Settings";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import calenderVector from "../../../../assets/Imagess/calenderVector.svg";
import LoaderButton from "../../Loader/LoaderButton";
import { toast } from "react-toastify";
import HorizantalLine from "../../../Atoms/Lines/HorizantalLine";
const TermsModal = ({
  handleOnClose,
  mode,
  type,
  refetchTerms,
  selectedItem,
  setSelectedItem,
}) => {
  const { data: academicYears } = useFetchAcademicYears({ type: 20 });
  const { mutate: addTerm, isLoading: isAddingTerms } = useAddTerm();
  const formikRef = useRef();
  const initialValues = {
    academicTermId:
      selectedItem?.academicTermId || "00000000-0000-0000-0000-000000000000",
    academicYearId: selectedItem?.academicYearId || "",
    termName: selectedItem?.termName || "",
    shortTermName: selectedItem?.shortTermName || "",
    startDate: selectedItem?.startDate
      ? selectedItem?.startDate?.substring(0, 10)
      : "",
    endDate: selectedItem?.endDate
      ? selectedItem?.endDate?.substring(0, 10)
      : "",
    submissionStartDate: selectedItem?.submissionStartDate
      ? selectedItem?.submissionStartDate?.substring(0, 10)
      : "",
    submissionEndDate: selectedItem?.submissionEndDate
      ? selectedItem?.submissionEndDate?.substring(0, 10)
      : "",
    isClintonScholarTerm: selectedItem?.isClintonScholarTerm || false,
    clintonScholarDeadline: selectedItem?.clintonScholarDeadline
      ? selectedItem?.clintonScholarDeadline?.substring(0, 10)
      : "",
  };
  const validationSchema = Yup.object().shape({
    academicTermId: Yup.string(),
    academicYearId: Yup.string().required("academicYearId is required"),
    termName: Yup.string().required("termName is required"),
    shortTermName: Yup.string().required("shortTermName is required"),
    startDate: Yup.date().required("startDate is required"),
    endDate: Yup.date()
      .required("endDate is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
    submissionStartDate: Yup.date().required("submissionStartDate is required"),
    submissionEndDate: Yup.date()
      .required("submissionEndDate is required")
      .min(Yup.ref("submissionStartDate"), "End date must be after start date"),
    isClintonScholarTerm: Yup.boolean(),
    clintonScholarDeadline: Yup.string().when("isClintonScholarTerm", {
      is: true,
      then: (schema) => schema.required("clintonScholarDeadline is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    // termValidityDate: Yup.date(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={(values) => {
        const formattedValues = {
          ...values,
          clintonScholarDeadline: values.clintonScholarDeadline || null,
          termValidityDate: null,
        };

        addTerm(formattedValues, {
          onSuccess: () => {
            handleOnClose();
            refetchTerms();
            setSelectedItem({});

            if (mode === "add") {
              toast.success(`${values.shortTermName} is successfully added`);
            } else {
              toast.success(`${values.shortTermName} is successfully updated`);
            }
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              width='40%'
              onClose={handleOnClose}
              title={
                mode == "add"
                  ? "Add Term"
                  : mode == "View"
                  ? "View Term"
                  : "Edit Term"
              }
            >
              <ModalChildrenContainer>
                <div className={styles["terms-container"]}>
                  <div className={styles["date-container"]}>
                    <TextInput
                      label='Term Name'
                      icon={folder}
                      placeholder='Term Name'
                      name='termName'
                      value={values.termName}
                      disabled={mode == "View" ? true : false}
                      required={true}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.termName}
                      touched={touched.termName}
                    />
                    <TextInput
                      label='Short Term Name'
                      icon={folder}
                      placeholder='Short Term Name'
                      disabled={mode == "View" ? true : false}
                      name='shortTermName'
                      value={values.shortTermName}
                      required={true}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.shortTermName}
                      touched={touched.shortTermName}
                    />
                  </div>

                  <CustomizedSelect
                    options={academicYears?.data?.items?.map(
                      ({ acadamicYearId, academicName }) => ({
                        value: acadamicYearId,
                        text: academicName,
                      })
                    )}
                    placeholder='Academic Year'
                    label='Academic Year'
                    icon={calenderVector}
                    disabled={mode == "View" ? true : false}
                    required={true}
                    name='academicYearId'
                    value={values.academicYearId}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.academicYearId}
                    touched={touched.academicYearId}
                  />
                  <div className={styles["date-container"]}>
                    <DateInput
                      label='From Date'
                      name='startDate'
                      disabled={mode == "View" ? true : false}
                      value={values.startDate}
                      required={true}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.startDate}
                      touched={touched.startDate}
                    />
                    <DateInput
                      label='To Date'
                      name='endDate'
                      value={values.endDate}
                      required={true}
                      disabled={mode == "View" ? true : false}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.endDate}
                      touched={touched.endDate}
                      startDate={values.startDate}
                    />
                  </div>
                  <div className={styles["sub-container"]}>
                    <h4 className={styles["application_title"]}>
                      Application Submission period{" "}
                    </h4>
                    <div className={styles["date-container"]}>
                      <DateInput
                        label='From Date'
                        name='submissionStartDate'
                        disabled={mode == "View" ? true : false}
                        value={values.submissionStartDate}
                        required={true}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errors={errors.submissionStartDate}
                        touched={touched.submissionStartDate}
                      />
                      <DateInput
                        label='Submission Deadline'
                        disabled={mode == "View" ? true : false}
                        name='submissionEndDate'
                        value={values.submissionEndDate}
                        required={true}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errors={errors.submissionEndDate}
                        touched={touched.submissionEndDate}
                        startDate={values.submissionStartDate}
                      />
                    </div>
                    {/* <DateInput
                      label='Term Validity Date'
                      name='termValidityDate'
                      value={values.termValidityDate}
                      required={true}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.termValidityDate}
                      touched={touched.termValidityDate}
                    /> */}
                  </div>
                  <HorizantalLine />
                  <div className={styles["subclinton-container"]}>
                    <CheckBox
                      text='Active Clinton Scholar'
                      name='isClintonScholarTerm'
                      disabled={mode == "View" ? true : false}
                      value={values.isClintonScholarTerm}
                      required={true}
                      onChange={(checked) => {
                        setFieldValue("isClintonScholarTerm", checked);
                      }}
                      errors={errors.isClintonScholarTerm}
                      touched={touched.isClintonScholarTerm}
                    />
                    {values.isClintonScholarTerm === true ? (
                      <DateInput
                        label='Clinton Application Deadline'
                        name='clintonScholarDeadline'
                        disabled={mode == "View" ? true : false}
                        value={values.clintonScholarDeadline}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errors={errors.clintonScholarDeadline}
                        touched={touched.clintonScholarDeadline}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {mode == "View" ? (
                    ""
                  ) : (
                    <>
                      {isAddingTerms ? (
                        <LoaderButton />
                      ) : (
                        <>
                          {mode == "add" ? (
                            <>
                              <Buttons
                                text='Save'
                                type='Submit'
                                handleOnClick={handleSubmit}
                              />
                            </>
                          ) : (
                            <Buttons
                              text='Update'
                              type='Submit'
                              handleOnClick={handleSubmit}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TermsModal;

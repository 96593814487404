import React from "react";
import { Quill } from "react-quill";
import { useFetchNotificationOptions } from "../../../Hooks/Notification";
import QuillTollbarSelect from "./QuillTollbarSelect";
import "react-quill/dist/quill.snow.css"; // For snow theme
// import ImageResize from "quill-image-resize-module-react";

// Registering size and font options
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large", "huge"];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

// Registering the image resize module
// Quill.register("modules/imageResize", ImageResize);

function insertCustomTags(args) {
  const value = args;
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertCustomTags: insertCustomTags,
    },
    imageResize: {
      displaySize: true,
    },
  },
  // imageResize: {
  //   modules: ["Resize", "DisplaySize"],
  //   handleStyles: {
  //     backgroundColor: 'black',
  //     border: 'none',
  // }
  // },
  history: {
    delay: 1000, // Delay between undo levels
    maxStack: 50, // Maximum number of undo levels
    userOnly: true, // Only track changes made by the user
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "background",
  "color",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

// Quill Toolbar component
export const QuillToolbar = ({ setVariable, variable }) => {
  const { data: notificationOptions } = useFetchNotificationOptions();

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      setVariable(selectedValue);
    }
  };
  // Adding handlers for undo and redo
  Quill.import("ui/icons")["undo"] = "<svg>...</svg>"; // Add custom undo icon if needed
  Quill.import("ui/icons")["redo"] = "<svg>...</svg>"; // Add custom redo icon if needed

  const toolbarHandlers = {
    undo: function () {
      this.quill.history.undo();
    },
    redo: function () {
      this.quill.history.redo();
    },
  };

  modules.toolbar.handlers = {
    ...modules.toolbar.handlers,
    undo: toolbarHandlers.undo,
    redo: toolbarHandlers.redo,
  };
  return (
    <div className='quill-container'>
      <div id='toolbar'>
        <span className='ql-formats'>
          <select className='ql-font'>
            <option value='arial' selected>
              Arial
            </option>
            <option value='comic-sans'>Comic Sans</option>
            <option value='courier-new'>Courier New</option>
            <option value='georgia'>Georgia</option>
            <option value='helvetica'>Helvetica</option>
            <option value='lucida'>Lucida</option>
          </select>
          <select className='ql-size' defaultValue='medium'>
            <option value='extra-small'>12</option>
            <option value='small'>14</option>
            <option value='medium'>18</option>
            <option value='large'>20</option>
            <option value='huge'>24</option>
          </select>
        </span>
        <span className='ql-formats'>
          <button className='ql-bold' />
          <button className='ql-italic' />
          <button className='ql-underline' />
          <button className='ql-strike' />
        </span>
        <span className='ql-formats'>
          <button className='ql-list' value='ordered' />
          <button className='ql-list' value='bullet' />
          <button className='ql-indent' value='-1' />
          <button className='ql-indent' value='+1' />
        </span>
        <span className='ql-formats'>
          <select className='ql-align' />
          <select className='ql-color' />
          <select className='ql-background' />
        </span>
        <span className='ql-formats'>
          <button className='ql-link' />
          <button className='ql-image' />
        </span>{" "}
        {/* <span className='ql-formats'>
          <button className='ql-undo'>Undo</button>
          <button className='ql-redo'>Redo</button>
        </span> */}
      </div>

      <select
        className='ql-insertCustomTags'
        value={variable}
        onChange={handleSelectChange}
        style={{ border: "none", fontWeight: "500" }}
      >
        <option value='Add Variable'>Add Variable</option>
        {notificationOptions?.data?.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

import React from "react";
import image from "../../../../assets/Imagess/Dashboard/noDataDashboard.svg";
import styles from "./NoDataDashboar.module.css";
const NoDataDashboard = ({ background }) => {
  return (
    <div
      className={`${styles["cont"]} ${background ? styles["whiteCont"] : ""}`}
    >
      <img src={image} alt='no data' />
      <h3>No Data Available</h3>
    </div>
  );
};

export default NoDataDashboard;

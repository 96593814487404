import React, { useEffect, useRef, useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import SettingsTopHeader from "../../../Moecules/Settings/SettingsMolecules/SettingsTopHeader";
import * as tableHeader from "../../../Moecules/TableHeader/AudienceTableHeader";
import TableComponent from "../../../Moecules/TableComponent/TableComponent";
import dublicateIcon from "../../../../assets/Imagess/Audience/dublicate.svg";
import DuplicateModal from "../../../Moecules/Audience/DuplicateModal";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../../../Moecules/Custom/DeletePopup";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import {
  useDeleteAudiencebyId,
  useExportAudienceTable,
  useFetchAudience,
} from "../../../../Hooks/Audience";
import { toast } from "react-toastify";
import PrintComponent from "../../../Moecules/Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";

const AudiencePage = () => {
  const navigate = useNavigate();
  const printRef = useRef();

  const [isDuplicate, setISDuplicate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState({});
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const {
    data: audience,
    isLoading,
    refetch: refetchAudience,
  } = useFetchAudience({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: search,
  });
  const { mutate: deleteAudience, isLoading: isDeletingAudience } =
    useDeleteAudiencebyId();
  const exportAudienceTable = useExportAudienceTable();

  const tableData = {
    data: {
      ...audience?.data,
      items: audience?.data?.items.map((item) => ({
        ...item,
        id: item.campaignAudienceId,
      })),
    },
    refetch: refetchAudience,
    isLoading: isLoading,
    tableHeader: tableHeader.audience,
  };

  const handleEditClick = (e, item) => {
    e.stopPropagation();
    navigate(`/home/new-audience/${item?.campaignAudienceId}`);
  };

  const handleDelete = () => {
    deleteAudience(selectedAudience?.campaignAudienceId, {
      onSuccess: () => {
        toast.success(
          `${selectedAudience?.audienceName} is deleted Successfully`
        );
        setIsDelete(false);
        setSelectedAudience({});
        refetchAudience();
      },
      onError: (error) => {
        toast.error(`${selectedAudience?.audienceName} ${error.response.data}`);
        setIsDelete(false);
        setSelectedAudience({});
        refetchAudience();
      },
    });
  };
  const handleRowClick = (e, id) => {
    e.stopPropagation();
    navigate(`/home/audienceDetails/${id}`);
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleExport = async () => {
    try {
      const data = await exportAudienceTable.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "AdienceTable";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const additionaIcon = (item) => (
    <Tooltip content='Duplicate'>
      <img
        src={dublicateIcon}
        alt='dublicate'
        onClick={(e) => (
          setISDuplicate((prev) => !prev),
          setSelectedAudience(item),
          e.stopPropagation()
        )}
      />
    </Tooltip>
  );

  return (
    <>
      <WrappingContainer>
        <WhiteContainer white>
          <SettingsTopHeader
            title='New Audience'
            handleAddClick={() => navigate("/home/new-audience")}
            search={search}
            setSearch={setSearch}
            handleExport={handleExport}
            handlePrint={handlePrint}
            canAdd
          />
          <PrintComponent ref={printRef} title='Audience Table'>
            <TableComponent
              tableData={tableData}
              editAdd
              additionalIcon={additionaIcon}
              handleEditClick={handleEditClick}
              handleDelete={(e, item) => (
                e.stopPropagation(),
                setSelectedAudience(item),
                setIsDelete((prev) => !prev)
              )}
              handleRowClick={handleRowClick}
              pageSize={pageSize}
              setPageSize={setPageSize}
              page={page}
              setPage={setPage}
              pagination
            />
          </PrintComponent>
        </WhiteContainer>
      </WrappingContainer>
      {isDuplicate && (
        <DuplicateModal
          handleOnClose={() => (
            setISDuplicate((prev) => !prev), setSelectedAudience({})
          )}
          selectedAudience={selectedAudience}
          setSelectedAudience={setSelectedAudience}
          refetchAudience={refetchAudience}
        />
      )}
      {isDelete && (
        <DeletePopup
          handleOnClose={() => (
            setIsDelete((prev) => !prev), setSelectedAudience({})
          )}
          handleDelete={handleDelete}
          title={`${selectedAudience?.audienceName} Audience`}
          isLoading={isDeletingAudience}
        />
      )}
    </>
  );
};

export default AudiencePage;

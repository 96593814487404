import React from "react";
import styles from "./Loader.module.css";
const Loader = ({ flexStart }) => {
  return (
    <div
      className={styles["loader-container"]}
      style={{ justifyContent: flexStart ? "flex-start" : "" }}
    >
      <div className={styles["loader"]}></div>
    </div>
  );
};

export default Loader;

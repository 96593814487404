import React from "react";
import Modal from "../../Atoms/Modal/Modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import psswordIcon from "../../../assets/Imagess/passwordloginicon.svg";
import TextInput from "../../Atoms/Inputs/TextInput";
import Buttons from "../../Atoms/Buttons/Buttons";
import { useChangePassword } from "../../../Hooks/Users";
import { toast } from "react-toastify";

const ChangePasswordModal = ({ handleOnClose, refetch }) => {
  const { mutate: changePassword, isLoading } = useChangePassword();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    repeatesNewPassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required"),
    repeatesNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        var CryptoJS = require("crypto-js");
        const secretKey = "AUD-CTS-109812jfgiubfg435345";
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(values.repeatesNewPassword),
          secretKey
        ).toString();

        changePassword(
          {
            oldPassword: values.oldPassword,
            newPassword: encryptedData,
          },
          {
            onSuccess: () => {
              handleOnClose();
              toast.success("Password is updated");
              refetch();
            },
            onError: (error) => {
              console.error("Password change error", error);
              toast.error("Old Password is wrong");
            },
          }
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal width='40%' title='Change Password' onClose={handleOnClose}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  paddingTop: "15px",
                }}
              >
                <TextInput
                  label='Current Password'
                  icon={psswordIcon}
                  placeholder='Current Password'
                  name='oldPassword'
                  value={values.oldPassword}
                  required={true}
                  onChange={(name, value) => {
                    var CryptoJS = require("crypto-js");
                    const secretKey = "AUD-CTS-109812jfgiubfg435345";
                    const encryptedData = CryptoJS.AES.encrypt(
                      JSON.stringify(value),
                      secretKey
                    ).toString();

                    setFieldValue(name, encryptedData);
                  }}
                  errors={errors.oldPassword}
                  touched={touched.oldPassword}
                  type='password'
                />
                <TextInput
                  label='New Password'
                  icon={psswordIcon}
                  placeholder='New Password'
                  name='newPassword'
                  value={values.newPassword}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.newPassword}
                  touched={touched.newPassword}
                  type='password'
                />
                <TextInput
                  label='Repeat New Password'
                  icon={psswordIcon}
                  placeholder='Repeat New Password'
                  name='repeatesNewPassword'
                  value={values.repeatesNewPassword}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.repeatesNewPassword}
                  touched={touched.repeatesNewPassword}
                  type='password'
                />
                <Buttons text='Save Password' handleOnClick={handleSubmit} />
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordModal;

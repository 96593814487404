import React, { useContext } from "react";
import styles from "./GeneralStatistics.module.css";
import leadsIcon from "../../../../assets/Imagess/Dashboard/dashboardIcon.svg";
import prospectsIcon from "../../../../assets/Imagess/Dashboard/prospectsIcon.svg";
import intersetedIcon from "../../../../assets/Imagess/Dashboard/interstedIcon.svg";
import notInterestedIcon from "../../../../assets/Imagess/Dashboard/notInterstedIcon.svg";
import convertedIcon from "../../../../assets/Imagess/Dashboard/convertedIcon.svg";
import canceledIcon from "../../../../assets/Imagess/Dashboard/canceledIcon.svg";
import approvedIcon from "../../../../assets/Imagess/Dashboard/approveedIcon.svg";
import rejectedIcon from "../../../../assets/Imagess/Dashboard/rejectedIcon.svg";
import { useFetchHeaderNumbers } from "../../../../Hooks/Dashboard";
import { formatNumber } from "../DashboardFunctions/FormatNumberFunction";
import Loader from "../../Loader/Loader";
import { ApplicationContext } from "../../../../Context/DashbaordContext";
const GeneralStatistics = ({}) => {
  const { date, setDate } = useContext(ApplicationContext);
  const { data, isLoading } = useFetchHeaderNumbers({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: -1,
  });



  const statistics = [
    {
      title: "Leads",
      icon: leadsIcon,
      number: formatNumber(data?.data?.leads || 0),
    },
    {
      title: "Prospects",
      icon: prospectsIcon,
      number: formatNumber(data?.data?.prospects || 0),
    },
    {
      title: "Interested",
      icon: intersetedIcon,
      number: formatNumber(data?.data?.interested || 0),
    },
    {
      title: "Not Interested",
      icon: notInterestedIcon,
      number: formatNumber(data?.data?.notInterested || 0),
    },
    {
      title: "Converted",
      icon: convertedIcon,
      number: formatNumber(data?.data?.converted || 0),
    },
    {
      title: "Canceled",
      icon: canceledIcon,
      number: formatNumber(data?.data?.cancelled || 0),
    },
    {
      title: "Approved",
      icon: approvedIcon,
      number: formatNumber(data?.data?.approved || 0),
    },
    {
      title: "Rejected",
      icon: rejectedIcon,
      number: formatNumber(data?.data?.rejected || 0),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles["statistical-div"]}>
          {statistics.map((item, index) => {
            return (
              <div
                className={`${styles["statistical-sub-cont"]} ${
                  index == 0 ? styles["item1"] : ""
                }`}
                key={index}
              >
                <img
                  src={item.icon}
                  alt='icon'
                  className={styles["stat-img"]}
                />

                <h4>{item.title}</h4>

                <h3> {item.number}</h3>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default GeneralStatistics;

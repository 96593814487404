import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../Navhead.module.css";
import downArrowIcon from "../../../../assets/Imagess/SidebarIcons/downArrow.svg";
import PopOver from "../../Custom/PopOver/PopOver";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import { useFetchSidbarPages } from "../../../../Hooks/SideBAr";
import Loader from "../../Loader/Loader";
import { useSearchFilterContext } from "../../../../Context/SearchFilterContext";
import { useTableFilterValuesContext } from "../../../../Context/TableFilterValues";

const NavLink = ({ isOpen, align, mobile, setIsOpen }) => {
  //reset filters between pages
  const { resetFilterState } = useSearchFilterContext();
  const { reset } = useTableFilterValuesContext();

  const [activeLink, setActiveLink] = useState("Dashboard");
  const [showSubLink, setShowSubLink] = useState(false);
  const [activeSubLink, setActiveSubLink] = useState("");
  const [navLink, setNavLink] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const subLinkRef = useRef(null);

  const { data: sideBarPages, isLoading, refetch } = useFetchSidbarPages();

  useEffect(() => {
    setNavLink(sideBarPages?.data);
  }, [sideBarPages]);

  useEffect(() => {
    refetch();
  }, [location?.pathname]);

  const handleNavigate = (link) => {
    resetFilterState();
    reset();
    if (link.childPages && link.childPages.length > 0) {
      setShowSubLink((prev) => !prev);
    } else {
      setActiveLink(link.name);
      setActiveSubLink("");

      if (mobile) {
        setIsOpen(false);
      }
      if (link.path) navigate(link.path);
      setShowSubLink(false);
    }
  };

  const handleNavigateSubLink = (subLink) => {
    resetFilterState();
    setActiveSubLink(subLink.name);
    navigate(subLink.path);
    isOpen ? setShowSubLink(true) : setShowSubLink(false);
    if (mobile) {
      setIsOpen(false);
    }
  };
  const handleToggleMenu = (e) => {
    e.stopPropagation();
    setShowSubLink(!showSubLink);
    document.body.style.overflow = !isOpen ? "hidden" : "";
  };
  useEffect(() => {
    const currentPath = location.pathname;
    const currentPathArray = currentPath.split("/");
    const lastItem = currentPathArray[currentPathArray.length - 1];
    const matchingLink = navLink?.find((link) => {
      return lastItem === link.pageName;
    });
    setActiveLink(matchingLink ? matchingLink.name : "");
  }, [location.pathname, navLink]);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (!subLinkRef.current && !subLinkRef.current?.contains(e.target)) {
        setShowSubLink(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setShowSubLink(false);
  }, [isOpen]);

  return isOpen ? (
    <nav
      className={`${styles["nav-open"]} ${isOpen ? styles.navOpen : ""}`}
      style={{ alignItems: align ? "flex-start" : "" }}
    >
      {navLink?.map((link) => (
        <div key={link.pageId}>
          <div
            to={link.path}
            className={
              styles[`${activeLink === link.name ? "openedaActive" : ""}`]
            }
            ref={subLinkRef}
          >
            <div
              onClick={() => handleNavigate(link)}
              className={`${styles["navlink-inactive-openedDiv"]} ${
                activeLink === link.name
                  ? link.childPages && link.childPages?.length > 0
                    ? ""
                    : styles["navlink-active-openedDiv"]
                  : ""
              }`}
            >
              <img
                src={`/Icon/${link.icon}.svg`}
                alt={link.name}
                className={` ${styles["navlink-openedimages"]} ${
                  activeLink === link.name
                    ? link.childPages && link.childPages?.length > 0
                      ? ""
                      : styles["navlink-activeOpened-image"]
                    : ""
                }`}
              />
              <h1
                className={`${styles["navlink-title"]} ${styles["navlink-title-opened"]}`}
              >
                {link.name}
              </h1>
              {link.childPages && link.childPages?.length > 0 && (
                <img
                  src={downArrowIcon}
                  alt='down-icon'
                  className={`${styles["arrow"]} ${
                    showSubLink ? styles["rotate-img"] : ""
                  }`}
                />
              )}
            </div>
          </div>
          {showSubLink && (
            <div>
              {link?.childPages &&
                link?.childPages?.map((item) => (
                  <div
                    className={styles["navlink-nested"]}
                    key={item.pageId}
                    onClick={(e) => (
                      e.stopPropagation(), handleNavigateSubLink(item)
                    )}
                  >
                    <div
                      className={`${styles["navlink-inactive-openedDiv"]} ${
                        activeSubLink === item.name
                          ? styles["navlink-active-openedDiv"]
                          : ""
                      }`}
                    >
                      <img
                        src={`/Icon/${item.icon}.svg`}
                        alt={item.name}
                        style={{ width: "20px" }}
                        className={` ${styles["navlink-nested-imaage"]} ${
                          activeSubLink === item.name
                            ? styles["navlink-activeNested-image"]
                            : ""
                        }`}
                      />
                      <h1
                        className={`${styles["navlink-title"]} ${styles["navlink-title-opened"]}`}
                        style={{ fontSize: "14px" }}
                      >
                        {item.name}
                      </h1>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      ))}
    </nav>
  ) : (
    <nav
      className={`${styles["nav-closed "]} ${
        !showSubLink ? styles.navOPen : ""
      }`}
    >
      {navLink?.map((link) => (
        <div key={link.pageId}>
          <div
            to={link.path}
            className={styles[`${activeLink === link.name ? "active" : ""}`]}
            onClick={() => handleNavigate(link)}
            ref={subLinkRef}
          >
            <div
              className={`${styles["navlink-inactive-div"]} ${
                activeLink === link.name ? styles["navlink-active-div"] : ""
              }`}
            >
              <Tooltip content={link.name} placement='right'>
                <img
                  src={`/Icon/${link.icon}.svg`}
                  alt={link.name}
                  className={` ${styles["navlink-images"]} ${
                    activeLink === link.name
                      ? styles["navlink-active-image"]
                      : ""
                  }`}
                />
                <h1 className={styles["navlink-title"]}>{link.name}</h1>
              </Tooltip>

              {showSubLink &&
                link?.childPages &&
                link?.childPages.length > 0 && (
                  <>
                    <div
                      className={`${styles.backdrop}`}
                      onClick={handleToggleMenu}
                    ></div>
                    <div className={styles["child"]}>
                      <PopOver
                        info={
                          link?.childPages &&
                          link?.childPages?.map((item) => ({
                            title: item.name,
                            icon: `/Icon/${item.icon}.svg`,
                            handleFunction: (e) => (
                              handleNavigateSubLink(item), e.stopPropagation()
                            ),
                            active: activeSubLink == item.name,
                          }))
                        }
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      ))}
    </nav>
  );
};

export default NavLink;

import React, { useContext, useRef } from "react";
import styles from "./DashboardCategories.module.css";
import DashboardContainer from "../../DashboardContainer";
import WhiteBoxContainer from "../../../../HOC/WrappingContainer/WhiteBoxContainer";
import archIcon from "../../../../../assets/Imagess/Dashboard/archICon.svg";
import businessIcon from "../../../../../assets/Imagess/Dashboard/businessIcon.svg";
import communicationIcon from "../../../../../assets/Imagess/Dashboard/communicationIcon.svg";
import leadershipIcon from "../../../../../assets/Imagess/Dashboard/leadershipIcon.svg";
import managementIcon from "../../../../../assets/Imagess/Dashboard/managementIcon.svg";
import { useFetchMostFieldOfInterest } from "../../../../../Hooks/Dashboard";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const DashboardCategories = () => {
  const chartRef = useRef(null);

  const { date, applicationStart } = useContext(ApplicationContext);
  const { data: mostFieldsData, isLoading } = useFetchMostFieldOfInterest({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const fieldsData = mostFieldsData?.data || [];

  const iconMapping = {
    Accounting: businessIcon,
    "Master of Arts in International Affairs": archIcon,
    MBA: leadershipIcon,
    "Masters in Marketing": communicationIcon,
  };

  const data = fieldsData.map((field) => ({
    title: field.label,
    number: field.value,
    icon: iconMapping[field.label] || managementIcon,
  }));

  const handleDownload = () => {
    const title = "ApplicationCategories_Graph";
    const series = fieldsData.map((field) => field.value);
    const categories = fieldsData.map((field) => field.label);

    downloadCSV(series, categories, title);
  };

  return (
    <DashboardContainer none>
      <DashboardContainer
        title='Most Field of Interest Applied'
        handleExport={handleDownload}
        chartRef={chartRef}
        isLoading={isLoading}
        noData={fieldsData?.length === 0}
      />

      <div className={styles["gridTwo"]} ref={chartRef}>
        {data?.map((item) => {
          return (
            <WhiteBoxContainer center key={item.title}>
              <div className={styles["iconContainer"]}>
                <img src={item.icon} alt='icon' />
                <div className={styles["titleContainer"]}>
                  <h3>{item.title}</h3>
                  <h5>{item.number}</h5>
                </div>
              </div>
            </WhiteBoxContainer>
          );
        })}
      </div>
    </DashboardContainer>
  );
};

export default DashboardCategories;

const formatDate = (date) => date.toISOString().split("T")[0];

export const getDateRange = (optionValue) => {
  const today = new Date();
  let startDate, endDate;

  switch (optionValue) {
    case "today":
      startDate = formatDate(today);
      endDate = formatDate(new Date(today.setDate(today.getDate() + 1)));
      break;
    case "week":
      const firstDayOfWeek = today.getDate() - today.getDay();
      startDate = formatDate(new Date(today.setDate(firstDayOfWeek)));
      endDate = formatDate(new Date(today.setDate(firstDayOfWeek + 6)));
      break;
    case "month":
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      startDate = formatDate(firstDayOfMonth);
      endDate = formatDate(lastDayOfMonth);
      break;
    case "year":
      const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
      const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
      startDate = formatDate(firstDayOfYear);
      endDate = formatDate(lastDayOfYear);
      break;
    default:
      startDate = "";
      endDate = "";
  }

  return { startDate, endDate };
};

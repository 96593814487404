import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

const login = (values) => {
  return axios.post(API_URL + `/api/Auth/login`, values);
};

export const useLogin = () => {
  return useMutation(login);
};

//validate user

const validateUser = async () => {
  return await axios.get(API_URL + `/api/ApplicantView/Validate`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useValidateUser = () => {
  return useQuery({
    queryKey: ["validateUSer"],
    queryFn: () => validateUser(),
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};

import React from "react";
import thankYouImg from "../../../assets/Imagess/thankYouIImage.svg";
import styles from "./RecomendationPage.module.css";
const REferenceSuccessForm = () => {
  return (
    <div className={styles.success}>
      <img src={thankYouImg} alt='thk' />
      <h1>Thank You</h1>
      <p>Letter of recommendation from your support was submitted</p>
    </div>
  );
};

export default REferenceSuccessForm;

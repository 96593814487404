import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import LoginPage from "../components/Template/LoginTemplate/LoginPage";
import DashboardPage from "../components/Template/DashboardTemplate/DashboardPage";
import UserProfile from "../components/Template/UserProfileTemplate/UserProfile";
import UsersPage from "../components/Template/UsersTemplate/UsersPage";
import NewTemplateEmail from "../components/Template/NewTemplateEmail/NewTemplateEmail";
import EmailPage from "../components/Template/EmailTemplate/EmailPage";
import SettingsBody from "../components/HOC/SettingsBody/SettingsBody";
import SingleApplicantPAge from "../components/Template/SingleApplicantTemplate/SingleApplicantPAge";
import AplicantsPage from "../components/Template/ApplicantTemplate/AplicantsPage";
import GeneralBody from "../components/HOC/GeneralBody/GeneralBody";
import RecomemdationPage from "../components/Template/RecomendationTemplate/RecomemdationPage";
import CampaignPage from "../components/Template/Campaign/Campaigns/CampaignPage";
import EmailTemCampaignPage from "../components/Template/Campaign/EmailCampaign/EmailTemCampaignPage";
import AudiencePage from "../components/Template/Campaign/Audience/AudiencePage";
import AddExternalContact from "../components/Moecules/Settings/ExternalContact/AddExternalContact";
import UsersAssignmentPage from "../components/Template/UserAssignmentPage/UsersAssignmentPage";
import SingleCampaignPage from "../components/Template/Campaign/SingleCampaign/SingleCampaignPage";
import NewAudiencePage from "../components/Template/Campaign/Audience/NewAudiencePage";
import NewCampaignPage from "../components/Template/Campaign/Campaigns/NewCampaignPage";
import SingleAudiencePage from "../components/Template/SingleAudience/SingleAudiencePage";
import ReferencePage from "../components/Template/RecomendationTemplate/ReferencePage";
import ExternalContactSettings from "../components/Moecules/Settings/ExternalPages/ExternalContactSettings";
import SingleExternalContactPage from "../components/Template/ContactPage/SingleExternalContactPage";
import ExternalContactPage from "../components/Template/ExternalContactTemplate/ExternalContactPage";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PublicRoutes />}>
        <Route index element={<LoginPage />} />
      </Route>
      <Route path='/home' element={<PrivateRoutes />}>
        <Route index element={<Navigate to='/home' />} />
        <Route path='' element={<GeneralBody />}>
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='profile' element={<UserProfile />} />{" "}
          <Route path='external-contact' element={<ExternalContactPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route path='users-assignment' element={<UsersAssignmentPage />} />
          <Route path='applicants' element={<AplicantsPage />} />
          <Route path='applicants/:id' element={<SingleApplicantPAge />} />
          <Route path='interested/:id' element={<SingleApplicantPAge />} />
          <Route path='settings' element={<SettingsBody />} />
          <Route path='email' element={<EmailPage />} />
          <Route path='new-email' element={<NewTemplateEmail />} />{" "}
          <Route path='new-email/:id' element={<NewTemplateEmail />} />
          <Route path='campaigns' element={<CampaignPage />} />
          <Route path='campaign-audience' element={<AudiencePage />} />
          <Route path='campaign-details/:id' element={<SingleCampaignPage />} />
          <Route path='new-audience' element={<NewAudiencePage />} />
          <Route path='new-audience/:id' element={<NewAudiencePage />} />
          <Route path='audienceDetails/:id' element={<SingleAudiencePage />} />
          <Route path='new-campaign' element={<NewCampaignPage />} />
          <Route path='new-campaign/:id' element={<NewCampaignPage />} />
          <Route
            path='campaign-templateEmail'
            element={<EmailTemCampaignPage />}
          />
          <Route
            path='contact-details/:id'
            element={<SingleExternalContactPage />}
          />
          <Route path='add-externalContact' element={<AddExternalContact />} />
          <Route
            path='edit-externalContact/:id'
            element={<AddExternalContact />}
          />
        </Route>
      </Route>
      <Route path='/recomendation' element={<ReferencePage />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default MainRoutes;

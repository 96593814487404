import React, { useState } from "react";
import MoreButton from "../../Custom/PopOver/MoreButton";
import PopOver from "../../Custom/PopOver/PopOver";
import launchIcon from "../../../../assets/Imagess/Campaign/launch.svg";
import cancelIcon from "../../../../assets/Imagess/Campaign/cancel.svg";
import stopIcon from "../../../../assets/Imagess/Campaign/Stop_Circle.svg";
import onHoldIcon from "../../../../assets/Imagess/Campaign/onHold.svg";
import deleteIcon from "../../../../assets/Imagess/Campaign/delete.svg";
import edit from "../../../../assets/Imagess/Campaign/Edit.svg";
import duplicate from "../../../../assets/Imagess/Campaign/duplicate.svg";
import moreIcon from "../../../../assets/Imagess/moreicon.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCancelCampaign,
  useDeleteCampaign,
  useDuplicateCampaign,
  useLaunchCampaign,
  useOnHoldCampaign,
  useResumeCampaign,
  useStopCampaign,
} from "../../../../Hooks/Campaign";
import { toast } from "react-toastify";
import DeletePopup from "../../Custom/DeletePopup";
const CampaignSettings = ({ id, title, refetch, status }) => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState({
    show: false,
    mode: "",
  });
  const { mutate: stopCampaign, isLoading: isStoping } = useStopCampaign();
  const { mutate: onHoldCampaign, isLoading: isHolding } = useOnHoldCampaign();
  const { mutate: LaunchCampaign, isLoading: isLauching } = useLaunchCampaign();
  const { mutate: duplicateCampaign, isLoading: isDuplicating } =
    useDuplicateCampaign();
  const { mutate: deleteCampaign, isLoading: isDeleting } = useDeleteCampaign();
  const { mutate: cancelCampaign, isLoading: isCanceling } =
    useCancelCampaign();
  const { mutate: resumeCampaign, isLoading: isResuming } = useResumeCampaign();

  const handleEditfunction = (e) => {
    e.stopPropagation();
    navigate(`/home/new-campaign/${id}`);
  };

  const handleFunction = (e) => {
    e.stopPropagation();
    if (mode.mode == "Stop") {
      stopCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is stoped`);
          setMode({ mode: "", show: false });
          refetch();
        },
        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    } else if (mode.mode == "Launch") {
      LaunchCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is launched`);
          setMode({ mode: "", show: false });
          refetch();
        },
        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    } else if (mode.mode == "Duplicate") {
      duplicateCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is duplicated`);
          setMode({ mode: "", show: false });
          refetch();

          navigate("/home/campaigns");
        },
        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    } else if (mode.mode == "Cancel") {
      cancelCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is cancelled`);
          setMode({ mode: "", show: false });
          refetch();
        },
        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    } else if (mode.mode == "Delete") {
      deleteCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is deleted`);
          setMode({ mode: "", show: false });
          refetch();
          navigate("/home/campaigns");
        },
        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    } else if (mode.mode == "Resume") {
      resumeCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is resumed`);
          setMode({ mode: "", show: false });
          refetch();
          navigate("/home/campaigns");
        },
        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    } else {
      onHoldCampaign(id, {
        onSuccess: () => {
          toast.success(`${title} campaign is onHold`);
          setMode({ mode: "", show: false });
          refetch();
        },

        onError: () => {
          toast.error(`Something went wrong`);
          refetch();
        },
      });
    }
  };

  const isLaunched = status == "Launched";
  const isReadyToLaunch = status == "Ready to Launch";
  const isOnHold = status == "On Hold";
  const isCancelled = status == "Cancelled";
  const isStopped = status == "Stopped";
  const info = [
    ...(isReadyToLaunch
      ? [
          {
            title: "Launch",
            icon: launchIcon,
            handleFunction: (e) => {
              e.stopPropagation();
              setMode({ show: true, mode: "Launch" });
            },
          },
        ]
      : isLaunched
      ? [
          {
            title: "On Hold",
            icon: onHoldIcon,
            handleFunction: (e) => {
              e.stopPropagation();
              setMode({ show: true, mode: "onHold" });
            },
          },
        ]
      : isOnHold
      ? [
          {
            title: "Resume",
            icon: onHoldIcon,
            handleFunction: (e) => {
              e.stopPropagation();
              setMode({ show: true, mode: "Resume" });
            },
          },
        ]
      : []),
    ...(!isCancelled && (isOnHold || isStopped)
      ? [
          {
            title: "Cancel",
            icon: cancelIcon,
            handleFunction: (e) => {
              e.stopPropagation();
              setMode({ show: true, mode: "Cancel" });
            },
          },
        ]
      : []),

    ...(isLaunched
      ? [
          {
            title: "Stop",
            icon: stopIcon,
            handleFunction: (e) => {
              e.stopPropagation();
              setMode({ show: true, mode: "Stop" });
            },
          },
        ]
      : []),
    {
      title: "Duplicate",
      icon: duplicate,
      handleFunction: (e) => {
        e.stopPropagation();
        setMode({ show: true, mode: "Duplicate" });
      },
    },
    ...(isReadyToLaunch || isLaunched
      ? [
          {
            title: "Edit",
            icon: edit,
            handleFunction: handleEditfunction,
          },
        ]
      : []),

    {
      title: "Delete",
      icon: deleteIcon,
      handleFunction: (e) => {
        e.stopPropagation();
        setMode({ show: true, mode: "Delete" });
      },
    },
  ];

  return (
    <>
      <MoreButton icon={moreIcon} toolTipText='More'>
        <PopOver scroll info={info} />
      </MoreButton>
      {mode.show && (
        <DeletePopup
          title={title}
          handleOnClose={(e) => (
            setMode({ mode: "", show: false }), e.stopPropagation()
          )}
          handleDelete={handleFunction}
          mode={mode.mode}
          text={`Are you sure you want to ${mode.mode}`}
          isLoading={
            isHolding ||
            isLauching ||
            isStoping ||
            isDuplicating ||
            isDeleting ||
            isCanceling ||
            isResuming
          }
        />
      )}
    </>
  );
};

export default CampaignSettings;

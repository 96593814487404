import React, { useEffect, useRef, useState } from "react";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import { useShowAudienceResult } from "../../../Hooks/Audience";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import * as tableHeader from "../TableHeader/NewAudienceTableHeader";
import TableComponent from "../TableComponent/TableComponent";
import EmptyText from "../Custom/EmptyText";
import TableTopHeader from "../TableTopHeader/TableTopHeader";
import { useExportShowResult } from "../../../Hooks/UserAssignment";
import ColumnVisibility from "../TableTopHeader/ColumnVisibility";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../Custom/ExportComponent/PrintComponent";
import NoDataComponent from "../Custom/NoData/NoDataComponent";

const SingleAudience = ({ audienceDetails }) => {
  const printRef = useRef();

  const [audienceData, setAudienceData] = useState([]);
  const [queryState, setQueryState] = useState({
    0: [],
    1: [],
    2: [],
  });
  const [valuesToExport, setValuesToExport] = useState({});

  const [visibleColumn, setVisibleColumn] = useState({
    firstName: true,
    contactType_Display: true,
    selectedTerm_Display: true,
    fieldOfInterest_Display: true,
    applicationStart_Display: true,
    nationality_Display: true,
    type: true,
    statusText: true,
  });

  const { mutate: showResult, isLoading: isShowingAudience } =
    useShowAudienceResult();
  const exportShowResult = useExportShowResult();

  useEffect(() => {
    if (audienceDetails) {
      setQueryState({
        0: audienceDetails
          ?.filter((item) => item.audienceType == 0)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
        1: audienceDetails
          ?.filter((item) => item.audienceType == 1)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
        2: audienceDetails
          ?.filter((item) => item.audienceType == 2)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
      });
    }
  }, [audienceDetails]);

  const handleShowResult = () => {
    const mappedQueryState = {
      prospectFilters: queryState[0],
      leadFilters: queryState[1],
      externalContactFilters: queryState[2],
    };
    setValuesToExport(mappedQueryState);
    showResult(mappedQueryState, {
      onSuccess: (data) => {
        setAudienceData(data?.data);
        // if (
        //   !data?.data?.leads?.length > 0 &&
        //   !data?.data?.applicants?.length > 0 &&
        //   !data?.data?.externalContacts?.length > 0
        // ) {
        //   toast.error("No Audience Found");
        // }
      },
    });
  };

  useEffect(() => {
    handleShowResult();
  }, [audienceDetails, queryState]);

  const combinedData = audienceData
    ? [
        ...(Array.isArray(audienceData?.applicants)
          ? audienceData.applicants.map((applicant) => ({
              ...applicant,
              type: "applicant",
              id: applicant.applicantId,
            }))
          : []),
        ...(Array.isArray(audienceData?.externalContacts)
          ? audienceData.externalContacts.map((contact) => ({
              ...contact,
              type: "contact",
              id: contact.externalContactId,
              firstName: contact.contactName,
            }))
          : []),
        ...(Array.isArray(audienceData?.leads)
          ? audienceData.leads.map((lead) => ({
              ...lead,
              type: "lead",
              id: lead.studentId,
            }))
          : []),
      ]
    : [];
  const handleExport = async () => {
    try {
      const data = await exportShowResult.mutateAsync(valuesToExport);

      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "AudienceTable";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected Blob.", data);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const tableData = {
    data: {
      items: combinedData.map((item) => ({
        ...item,
        id: item.id,
      })),
    },
    refetch: null,
    isLoading: isShowingAudience,
    tableHeader: tableHeader.newAudienceTableHeader,
  };
  const rightButtons = (
    <ColumnVisibility
      tableHeader={tableHeader.newAudienceTableHeader}
      visibleColumn={visibleColumn}
      setVisibleColumn={setVisibleColumn}
    />
  );

  return (
    <WhiteContainer white>
      {isShowingAudience ? (
        <Loader />
      ) : combinedData?.length > 0 ? (
        <>
          <TableTopHeader
            handleExport={handleExport}
            leftComponent={
              <div>
                <h1 style={{ fontWeight: "700", fontSize: "16px" }}>
                  Future Learners League
                </h1>
                <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                  Total: {combinedData?.length}
                </h3>
              </div>
            }
            rightButtons={rightButtons}
            handlePrint={handlePrint}
          />
          <TableComponent
            tableData={tableData}
            isLoading={false}
            visibleColumn={visibleColumn}
            columnVisibility
          />
        </>
      ) : (
        // <EmptyText text='Audience' />
        <NoDataComponent text='Audience' />
      )}
      <div style={{ display: "none" }}>
        <PrintComponent
          ref={printRef}
          title='Audience Table'
          data={tableData}
        />
      </div>
    </WhiteContainer>
  );
};

export default SingleAudience;

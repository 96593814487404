import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";
import { usePostTableData } from "./services/postCall";

//add external contact
const addESignature = (values) => {
  return axios.post(
    API_URL + `/api/Notifications/PostEmailSignatures`,
    values,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useAddESignature = () => {
  return useMutation(addESignature);
};

//get External Contact
export const useFetchESignature = (data) => {
  return usePostTableData(
    data,
    "/api/Notifications/GetEmailSignatures",
    "eSignature"
  );
};
/****************fetch unpaged data */
const fetchUnpagedESignature = async (data) => {
  return await axios.get(
    API_URL + `/api/Notifications/GetUnPagedEmailSignatures`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};
export const useFetchUnpagedESignature = (data) => {
  return useQuery({
    queryKey: ["unpagedESignature", data],
    queryFn: () => fetchUnpagedESignature(data),
    enabled: true,
  });
};

//delete by id external contact by id
const deleteEmailSignature = (emailSignature) => {
  return axios.post(
    API_URL + `/api/Notifications/DeleteEmailSignature/${emailSignature}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDeleteEmailSignature = () => {
  return useMutation(deleteEmailSignature);
};
////export e-signature
const exportEmailSignature = async () => {
  return await axios.get(API_URL + `/api/Export/ExportEmailSignature`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportEmailSignature = () => {
  return useMutation(() => exportEmailSignature());
};

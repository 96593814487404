import React, { createContext, useState } from "react";

const formatDate = (date) => date.toISOString().split("T")[0];
const today = new Date();
const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

export const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {
  const [applicationStart, setApplicationStart] = useState(-1);
  const [date, setDate] = useState({
    startDate: formatDate(firstDayOfYear),
    endDate: formatDate(lastDayOfYear),
  });

  return (
    <ApplicationContext.Provider
      value={{ applicationStart, setApplicationStart, date, setDate }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

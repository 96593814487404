import React, { createContext, useContext, useState } from "react";

const TableFilterValuesContext = createContext();

export const TableFilterValuesProvider = ({ children }) => {
  const [filterValues, setFilterValues] = useState({});
  const [filterColumns, setFilterColumns] = useState([]);

  const reset = () => {
    setFilterValues({});
    setFilterColumns([]);
  };

  return (
    <TableFilterValuesContext.Provider
      value={{
        filterValues,
        setFilterValues,
        filterColumns,
        setFilterColumns,
        reset,
      }}
    >
      {children}
    </TableFilterValuesContext.Provider>
  );
};

export const useTableFilterValuesContext = () =>
  useContext(TableFilterValuesContext);

import React, { useEffect, useState } from "react";
import styles from "./UsersAssignment.module.css";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import Toggle from "../../Atoms/Inputs/Toggle";
import UsersAssignment from "../../Moecules/UsersAssignment/DataComponent/UsersAssignment";
import ColoredButton from "../../Atoms/Buttons/ColoredButtons/ColoredButton";
import runIcon from "../../../assets/Imagess/Users/UsersAssignment/runIcon.svg";
import LPCQuery from "../../Moecules/Custom/QueryComponent/LeadsProspectContactQuery.jsx/LPCQuery";
import { useShowAudienceResult } from "../../../Hooks/Audience";
import { toast } from "react-toastify";
import Loader from "../../Moecules/Loader/Loader";
import Spinner from "../../Moecules/Loader/Spinner";
import { useEdges } from "reactflow";

const UsersAssignmentPage = () => {
  const [isReset, setIsReset] = useState(false);
  const [queryState, setQueryState] = useState({
    0: [],
    1: [],
    2: [],
  });
  const [valuesToExport, setValuesToExport] = useState({});
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("");
  const [usersAssignmentData, setUsersAssignmentData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterByField, setFilterByField] = useState({
    filterByField: "",
    filterBy: "",
  });
  const [isMounted, setIsMounted] = useState(false);
  const [assigned, setAssigned] = useState(null);
  const {
    mutate: showResult,
    isLoading: isShowingUsrsAssignment,
    refetch: refetchShowingAssignment,
  } = useShowAudienceResult();

  const isQueryStateNotEmpty = Object.values(queryState).some(
    (queries) => queries?.length > 0
  );

  const handleEmptyQuery = () => {
    toast.error("Please choose a query to run it");
  };
  const handleShowResult = () => {
    setIsMounted(true);
    if (!isQueryStateNotEmpty) {
      toast.error("Please choose a query to run it");
      return;
    }

    const mappedQueryState = {
      prospectFilters: queryState[0],
      leadFilters: queryState[1],
      externalContactFilters: queryState[2],
    };

    const hasEmptyValueIds = (filters) => {
      return filters.some(
        (filter) =>
          filter.andOr === undefined ||
          filter.andOr === "" ||
          !filter.audienceOptionId ||
          filter.inNotIn == undefined ||
          !filter.valueIds
      );
    };

    if (
      hasEmptyValueIds(mappedQueryState.prospectFilters) ||
      hasEmptyValueIds(mappedQueryState.leadFilters) ||
      hasEmptyValueIds(mappedQueryState.externalContactFilters)
    ) {
      toast.error("Please fill all query fields before proceeding.");
      return;
    }

    const valuestoBeSent = {
      prospectFilters: queryState[0],
      leadFilters: queryState[1],
      externalContactFilters: queryState[2],
      orderBy: orderBy,
      orderDir: orderDir,
      searchBy: search,
      filterByField: filterByField.filterByField,
      filterBy: filterByField.filterBy,
      assigned: assigned,
    };
    setValuesToExport(valuestoBeSent);

    showResult(valuestoBeSent, {
      onSuccess: (data) => {
        setUsersAssignmentData(data?.data);
        if (
          !data?.data?.leads?.length > 0 &&
          !data?.data?.applicants?.length > 0 &&
          !data?.data?.externalContacts?.length > 0
        ) {
          toast.error("No Data Found");
        }
      },
    });
  };

  const isShowResult =
    usersAssignmentData?.leads?.length > 0 ||
    usersAssignmentData?.applicants?.length > 0 ||
    usersAssignmentData?.externalContacts?.length > 0;

  const handleReset = () => {
    setQueryState({
      0: [],
      1: [],
      2: [],
    });
    setUsersAssignmentData([]);
    setIsReset(true);
    setIsMounted(false);
    setValuesToExport({});
    setTimeout(() => {
      setIsReset(false);
    }, 1000);
  };

  useEffect(() => {
    if (isMounted && (orderBy !== "" || orderDir !== "")) {
      handleShowResult();
    }
  }, [orderBy, orderDir]);

  useEffect(() => {
    if (isMounted && (search !== undefined || filterByField.filterBy !== "")) {
      handleShowResult();
    }
  }, [search, filterByField.filterBy]);

  useEffect(() => {
    if (assigned != null) {
      if (assigned) {
        toast.warning(
          "You will get all applicants that are assigned to a user after pressing run"
        );
      } else {
        toast.warning(
          "You will get all applicants that aren't assigned to user after pressing run"
        );
      }
    }
  }, [assigned]);

  useEffect(() => {}, []);
  return (
    <WrappingContainer>
      <div className={styles["userAssignment_upperButtons"]}>
        <Toggle
          text='Assigned'
          isActive={assigned}
          onChange={() => setAssigned((prev) => !prev)}
        />
        {isShowingUsrsAssignment ? (
          <Loader />
        ) : (
          <div style={{ display: "flex", gap: "5px" }}>
            {isQueryStateNotEmpty ? (
              <ColoredButton
                unfilled
                title='Reset Result'
                rounded
                handleOnClick={handleReset}
              />
            ) : null}
            <ColoredButton
              title='Run'
              red
              rounded={true}
              icon={runIcon}
              handleOnClick={
                !isQueryStateNotEmpty ? handleEmptyQuery : handleShowResult
              }
            />
          </div>
        )}
      </div>

      <LPCQuery
        disabled={false}
        queryState={queryState}
        setQueryState={setQueryState}
        isReset={isReset}
      />
      {isMounted && (
        <UsersAssignment
          usersAssignmentData={usersAssignmentData}
          setOrderBy={setOrderBy}
          setOrderDir={setOrderDir}
          orderDir={orderDir}
          orderBy={orderBy}
          refetch={handleShowResult}
          handleShowResult={handleShowResult}
          setSearch={setSearch}
          isShowingUsrsAssignment={isShowingUsrsAssignment}
          setFilterByField={setFilterByField}
          isShowResult={isShowResult}
          valuesToExport={valuesToExport}
        />
      )}
    </WrappingContainer>
  );
};

export default UsersAssignmentPage;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./RightSideComp.module.css";
import TextInput from "../../../../../Atoms/Inputs/TextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import emailIcon from "../../../../../../assets/Imagess/emailinputicon.svg";
import { useFetchActiveNotifications } from "../../../../../../Hooks/Notification";
import CustomizedSelect from "../../../../../Atoms/Inputs/CustomizedSelect";

const EmailForm = forwardRef(({ selectedNode }, ref) => {
  const emailRef = useRef();
  const [init, setInit] = useState({});
  const [emailPreview, setEmailPreview] = useState({});
  const [emailId, setEmailId] = useState("");
  const { data: emails, refetch: refetchEmails } =
    useFetchActiveNotifications(true);
  const validationSchema = Yup.object().shape({
    emailCategory: Yup.string().required("Email category is required"),
    email: Yup.string().required("Email is required"),
  });

  useEffect(() => {
    if (selectedNode) {
      const initialValues = {
        emailCategory: selectedNode?.data?.label?.emailCategory || "",
        email: selectedNode?.data?.label?.email || "",
      };
      if (
        initialValues.emailCategory !== init.emailCategory ||
        initialValues.email !== init.email
      ) {
        setInit(initialValues);
      }
    }
  }, [selectedNode, init.emailCategory, init.email]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      emailRef.current.submitForm();
    },
    get isValid() {
      return emailRef.current.isValid;
    },
    get values() {
      return emailRef.current.values;
    },
  }));

  useEffect(() => {
    ref.current = emailRef.current;
  }, [ref]);

  useEffect(() => {
    const filterEmail = emails?.data?.filter(
      (value) =>
        value.notificationId === emailId ||
        value.notificationId === emailRef?.current?.values?.email
    );
    setEmailPreview(filterEmail && filterEmail[0]);
  }, [emailId, emailRef?.current?.values?.email]);
  return (
    <Formik
      initialValues={init}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
      innerRef={emailRef}
      validateOnMount={true}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div className={styles["email_container"]}>
            <TextInput
              label='Email Category'
              icon={emailIcon}
              placeholder='Enter Category'
              name='emailCategory'
              value={values.emailCategory}
              required={true}
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
              errors={errors.emailCategory}
              touched={touched.emailCategory}
            />

            <CustomizedSelect
              options={emails?.data?.map(
                ({ notificationId, notificationName }) => ({
                  value: notificationId,
                  text: notificationName,
                })
              )}
              label='Email'
              icon={emailIcon}
              placeholder='Select Email Template'
              name='email'
              value={values.email}
              required={true}
              onChange={(name, value) => {
                setFieldValue(name, value);
                setEmailId(value);
              }}
              errors={errors.email}
              touched={touched.email}
            />

            {emailPreview && (
              <p
                dangerouslySetInnerHTML={{
                  __html: emailPreview.notificationContent?.replace(
                    /<p /g,
                    '<p style="max-width:350px;max-height:350px" '
                  ),
                }}
              ></p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default EmailForm;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AudienceList from "./left&Right Components/AudienceList";
import FilterApplied from "./left&Right Components/FilterApplied";
import styles from "./SelectedAudience.module.css";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useAddCampaignstage2 } from "../../../../../Hooks/Campaign";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { useParams } from "react-router-dom";
const SelectedAudience = forwardRef(
  ({ campaignDetails, isLoadingdata, setActiveStep }, ref) => {
    const { campaignId } = useParams();
    const [init, setInit] = useState({});
    const { mutate: addStage2, isLoading: isAddingAudience } =
      useAddCampaignstage2();

    const validationSchema = Yup.object().shape({
      audienceId: Yup.string().required("Audience  is required"),
    });
    useEffect(() => {
      const initialValues = { audienceId: campaignDetails?.audienceId || "" };
      setInit(initialValues);
    }, [campaignDetails]);

    const formik = useFormik({
      initialValues: init,
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        const id = localStorage.getItem("campaignId") || campaignId;
        const valuesToAdd = { ...values, campaignId: id };
        addStage2(valuesToAdd, {
          onSuccess: () => {
            setActiveStep((prevStep) => prevStep + 1);
          },
        });
      },
    });

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formik.submitForm();
      },
    }));

    useEffect(() => {
      ref.current = formik;
    }, [ref, formik]);
    if (isLoadingdata) {
      return <Loader />;
    }
    return (
      <FormikProvider value={formik} innerRef={ref}>
        {isAddingAudience ? (
          <Loader />
        ) : (
          <div className={styles["form"]}>
            <AudienceList />
            <FilterApplied />
          </div>
        )}
      </FormikProvider>
    );
  }
);

export default SelectedAudience;

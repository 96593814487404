import React from "react";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import CampaignDetailedBox from "../Campaign/CampaignDetails/CampaignDetailedBox";
import EmptyText from "../Custom/EmptyText";
import NoDataComponent from "../Custom/NoData/NoDataComponent";

const SingleCampaignAudience = ({ campaigns }) => {
  return (
    <WhiteContainer white>
      {campaigns?.length > 0 ? (
        campaigns?.map((item) => {
          return <CampaignDetailedBox data={item} contact />;
        })
      ) : (
        // <EmptyText text='Campaigns' />
        <NoDataComponent text='Campaign' />
      )}
    </WhiteContainer>
  );
};

export default SingleCampaignAudience;

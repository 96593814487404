import React, { useContext } from 'react'
import AgeGraph from './AgeGraph';
import { useFetchAgeGraphData } from '../../../../../Hooks/Dashboard';
import { ApplicationContext } from '../../../../../Context/DashbaordContext';

const AgeGraphComponent = () => {
      const { date, applicationStart } = useContext(ApplicationContext);
      const { data, isLoading } = useFetchAgeGraphData({
        startDate: date.startDate,
        endDate: date.endDate,
        applicationStart: applicationStart,
      });

  return <AgeGraph  isLoading={isLoading} ageData={data?.data}/>;
}

export default AgeGraphComponent

import React, { useEffect, useState } from "react";
import styles from "./ReferenceMolecule.module.css";
import { useFetchApplicationByApplicantAndApplication } from "../../../../Hooks/SingleApplicant";
import { useParams } from "react-router-dom";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
import MoreButton from "../../Custom/PopOver/MoreButton";
import ReferenceDocumentIcon from "../../../../assets/Imagess/refrenceDocumentIcon.svg";
import moreIcon from "../../../../assets/Imagess/more-vet-svgrepo-com 1.svg";
import downloadIcon from "../../../../assets/Imagess/downloadIcon.svg";
import PopOver from "../../Custom/PopOver/PopOver";
import { useDownloadReferenceDocument } from "../../../../Hooks/Reference";
import { toast } from "react-toastify";
const ReferenceMolecule = ({ data }) => {
  console.log("Cdsncsj", data);
  const { id } = useParams();
  const [applicationId, setApplicationId] = useState(
    data?.applications[0]?.applicationId
  );
  const { data: applicationDataById } =
    useFetchApplicationByApplicantAndApplication(id, applicationId);

  const downloadReferenceDocument = useDownloadReferenceDocument();
  useEffect(() => {
    {
      data?.applications ??
        setApplicationId(data?.applications[0]?.applicationId);
    }
  }, [data]);

  const handleDownload = async (doc) => {
    try {
      const data = await downloadReferenceDocument.mutateAsync(doc);
      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Reference Document";
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success("Document is Downloaded");
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("File has not been submitted yet");
    }
  };
  return data?.applicantReferance?.map((ref) => {
    if (ref?.referanceEmail == null) {
      return;
    }
    return (
      <div className={styles["reference-container"]}>
        <div className={styles["reference"]}>
          <div className={styles["reference-subcontainer"]}>
            <TextContainer
              title='Title'
              data={ref?.referanceTitle_Display}
              required={false}
            />
            <TextContainer
              title='Name'
              data={ref?.referanceName}
              required={false}
            />
            <TextContainer
              title='Email'
              data={ref?.referanceEmail}
              required={false}
            />{" "}
            <TextContainer
              title='How do you know the reference: '
              data={ref?.knowTheReferance}
            />
          </div>

          <div className={styles["document-container"]}>
            <h2>Letter of Recomendation</h2>
            <div className={styles["document-subcontainer"]}>
              {ref?.applicantReferanceId && ref?.referanceName != null ? (
                <>
                  {" "}
                  <div className={styles["document"]}>
                    <img src={ReferenceDocumentIcon} alt='reference document' />
                    <div>
                      <h2>Reference.pdf</h2>
                      <h4>Reference</h4>
                    </div>
                  </div>
                  <MoreButton
                    icon={moreIcon}
                    profileImg='profile-img'
                    width='200px'
                    imgWidth='25px'
                    imgHeight='20px'
                    top='1rem'
                    toolTipText='More'
                  >
                    <PopOver
                      stop
                      info={[
                        {
                          title: "Download",
                          icon: downloadIcon,
                          handleFunction: () =>
                            handleDownload(ref?.applicantReferanceId),
                        },
                        // {
                        //   title: "Print",
                        //   icon: printIcon,
                        // },
                        // {
                        //   title: "Delete",
                        //   icon: deleteicon,
                        // },
                      ]}
                    />
                  </MoreButton>
                </>
              ) : (
                <TextContainer attachement={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default ReferenceMolecule;

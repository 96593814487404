import React from "react";
import styles from "./TableFilter.module.css";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import filter from "../../../../assets/Imagess/filter.svg";
import closeIcon from "../../../../assets/Imagess/SettingsSideBar/exitBurgerSettingsIco.svg";
const TableFilter = ({
  isFilter,
  setIsFilter,
  setFilterByField,
  setFilteredColumn=null,
}) => {
  return (
    <div className={`${styles["filterIcon"]}`}>
      <Tooltip content='Filter'>
        <img
          src={isFilter ? closeIcon : filter}
          alt='filter'
          onClick={() => (
            setIsFilter(!isFilter),
            setFilterByField({
              filterByField: "",
              filterBy: "",
            }),
            setFilteredColumn!=null ? setFilteredColumn([]) : null
          )}
        />
      </Tooltip>
    </div>
  );
};

export default TableFilter;

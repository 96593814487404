import React from "react";
import styles from "./FilledUnFilledText.module.css";
const FlagComponent = ({ icon, text }) => {
  return (
    <div>
      <span className={styles["flag"]}>
        {icon && <img src={icon} alt='deleted icon' />}
        <h5>{text}</h5>
      </span>
    </div>
  );
};

export default FlagComponent;

import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";
import { useGetData } from "./services/getCall";
import { usePostTableData } from "./services/postCall";

//get field options

export const useGetFieldOption = (type) => {
  const url = `/api/Audience/GetFieldOptions/${type}`;
  return useGetData(url, "fieldOptionsByType", type != undefined);
};

//get valueAPI
const getValueAPI = async (apiUrl) => {
  if (apiUrl != undefined) {
    return axios.get(`${API_URL}/${apiUrl}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};
export const useGetValueAPI = (apiUrl) => {
  return useQuery({
    queryKey: ["apiUrl", apiUrl],
    queryFn: () => getValueAPI(apiUrl),
    enabled: true,
  });
};

//show results
const showAudienceResult = (fields) => {
  return axios.post(API_URL + `/api/Audience/ShowResult`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useShowAudienceResult = () => {
  return useMutation(showAudienceResult);
};

///post audience
const addNewAudience = (fields) => {
  return axios.post(API_URL + `/api/Audience/Audience`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddNewAudience = () => {
  return useMutation(addNewAudience);
};

//fetch audience

export const useFetchAudience = (data) => {
  let url = `${API_URL}/api/Audience/GetAudience`;

  return usePostTableData(data, url, "audiencedata");
};
//fetch audience by id
const fechAudienceById = async (id) => {
  if (id) {
    return axios.get(API_URL + `/api/Audience/GetAudienceById/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};

export const useFetchAudienceById = (id) => {
  return useQuery({
    queryKey: ["audiencedataById", id],
    queryFn: () => fechAudienceById(id),
    enabled: true,
  });
};

//delete audience by id
const deleteAudiencebyId = (audeieceId) => {
  return axios.delete(API_URL + `/api/Audience/DeleteAudience/${audeieceId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteAudiencebyId = () => {
  return useMutation(deleteAudiencebyId);
};

//*********export audience table */
const exportAudience = async () => {
  return await axios.get(API_URL + "/api/Export/ExportAudience", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportAudienceTable = () => {
  return useMutation(() => exportAudience());
};

import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";
import { usePostTableData } from "./services/postCall";
import { useGetData } from "./services/getCall";

/****************fetch applicant and interested*/
export const useFetchPostApplicantData = (data) => {
  const shouldFetchData = !!data?.selected;
  return usePostTableData(
    data,
    "/api/ApplicantView/GetApplicants",
    "ApplicantData",
    shouldFetchData
  );
};

export const useFetchPostInterestedData = (data) => {
  const shouldFetchData = !!data?.selected;
  return usePostTableData(
    data,
    "/api/ApplicantView/GetShowingInterest",
    "InterestedData",
    shouldFetchData
  );
};

/****************fetch single applicant */
export const useFetchSingleApplicant = (data) => {
  const url = `/api/ApplicantView/GetApplicantById?ApplicantId=${data?.id}`;
  return useGetData(url, "ApplicantById", data?.isApplicant);
};

/***************Update applicant status */
const changeApplicantStatus = (fields) => {
  return axios.post(
    API_URL + `/api/ApplicantView/ChangeApplicantStatus`,
    fields,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useChangeApplicantsStatus = () => {
  return useMutation(changeApplicantStatus);
};
/***************Update  status i ncase cancel */

const bulkCancelApplicant = (fields) => {
  return axios.post(
    API_URL + `/api/ApplicantView/BulkCancelApplicant`,
    fields,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useBulkCancelApplicant = () => {
  return useMutation(bulkCancelApplicant);
};

/*******************Get applicant status */
const fetchApplicantStatus = async () => {
  return await axios.get(API_URL + "/api/ApplicantView/GetApplicantStatuses", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchApplicantStatus = () => {
  return useQuery({
    queryKey: ["applicantStatus"],
    queryFn: () => fetchApplicantStatus(),
    enabled: true,
  });
};

//*********export applicants table */
const exportApplicants = async () => {
  return await axios.get(API_URL + "/api/Export/ExportApplicants", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportApplicantsTable = () => {
  return useMutation(() => exportApplicants());
};

/***************Verify Applicant*/
const verifyApplicant = (data) => {
  return axios.post(
    API_URL +
      `/api/ApplicantView/ForceValidateApplicant/${data?.id}/${data?.email}/${data?.mobile}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useVerifyApplicant = () => {
  return useMutation(verifyApplicant);
};

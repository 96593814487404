import React, { useRef } from "react";
import styles from "./DashboardContainer.module.css";
import ExportDashboardButton from "./ExportButton/ExportDashboardButton";
import { handleExportPDF } from "./DashboardFunctions/ExportPDF";
import { useReactToPrint } from "react-to-print";
import { handleExportApexGraphs } from "./DashboardFunctions/ExportApexFunction";
import PrintComponent from "../Custom/ExportComponent/PrintComponent";
import NoDataDashboard from "./NoData/NoDataDashboard";
import Loader from "../Loader/Loader";

const DashboardContainer = ({
  children,
  none,
  title,
  hidden,
  noData,
  handleExport,
  chartRef,
  isLoading,
}) => {
  const printRef = useRef();

  const handleDownloadPDF = () => {
    handleExportPDF(chartRef, `${title}.graph`);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div
      className={styles["pieChart-cont"]}
      style={{
        backgroundColor: none ? "transparent" : "",
        padding: none ? "0px" : "",
        overflow: hidden ? "scroll" : "",
        display: children ? "flex" : "block",
      }}
    >
      {title && (
        <div className={styles["pieChart-subcont"]}>
          <h2>{title}</h2>
          {!noData && !isLoading && (
            <ExportDashboardButton
              handleExport={handleExport}
              handleDownloadPDF={handleDownloadPDF}
              handlePrint={handlePrint}
            />
          )}
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : noData ? (
        <NoDataDashboard />
      ) : (
        <PrintComponent ref={printRef} title={title}>
          <div
            style={{
              flex: "1 1 0%",
              overflow: hidden ? "scroll" : "",
              display: noData ? "flex" : "",
              justifyContent: noData ? "center" : "",
            }}
          >
            {children}
          </div>
        </PrintComponent>
      )}
    </div>
  );
};

export default DashboardContainer;

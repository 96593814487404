import React from "react";
import DashboardContainer from "../../DashboardContainer";
import RateContainer from "../../ApplicationAnalysis/Rate/RateChart/RateContainer/RateContainer";
import styles from "../CAmpaignReflection.module.css";
import Rate from "../../ApplicationAnalysis/Rate/Rate";
const CampaignStatistics = () => {
  const data = [
    {
      title: "Total Click Rate",
      text: "Percentage of applicants who complete the entire application process.",
      percentage: "0",
      color: "#3886BD",
    },
    {
      title: "Total Response Rate",
      text: "Average time taken to respond to an applications.",
      percentage: "0",
      color: "#F3223C",
    },
    {
      title: "Total Converted Rate",
      text: "not sure about it for now dummy",
      percentage: "0",
      color: "#FFB202",
    },
  ];

  return <Rate data={data} />;
};

export default CampaignStatistics;

import React from "react";
import styles from "./Stepper.module.css";
import doneICon from "../../../../../assets/Imagess/Campaign/NewCampaign/doneIcon.svg";

const Stepper = ({ steps, activeStep }) => {
  return (
    <div className={styles["steps-bar"]}>
      {steps.map((step, index) => {
        return (
          <div
            key={index}
            className={`${styles["bar"]} ${
              index === activeStep
                ? styles.active
                : index <= activeStep - 1
                ? styles.done
                : index == activeStep + 2
                ? styles.secondInactive
                : styles.inactive
            }`}
          >
            {index <= activeStep - 1 && <img src={doneICon} />}
            <p>{step.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;

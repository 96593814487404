import React, { useEffect, useState } from "react";
import styles from "./Input.module.css";
import messageIcon from "../../../assets/Imagess/messageIcon.svg";
import sendIcon from "../../../assets/Imagess/sendIcon.svg";
import closeIcon from "../../../assets/Imagess/xvector.svg";
import Tooltip from "../Tooltip/Tooltip";

const QuestionInput = ({
  onkeyDown,
  value,
  onChange,
  handleSubmit,
  placeholder,
  edit,
  onClose,
}) => {
  const [inputHeight, setInputHeight] = useState("20px");
  const [inputClassName, setInputClassName] = useState(styles["message-icon"]);

  const handleInputChange = (e) => {
    const input = e.target;
    setInputHeight(Math.max(40, input.scrollHeight) + "px");
    onChange(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setInputHeight("40px");
      onkeyDown(e);
    }
  };

  const handleFormSubmit = (e) => {
    setInputHeight("40px");
    handleSubmit(e);
  };

  const handleFocus = () => {
    setInputClassName(styles["message-icon-edit"]);
  };

  const handleBlur = () => {
    setInputClassName(styles["message-icon"]);
  };

  useEffect(() => {
    if (edit) {
      setInputHeight("100px");
      setInputClassName(styles["message-icon-edit"]);
    }
  }, [edit]);

  return (
    <div className={styles["question-bar"]}>
      <div className={styles["img-container"]} style={{ height: inputHeight }}>
        <img src={messageIcon} className={inputClassName} alt='icon' />
        {edit ? (
          <Tooltip content="Don't Save">
            <img
              onClick={onClose}
              src={closeIcon}
              className={styles["send-icon"]}
              style={{ alignSelf: "end" }}
            />
          </Tooltip>
        ) : (
          ""
        )}
      </div>

      <textarea
        className={styles["question-inp"]}
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        onKeyDown={handleKeyDown}
        style={{
          height: inputHeight,
          minHeight: "25px",
          overflowY: edit ? "scroll" : "hidden",
        }}
        onFocus={handleFocus}
        // onBlur={handleBlur}
      />
      <Tooltip content='Send'>
        <img
          src={sendIcon}
          className={styles["send-icon"]}
          onClick={handleFormSubmit}
        />
      </Tooltip>
    </div>
  );
};

export default QuestionInput;

import { useQuery } from "react-query";
import axiosInstance from "./axiosInstance";

const removeEmptyFields = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) =>
        value != undefined &&
        value != null &&
        !(Array.isArray(value) && value.length === 0) &&
        value !== ""
    )
  );
};

const filterAndEncodeBody = (body) => {
  const filteredBody = {
    ...removeEmptyFields(body),
  };

  if (filteredBody?.filterColumns) {
    filteredBody.filterColumns = filteredBody.filterColumns.map((item) => {
      if (item.columnValue.includes("@")) {
        return { ...item, columnValue: encodeURIComponent(item.columnValue) };
      }
      return item;
    });
  }

  return filteredBody;
};

export const usePostTableData = (data, endpoint, queryKey, enabled = true) => {
  return useQuery({
    queryKey: [queryKey, data],
    queryFn: async () => {
      const filteredBody = filterAndEncodeBody(data);
      return axiosInstance.post(endpoint, filteredBody);
    },
    enabled: enabled, 
  });}
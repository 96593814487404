import React, { useState } from "react";
import styles from "../../../Atoms/Modal/Modal.module.css";
import Modal from "../../../Atoms/Modal/Modal";
import TextInput from "../../../Atoms/Inputs/TextInput";
import Buttons from "../../../Atoms/Buttons/Buttons";
import folder from "../../../../assets/Imagess/folderICon.svg";
import hash from "../../../../assets/Imagess/hashicon.svg";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import {
  useAddValuesForType,
  useEditValuesForTypes,
} from "../../../../Hooks/Settings";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "../../Loader/Loader";
import LoaderButton from "../../Loader/LoaderButton";
import { toast } from "react-toastify";

const SettingsModal = ({
  handleOnClose,
  title,
  label1,
  mode,
  type,
  refetchTypes,
  selectedValueText,
  refetchParentOptions,
  isRelated,
  selectedItem,
  setSelectedItem,
}) => {
  const [text, setText] = useState("");
  const { mutate: addValuesForTypeMutation, isLoading: isAddValues } =
    useAddValuesForType();
  const { mutate: editValueForType, isLoading: isEditting } =
    useEditValuesForTypes();
  const initialValues = {
    name: selectedItem?.text || "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
  });

  const handleSubmit = () => {
    addValuesForTypeMutation(
      {
        typeID: type,
        text: text,
        value: text,
        parent: selectedValueText || "",
      },
      {
        onSuccess: () => {
          handleOnClose();
          refetchTypes();
          if (selectedValueText) {
            refetchParentOptions();
          }
          setSelectedItem({});
          toast.success(`${text} is successfully added`);
        },
        onError: (error) => {
          toast.error(error.response?.data?.message);
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        {
          mode == "add"
            ? handleSubmit(values)
            : editValueForType(
                {
                  typeID: selectedItem?.ddid,
                  text: values.name,
                },
                {
                  onSuccess: () => {
                    handleOnClose();
                    refetchTypes();
                    if (selectedValueText) {
                      refetchParentOptions();
                    }
                    setSelectedItem({});
                    toast.success(`${values.name} is successfully updated`);
                  },
                }
              );
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              onClose={handleOnClose}
              title={mode == "add" ? "Add" + " " + title : "Edit" + " " + title}
              width='40%'
            >
              <ModalChildrenContainer>
                {/* <TextInput label={label1} disabled={true} icon={hash} /> */}
                <TextInput
                  label='Name'
                  icon={folder}
                  name='name'
                  placeholder='Enter Name'
                  value={values.name}
                  required={true}
                  // text={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    setText(value);
                  }}
                  errors={errors.name}
                  touched={touched.name}
                />

                {isAddValues || isEditting ? (
                  <LoaderButton />
                ) : (
                  <Buttons
                    text={mode == "add" ? "Save" : "update"}
                    handleOnClick={handleSubmit}
                  />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SettingsModal;

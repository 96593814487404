import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const handleExportPDF = (ref, name) => {
  html2canvas(ref.current).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("landscape");
    pdf.addImage(imgData, "PNG", 10, 10);
    pdf.save(`${name}.pdf`);
  });
};

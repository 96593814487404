import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../Constants";

const fetchGraphData = async (endpoint, data) => {
  const url = `${API_URL}/api/Dashboard/${endpoint}/${data.applicationStart}/${data.startDate}/${data.endDate}`;

  if (
    data.applicationStart !== null &&
    data.applicationStart !== undefined &&
    data.startDate &&
    data.endDate
  ) {
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};

const useFetchGraphData = (endpoint, data) => {
  return useQuery({
    queryKey: [endpoint, data],
    queryFn: () => fetchGraphData(endpoint, data),
    enabled: true,
  });
};

export const useFetchAgeGraphData = (data) => useFetchGraphData("GetAge", data);

export const useFetchGenderGraphData = (data) =>
  useFetchGraphData("GetGender", data);

export const useFetchHeaderNumbers = (data) =>
  useFetchGraphData("GetHeaderNumbers", data);

export const useFetchHearAboutUs = (data) =>
  useFetchGraphData("GetHearAboutUs", data);

export const useFetchMostFieldOfInterest = (data) =>
  useFetchGraphData("GetMostFieldOfInterest", data);

export const useFetchNationalities = (data) =>
  useFetchGraphData("GetNationalities", data);

export const useFetchLevelOfEducation = (data) =>
  useFetchGraphData("GetLevelOfEducation", data);

export const useFetchApplicationCategories = (data) =>
  useFetchGraphData("GetApplicationCategories", data);

//field of interest
const fetchData = async (endpoint, data) => {
  const url = `${API_URL}/api/Dashboard/${endpoint}/${data.applicationStart}/${data.startDate}/${data.endDate}`;
  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const useFetchData = (endpoint, data) => {
  return useQuery({
    queryKey: [endpoint, data],
    queryFn: () => fetchData(endpoint, data),
    enabled: true,
  });
};

export const useFetchFieldOfInterestByType = (data) =>
  useFetchData("GetFieldOfInterestByType", data);

/////

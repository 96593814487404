import React, { useContext } from "react";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { useFetchLevelOfEducation } from "../../../../../Hooks/Dashboard";
import LevelOfEducation from "./LevelOfEducation";

const LevelOfEducationComponent = () => {
  const { date, applicationStart } = useContext(ApplicationContext);

  const { data, isLoading } = useFetchLevelOfEducation({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  return (
    <LevelOfEducation levelOfEducationData={data?.data} isLoading={isLoading} />
  );
};

export default LevelOfEducationComponent;

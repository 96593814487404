import React, { useRef, useState } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import styles from "./Dashboard.module.css";
import SingleTabGroup from "../../Moecules/SingleApplicant/SingleTabGroup";
import ApplicantDemographics from "../../Moecules/Dashboard/ApplicantDemographics/ApplicantDemographics";
import ApplicationAnalysis from "../../Moecules/Dashboard/ApplicationAnalysis/ApplicationAnalysis";
import CampaignReflection from "../../Moecules/Dashboard/CampaignReflection/CampaignReflection";
import GeneralStatistics from "../../Moecules/Dashboard/GeneralStatistics/GeneralStatistics";
import DashboardOptions from "../../Moecules/Dashboard/OptionsContainer/DashboardOptions";
import ExportPrintComponent from "../../Moecules/Settings/SettingsMolecules/ExportPrintComponent";
import { ApplicationProvider } from "../../../Context/DashbaordContext";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";

const DashboardPage = () => {
  const [selected, setSelected] = useState(0);
  const printRef = useRef();
  const tabContainer = [
    {
      value: 0,
      text: "Applicant Demographics",
      component: <ApplicantDemographics />,
    },
    {
      value: 1,
      text: "Application Analysis",
      component: <ApplicationAnalysis />,
    },
    {
      value: 2,
      text: "Campaign Reflection",
      component: <CampaignReflection />,
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <ApplicationProvider>
      <WrappingContainer>
        <PrintComponent ref={printRef}>
          <div className={styles["welcome-div"]}>
            <h2>Welcome To AUD CRM!</h2>
            <DashboardOptions handlePrint={handlePrint} />
          </div>

          <GeneralStatistics />
          <div className={styles["tab_more_cont"]}>
            <SingleTabGroup
              tabContainer={tabContainer}
              selected={selected}
              setSelected={setSelected}
              noPadding
            />
            {/* <ExportPrintComponent /> */}
          </div>

          {tabContainer.find((tab) => tab.value === selected)?.component}
        </PrintComponent>
      </WrappingContainer>
    </ApplicationProvider>
  );
};

export default DashboardPage;

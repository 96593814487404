import React, { useEffect, useState } from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import TextInput from "../../../Atoms/Inputs/TextInput";
import RolesDropDown from "../../DopDownRoles/RolesDropDown";
import rolesIcon from "../../../../assets/Imagess/roleiconname.svg";
import Buttons from "../../../Atoms/Buttons/Buttons";
import { useAddRoles, useFetchRoleById } from "../../../../Hooks/Roles";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import LoaderButton from "../../Loader/LoaderButton";
import Loader from "../../Loader/Loader";
import RolesDropdownWithChildPages from "../../DopDownRoles/RolesDropdownWithChildPages";

const AddRolesModal = ({
  handleOnClose,
  title,
  refetch,
  mode,
  selected,
  roleDetails,
  setRoleDetails,
}) => {
  const { mutate: addRole, isLoading: isAddingRole } = useAddRoles();
  const {
    data: roleById,
    refetch: refetchRoleById,
    isLoading,
  } = useFetchRoleById(selected.roleId);
  const initialValues = {
    roleName: selected?.roleName || "",
  };
  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("name is required"),
  });

  const handleAddRole = (values) => {
    if (roleDetails?.length === 0) {
      toast.error("Please Choose a Privilege before submitting");
      return;
    }

    const filteredRoleDetails = roleDetails.map((detail) => {
      const { pageId, pageActionId } = detail;
      if (pageActionId) {
        return { pageId, pageActionId };
      }
      return { pageId };
    });

    const valuesToAdd = {
      roleId: selected.roleId || "00000000-0000-0000-0000-000000000000",
      roleName: values.roleName,
      roleDetails: filteredRoleDetails,
    };

    addRole(valuesToAdd, {
      onSuccess: () => {
        toast.success(`${values.roleName} is added successfully`);
        handleOnClose();
        refetch();
        setRoleDetails([]);
      },
    });
  };

  useEffect(() => {
    if (roleById) {
      const newRoleDetails =
        roleById?.data &&
        roleById?.data[0]?.pages?.flatMap((item) => {
          if (item.isSelected && item.pageActions.length === 0) {
            return [{ pageId: item.pageId }];
          }

          if (item.isSelected && item.pageActions.length > 0) {
            return item.pageActions
              .filter((action) => action.isSelected)
              .map((action) => ({
                pageId: item.pageId,
                pageActionId: action.pageActionId,
              }));
          }

          return [];
        });
      setRoleDetails(newRoleDetails);
    } else {
      setRoleDetails([]);
    }
  }, [roleById, setRoleDetails]);

  useEffect(() => {
    refetchRoleById(selected.roleId);
  }, [selected]);

  if (isLoading) {
    return <Loader />;
  }


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleAddRole(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              onClose={handleOnClose}
              title={mode === "add" ? "Add " + title : "Edit " + title}
              width='50%'
            >
              <ModalChildrenContainer>
                <TextInput
                  icon={rolesIcon}
                  placeholder='Enter Role Name'
                  label='Role Name'
                  name='roleName'
                  value={values.roleName}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.roleName}
                  touched={touched.roleName}
                />
                {/* <RolesDropDown
                  roleDetails={roleDetails}
                  setRoleDetails={setRoleDetails}
                  isAddingRole={isAddingRole}
                /> */}
                <RolesDropdownWithChildPages
                  roleDetails={roleDetails}
                  setRoleDetails={setRoleDetails}
                  isAddingRole={isAddingRole}
                />
                {isAddingRole ? (
                  <LoaderButton />
                ) : (
                  <Buttons text='Save' handleOnClick={handleSubmit} />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddRolesModal;

import React, { useState } from "react";
import styles from "./AddExternalContact.module.css";
import TextInput from "../../../Atoms/Inputs/TextInput";
import PhoneNumber from "../../../Atoms/Inputs/PhoneNumber";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import nameIcon from "../../../../assets/Imagess/Contact/form/nameIcon.svg";
import mailIcon from "../../../../assets/Imagess/Contact/form/maillcon.svg";
import ebooks from "../../../../assets/Imagess/Contact/form/ebooks.svg";
import flagIcon from "../../../../assets/Imagess/Contact/form/flagIcon.svg";
import globelIcon from "../../../../assets/Imagess/Contact/form/globeIcon.svg";
import gradeIcon from "../../../../assets/Imagess/Contact/form/grade.svg";
import highschool from "../../../../assets/Imagess/Contact/form/highschool.svg";
import jobIcon from "../../../../assets/Imagess/Contact/form/jobIcon.svg";
import languageIcon from "../../../../assets/Imagess/Contact/form/languageIcon.svg";
import priorityIcon from "../../../../assets/Imagess/Contact/form/priorityIcon.svg";
import schoolIcon from "../../../../assets/Imagess/Contact/form/schoolIcon.svg";
import typeIcon from "../../../../assets/Imagess/Contact/form/typeIcon.svg";
import RadioButtonGroup from "../../../Atoms/Inputs/RadioButtons/RadioButtonGroup";
import { useFormikContext } from "formik";
import { useFetchDropDownTypes } from "../../../../Hooks/Settings";

const radioOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
const ExternalContactForm = () => {
  const [selectedOption, setSelectedOption] = useState("option1");
  const formik = useFormikContext();
  const { data: contactTypeData } = useFetchDropDownTypes({ typeId: 16 });
  const { data: contactPrioritydata } = useFetchDropDownTypes({ typeId: 17 });
  const { data: curriculumData } = useFetchDropDownTypes({ typeId: 18 });
  const { data: nationalityData } = useFetchDropDownTypes({ typeId: 4 });
  const { data: countryData } = useFetchDropDownTypes({ typeId: 9 });
  const { data: languagedData } = useFetchDropDownTypes({ typeId: 22 });
  const { data: gradesData } = useFetchDropDownTypes({ typeId: 23 });

  const handleRadioChange = (e) => {
    setSelectedOption("test", e.target.value);
  };

  return (
    <div className={styles["form_container"]}>
      <div className={styles["form_subcContainer"]}>
        <TextInput
          label='Name'
          icon={nameIcon}
          placeholder=' Enter Name'
          name='contactName'
          value={formik.values.contactName}
          // disabled={mode == "View" ? true : false}
          required={true}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.contactName}
          touched={formik.touched.contactName}
        />
        <PhoneNumber
          label='Mobile'
          placeholder='Enter Number'
          required
          name='mobile'
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          value={formik.values.mobile}
          errors={formik.errors.mobile}
          touched={formik.touched.mobile}
        />
        <TextInput
          label='Email'
          icon={mailIcon}
          placeholder='Enter Email'
          name='email'
          value={formik.values.email}
          // disabled={mode == "View" ? true : false}
          required={true}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.email}
          touched={formik.touched.email}
        />
        <CustomizedSelect
          icon={typeIcon}
          label='Contact Type'
          name='contactTypeId'
          value={formik.values.contactTypeId}
          placeholder='Select Type'
          options={contactTypeData?.data?.map(({ ddid, text }) => ({
            value: ddid,
            text: text,
          }))}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          required
          errors={formik.errors.contactTypeId}
          touched={formik.touched.contactTypeId}
        />
        <TextInput
          label='School Name'
          icon={schoolIcon}
          placeholder='Enter School Name'
          name='schoolName'
          value={formik.values.schoolName}
          // disabled={mode == "View" ? true : false}
          required={true}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.schoolName}
          touched={formik.touched.schoolName}
        />{" "}
        <CustomizedSelect
          icon={priorityIcon}
          label='Contact Priority'
          name='priorityId'
          placeholder='Select Priority'
          value={formik.values.priorityId}
          // disabled={mode == "View" ? true : false}
          required={true}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.priorityId}
          touched={formik.touched.priorityId}
          options={contactPrioritydata?.data?.map(({ ddid, text }) => ({
            value: ddid,
            text: text,
          }))}
        />{" "}
        <RadioButtonGroup
          options={radioOptions}
          selectedValue={formik.values.sendMailer}
          onRadioChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          label='Send Mailer'
          name='sendMailer'
          errors={formik.errors.sendMailer}
          touched={formik.touched.sendMailer}
        />
      </div>
      <div className={styles["form_subcContainer"]}>
        <CustomizedSelect
          icon={globelIcon}
          label='Country'
          placeholder='Select Country'
          required
          value={formik.values.countryId}
          name='countryId'
          options={countryData?.data?.map(({ ddid, text }) => ({
            value: ddid,
            text: text,
          }))}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.countryId}
          touched={formik.touched.countryId}
        />
        <CustomizedSelect
          icon={flagIcon}
          label='Nationality'
          placeholder='Select Nationality'
          required
          name='nationalityId'
          options={nationalityData?.data?.map(({ ddid, text }) => ({
            value: ddid,
            text: text,
          }))}
          value={formik.values.nationalityId}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.nationalityId}
          touched={formik.touched.nationalityId}
        />
        <TextInput
          label='Job title'
          icon={jobIcon}
          placeholder='Enter Job title'
          name='jobTitle'
          value={formik.values.jobTitle}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.jobTitle}
          touched={formik.touched.jobTitle}
        />{" "}
        <CustomizedSelect
          icon={languageIcon}
          label='Language'
          placeholder='Select Language'
          required
          name='languageId'
          options={languagedData?.data?.map(({ ddid, text }) => ({
            value: ddid,
            text: text,
          }))}
          value={formik.values.languageId}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.languageId}
          touched={formik.touched.languageId}
        />
        {formik.values.contactTypeId ==
          "e1cffe75-79d4-4517-ada5-a75fc192a510" && (
          <>
            <CustomizedSelect
              icon={ebooks}
              label='School Curriculum'
              name='curriculumId'
              placeholder='Select School Curriculum'
              required
              options={curriculumData?.data?.map(({ ddid, text }) => ({
                value: ddid,
                text: text,
              }))}
              value={formik.values.curriculumId}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.curriculumId}
              touched={formik.touched.curriculumId}
            />
            <CustomizedSelect
              icon={gradeIcon}
              label='Grade'
              placeholder='Select Grade'
              name='gradeId'
              required
              options={gradesData?.data?.map(({ ddid, text }) => ({
                value: ddid,
                text: text,
              }))}
              value={formik.values.gradeId}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.gradeId}
              touched={formik.touched.gradeId}
            />
            <TextInput
              label='High School Academic GPA'
              icon={highschool}
              placeholder='Enter Grade'
              name='highSchoolGPA'
              value={formik.values.highSchoolGPA}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.highSchoolGPA}
              touched={formik.touched.highSchoolGPA}
            />{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default ExternalContactForm;

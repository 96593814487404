import React from "react";
import Modal from "../../../Atoms/Modal/Modal";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../../Atoms/Inputs/TextInput";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import Buttons from "../../../Atoms/Buttons/Buttons";
import PhoneNumber from "../../../Atoms/Inputs/PhoneNumber";
import { useVerifyApplicant } from "../../../../Hooks/Applicant";
import emailicon from "../../../../assets/Imagess/emailloginicon.svg";
import { useVerifyShowingInterest } from "../../../../Hooks/Interested";
import { toast } from "react-toastify";
const VerificationModal = ({
  handleOnClose,
  setSelectedItem,
  selectedItem,
  isApplicant,
  refetch,
}) => {
  const { mutate: verifyApplicant, isLoading: isVerifyingApplicant } =
    useVerifyApplicant();
  const { mutate: verifyInterest, isLoading: isVerifyingInterest } =
    useVerifyShowingInterest();

  const initialValues = {
    email: selectedItem?.email,
    mobile: selectedItem?.mobile,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("required"),
    mobile: Yup.string().required("required"),
  });

  const handleVerify = (values) => {
    isApplicant
      ? verifyApplicant(
          {
            id: selectedItem?.id,
            email: values.email,
            mobile: values.mobile,
          },
          {
            onSuccess: () => {
              refetch();
              handleOnClose();
              toast.success("Verified Successfully");
            },
          }
        )
      : verifyInterest(
          {
            id: selectedItem?.id,
            email: values.email,
            mobile: values.mobile,
          },
          {
            onSuccess: () => {
              refetch();
              handleOnClose();
              toast.success("Verified Successfully");
            },
          }
        );
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleVerify(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal width='40%' title='Verification' onClose={handleOnClose}>
              <ModalChildrenContainer>
                <TextInput
                  label='Email Address'
                  placeholder='Email'
                  name='email'
                  icon={emailicon}
                  value={values.email}
                  disabled
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.email}
                  touched={touched.email}
                />
                <PhoneNumber
                  label='Mobile Number'
                  placeholder='Mobile Number'
                  name='mobile'
                  value={values.mobile}
                  disabled
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.mobile}
                  touched={touched.mobile}
                />
                <Buttons
                  text='Verify'
                  type='Submit'
                  handleOnClick={handleSubmit}
                  isLoading={isVerifyingApplicant || isVerifyingInterest}
                />
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VerificationModal;

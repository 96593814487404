import * as Yup from "yup";

const externalContactValidationSchema = Yup.object().shape({
  contactName: Yup.string().required("contact name is required"),
  mobile: Yup.number().required("mobile is required"),
  email: Yup.string().required("email is required"),
  contactTypeId: Yup.string().required("type is required"),
  schoolName: Yup.string().required("school name is required"),
  priorityId: Yup.string().required("contact priority is required"),
  sendMailer: Yup.boolean(),
  countryId: Yup.string().required("country is required"),
  nationalityId: Yup.string().required("nationality is required"),
  jobTitleId: Yup.string(),
  languageId: Yup.string().required("language is required"),
  curriculumId: Yup.string().when("contactTypeId", {
    is: (value) => value === "e1cffe75-79d4-4517-ada5-a75fc192a510",
    then: (schema) => schema.required("curriculumId is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  gradeId: Yup.string().when("contactTypeId", {
    is: (value) => value === "e1cffe75-79d4-4517-ada5-a75fc192a510",
    then: (schema) => schema.required("gradeId is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  highSchoolGPA: Yup.string().when("contactTypeId", {
    is: (value) => value === "e1cffe75-79d4-4517-ada5-a75fc192a510",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export default externalContactValidationSchema;

import React, { useContext, useRef } from "react";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
import { useFetchGenderGraphData } from "../../../../../Hooks/Dashboard";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const GenderGraph = ({ genderData, isLoading }) => {
  const chartRef = useRef(null);

  const series = genderData?.map((item) => item.value) || [];
  const labels = genderData?.map((item) => item.label) || [];

  const options = {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: "gender-data",
            columnDelimiter: ",",
            headerCategory: "Category",
            headerValue: "Value",
          },
        },
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          size: "50%",
        },
      },
    },
    colors: ["#007FD9", "#F3223C"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: "top",
    },
    labels: labels,
  };

  const handleDownload = () => {
    const categories = labels;
    const title = "Gender_Graph";
    downloadCSV(series, categories, title);
  };

  return (
    <DashboardContainer
      title='Gender'
      noData={genderData?.length === 0}
      handleExport={handleDownload}
      chartRef={chartRef}
      isLoading={isLoading}
    >
      <div ref={chartRef}>
        <Chart options={options} series={series} type='donut' />
      </div>
    </DashboardContainer>
  );
};

export default GenderGraph;

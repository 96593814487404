import React, { useCallback, useEffect, useMemo } from "react";
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import "reactflow/dist/style.css";
import StartNode from "./StartNode";
import DiamondBox from "./DiamondBox";
import RectangularNode from "./RectangularNode";
import CustomEdge from "./CustomEdge";
import WhiteContainer from "../../../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./Chart.module.css";
import { toast } from "react-toastify";
const Chart = ({
  nodes,
  setNodes,
  edges,
  setEdges,
  onNodeSelect,
  readOnly,
  height,
  isSelectedNodeEmpty,
}) => {
  // const onNodesChange = useCallback(
  //   (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
  //   []
  // );

  // const onEdgesChange = useCallback(
  //   (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
  //   []
  // );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) =>
        applyNodeChanges(
          changes.filter((change) => {
            if (change.type === "remove") {
              // Prevent deletion of the first node
              const isFirstNode = change.id === "1";
              if (isFirstNode) {
                toast.error("Can't delete the start node.");
                return false;
              }

              // Prevent deletion of middle nodes
              const isMiddleNode =
                edges.some((edge) => edge.target === change.id) &&
                edges.some((edge) => edge.source === change.id);
              if (isMiddleNode) {
                toast.error("Can't delete a middle node");
                return false;
              }
            }
            return true;
          }),
          nds
        )
      );
    },
    [edges] // Use only edges here to avoid unnecessary re-renders
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) =>
        applyEdgeChanges(
          changes.filter((change) => {
            if (change.type === "remove") {
              const isEdgeConnectedToMiddleNode = edges.some((edge) => {
                const targetNode = nodes.find(
                  (node) => node.id === edge.target
                );
                const sourceNode = nodes.find(
                  (node) => node.id === edge.source
                );
                return (
                  edge.id === change.id &&
                  targetNode &&
                  sourceNode &&
                  edges.some((e) => e.target === targetNode.id) &&
                  edges.some((e) => e.source === sourceNode.id)
                );
              });
              return !isEdgeConnectedToMiddleNode;
            }
            return true;
          }),
          eds
        )
      );
    },
    [nodes, edges]
  );
  useEffect(() => {
    const nodeIds = nodes.map((node) => node.id);
    const updatedEdges = edges.filter((edge) => nodeIds.includes(edge.target));
    if (updatedEdges.length !== edges.length) {
      setEdges(updatedEdges);
    }
  }, [nodes, edges, setEdges]);

  const handleAddNode = (type, action, sourceHandle, id, xPos, yPos) => {
    const newNodeId = (nodes.length + 1)?.toString();
    const newNode = {
      id: newNodeId,
      type,
      position: {
        x:
          sourceHandle === "left"
            ? xPos - 200
            : sourceHandle == "right"
            ? xPos + 200
            : action === "email"
            ? xPos - 50
            : xPos,
        y: sourceHandle ? yPos + 120 : yPos + 100,
      },
      data: {
        label: type.charAt(0).toUpperCase() + type.slice(1),
        action,
      },
    };

    setNodes((nds) => nds.concat(newNode));
    setEdges((eds) =>
      eds.concat({
        id: `e${id}-${newNodeId}`,
        source: id,
        target: newNodeId,
        sourceHandle,
      })
    );
  };

  const isLastSourceNode = (id, sourceHandle) => {
    return !edges?.some(
      (edge) => edge.source === id && edge.sourceHandle === sourceHandle
    );
  };

  const handleNodeClick = (e, node) => {
    onNodeSelect(node);
  };
  const nodeTypes = useMemo(
    () => ({
      startNode: (props) => (
        <StartNode
          {...props}
          isLastSourceNode={isLastSourceNode}
          onAddNode={handleAddNode}
          readOnly={readOnly}
        />
      ),
      rectangularNode: (props) => (
        <RectangularNode
          {...props}
          isLastSourceNode={isLastSourceNode}
          onAddNode={handleAddNode}
          readOnly={readOnly}
        />
      ),
      diamondNode: (props) => (
        <DiamondBox
          {...props}
          isLastSourceNode={isLastSourceNode}
          onAddNode={handleAddNode}
          readOnly={readOnly}
        />
      ),
    }),
    [edges]
  );

  const edgeTypes = useMemo(
    () => ({
      "custom-edge": CustomEdge,
    }),
    []
  );

  return (
    <div
      className={styles["chart_container"]}
      style={{ height: height ? height : "" }}
    >
      <ReactFlow
        nodes={nodes}
        onNodesChange={!readOnly ? onNodesChange : null}
        edges={edges}
        onEdgesChange={!readOnly ? onEdgesChange : null}
        fitView={readOnly}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodeClick={handleNodeClick}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default Chart;

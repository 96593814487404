import React from "react";
import styles from "./NoDataComponent.module.css";
import nodataImage from "../../../../assets/Imagess/nodataImage.svg";
const NoDataComponent = ({ text, table }) => {
  return (
    <div className={`${styles["cont"]}  ${table ? styles["table"] : ""} `}>
      <img src={nodataImage} alt='no data' />
      <h3>No {text ? text : "Data"} Available</h3>
    </div>
  );
};

export default NoDataComponent;

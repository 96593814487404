import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "../Constants";

const PrivateRoutes = () => {
  const location = useLocation();
  const localStorageToken = localStorage.getItem("token");
  const [token, setToken] = useState(localStorageToken);
  const [isValid, setIsValid] = useState(true);

  const fetchValidation = async () => {
    try {
      await axios.get(API_URL + "/api/ApplicantView/Validate", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setIsValid(true);
    } catch (error) {
      console.error("Error during validation:", error);
      localStorage.removeItem("token");
      setIsValid(false);
      toast.error("Invalid session. Please log in again.");
    }
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          toast.error("Session expired. Please log in again.");
          localStorage.removeItem("token");
          setIsValid(false);
        } else {
          fetchValidation();
        }
      } catch (error) {
        console.error("Invalid token:", error);
        toast.error("Invalid token format.");
        localStorage.removeItem("token");
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }, [token, location]);

  useEffect(() => {
    setToken(localStorageToken);
  }, [localStorageToken]);

  useEffect(() => {
    const handleFocus = () => fetchValidation();

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  if (!isValid) {
    return <Navigate to='/' />;
  }

  return <Outlet />;
};

export default PrivateRoutes;

import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./Tooltip.css";

const Tooltip = ({ content, children, red, placement, padding }) => {
  const tooltipClass = red ? "red-tooltip" : padding ? "padding-tooltip":"";

  return (
    <Tippy
      content={content}
      className={tooltipClass}
      arrow={false}
      placement={placement}
    >
      <span >{children}</span>
    </Tippy>
  );
};

export default Tooltip;

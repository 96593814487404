import React from "react";
import styles from "./Rate.module.css";
import DashboardContainer from "../../DashboardContainer";
import RateChart from "./RateChart/RateChart";
import RateContainer from "./RateChart/RateContainer/RateContainer";
const Rate = ({data}) => {

  return (
    <DashboardContainer >
      <div className={styles["cont"]}>
        {data?.map((item, index) => {
          return (
            <div
              className={styles["rateCont"]}
              key={index}
              style={{ borderBottom: index == data?.length - 1 ? "none" : "" }}
            >
              <RateContainer data={item} />
            </div>
          );
        })}
      </div>
    </DashboardContainer>
  );
};

export default Rate;

export const handleExportApexGraphs = (format) => {
  const exportButton = document.querySelector(
    `.apexcharts-menu-item.export${format.toUpperCase()}`
  );

  if (exportButton) {
    exportButton.click();
  }
};

export const downloadCSV = (series, categories, title) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += "Category,Value\n";
  categories.forEach((category, index) => {
    csvContent += `${category},${series[index]}\n`;
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${title}.csv`);
  document.body.appendChild(link);

  link.click();
};

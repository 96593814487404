import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";
import { usePostTableData } from "./services/postCall";


/*****************fetch all users */
export const useFetchUsers = (data) => {
  const shouldFetchData = !!data?.selected;
  let url = `/api/User/GetUsers`;

  return usePostTableData(data, url, "nousersifications", shouldFetchData);
};

/*****************add user */
const addUser = (values) => {
  return axios.post(API_URL + `/api/User/CreateUser`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddUser = () => {
  return useMutation(addUser);
};

/***********Export table users */
const exportTableUsers = async () => {
  return await axios.get(API_URL + "/api/Export/ExportUsers", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportTableUsers = () => {
  return useMutation(() => exportTableUsers());
};

/******************Edit users */
const editUser = (values) => {
  return axios.post(API_URL + `/api/User/EditUser`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useEditUser = () => {
  return useMutation(editUser);
};

//delete user
const deleteUser = (userId) => {
  return axios.delete(API_URL + `/api/User/DeleteUser/${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteUser = () => {
  return useMutation(deleteUser);
};

//change password
const changePassword = (values) => {
  return axios.post(API_URL + `/api/User/ResetPassword`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useChangePassword = () => {
  return useMutation(changePassword);
};

//change password
const uploadProfileImage = (values) => {
  return axios.post(API_URL + `/api/User/UploadProfileImage`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useUploadProfileImage = () => {
  return useMutation(uploadProfileImage);
};

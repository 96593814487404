import React, { useState } from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import userIcon from "../../../../assets/Imagess/userinputicon.svg";
import Buttons from "../../../Atoms/Buttons/Buttons";
import { useFetchUsers } from "../../../../Hooks/Users";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoaderButton from "../../Loader/LoaderButton";
import { useAssignUsersToRolels } from "../../../../Hooks/Roles";
import CustomizedSelectwithCheckbox from "../../../Atoms/Inputs/CustomizedSelectwithCheckbox";
import { useAutomaticAssign } from "../../../../Hooks/UserAssignment";
const AssignUserModal = ({
  handleOnClose,
  selected,
  setSelected,
  refetch,
  title,
}) => {
  const [search, setSearch] = useState("");
  const {
    data: users,
    refetch: refetchUsers,
    isLoading: isUsersLoading,
  } = useFetchUsers({
    pageSize: 2000,
    pageNumber: 1,
    selected: true,
  });
  const { mutate: assignUsers, isLoading } = useAssignUsersToRolels();
  const { mutate: automaticAssign, isLoading: isLaodingAutomaticAssign } =
    useAutomaticAssign();

  const initialValues = {
    users: [],
  };

  const validationSchema = Yup.object().shape({
    users: Yup.array().required("user is required"),
  });

 
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const valuesToAdd = {
          roleId: selected?.roleId,
          userId: values.users.map((user) => user.value),
        };

        if (title === "Automatic Assign") {
          automaticAssign(
            {
              userId: values.users.map((user) => user.value),
              assignees: selected?.map((item) => item.id),
            },
            {
              onSuccess: () => {
                toast.success("Assigned successfully");
                handleOnClose();
                setSelected([]);
                if (refetch) {
                  refetch();
                }
              },
            }
          );
        } else {
          assignUsers(valuesToAdd, {
            onSuccess: () => {
              toast.success("Assigned successfully");
              handleOnClose();
              setSelected([]);
              refetch();
            },
          });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              onClose={handleOnClose}
              title={title || "Assign Users"}
              width='50%'
            >
              <ModalChildrenContainer>
                <CustomizedSelectwithCheckbox
                  icon={userIcon}
                  label='Assign to'
                  placeholder='Select User'
                  options={users?.data?.items?.map(({ userId, userName }) => ({
                    value: userId,
                    text: userName,
                  }))}
                  name='users'
                  value={values.users}
                  required={true}
                  // text={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.users}
                  touched={touched.users}
                  notAbsolute
                />
                {isLoading ? (
                  <LoaderButton />
                ) : (
                  <Buttons text='Save' handleOnClick={handleSubmit} />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignUserModal;

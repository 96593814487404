import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./Input.module.css";

const TextInput = ({
  label,
  required,
  width,
  value, // Controlled by props
  onChange,
  name,
  errors,
  touched,
  type,
  disabled,
  icon,
  placeholder,
  text,
  grey,
  height,
}) => {
  const [initialValue, setInitialValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInitialValue(newValue);

    if (name) {
      onChange(name, newValue);
    } else {
      onChange(newValue);
    }
  };

  const inputStyle = {
    width: width,
    borderColor:
      errors && touched
        ? "#F3223C"
        : grey || disabled
        ? "#1b224c3b"
        : "#1b224c",
  };
  useEffect(() => {
    {
      type!="password" && setInitialValue(value);
    }
  }, [value]);

  return (
    <div
      className={styles["textBox-container"]}
      style={{ height: height ? height : "" }}
    >
      <label
        htmlFor={label}
        style={{ color: "#1B224C", opacity: disabled ? "0.5" : "1" }}
      >
        {label && label}
        {required && <span className={styles["required"]}>*</span>}
      </label>

      <div className={styles["icon-input-container"]} style={inputStyle}>
        {icon && <img src={icon} alt='icon' className={styles["icon"]} />}
        <input
          type={type ? type : "text"}
          id={label}
          required={required}
          placeholder={placeholder}
          className={styles["text-input"]}
          onChange={text ? onChange : handleChange}
          value={text ? value : initialValue} // Controlled value from props
          disabled={disabled ? true : false}
        />
      </div>
    </div>
  );
};

export default TextInput;

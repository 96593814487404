import React, { useRef, useState } from "react";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import SettingsTopHeader from "../../../Moecules/Settings/SettingsMolecules/SettingsTopHeader";
import SingleTabGroup from "../../../Moecules/SingleApplicant/SingleTabGroup";
import CampaignDetailedBox from "../../../Moecules/Campaign/CampaignDetails/CampaignDetailedBox";
import { useNavigate } from "react-router-dom";
import {
  useExportCampaign,
  useFetchCampaign,
} from "../../../../Hooks/Campaign";
import Loader from "../../../Moecules/Loader/Loader";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../../Moecules/Custom/ExportComponent/PrintComponent";
import NoDataComponent from "../../../Moecules/Custom/NoData/NoDataComponent";
import styles from "./NewCampaign.module.css";
import { useSearchFilterContext } from "../../../../Context/SearchFilterContext";
const tabContainer = [
  {
    text: "Active",
    value: 0,
  },
  {
    text: "Inactive",
    value: 1,
  },
];

const CampaignPage = () => {
  const navigate = useNavigate();
  const printRef = useRef();
  const { searchValue } = useSearchFilterContext();

  const [selectedTab, setSelectedTab] = useState(0);
  const [search, setSearch] = useState(searchValue || "");

  const {
    data: campaignData,
    isLoading,
    refetch: refetchCampaigns,
  } = useFetchCampaign({
    search: search,
  });
  const filteredData = campaignData?.data?.filter(
    (item) =>
      ((item.statusId == 0 || item.statusId == 1) &&
        item.isDeleted == false) ===
      (selectedTab === 0)
  );
  const exportCampaign = useExportCampaign();

  const handleExport = async () => {
    try {
      const data = await exportCampaign.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Campaigns";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // const rightButtons = <Filter />;

  return (
    <>
      <WrappingContainer>
        <WhiteContainer white>
          <SettingsTopHeader
            title='New Campaign'
            // rightButton={rightButtons}
            handleAddClick={() => navigate(`/home/new-campaign`)}
            isSearch
            search={search}
            setSearch={setSearch}
            handleExport={handleExport}
            handlePrint={handlePrint}
            canAdd
          />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <SingleTabGroup
                tabContainer={tabContainer}
                selected={selectedTab}
                setSelected={setSelectedTab}
              />

              <PrintComponent ref={printRef} title='Campaigns'>
                <div className={styles["cont"]}>
                  {filteredData?.length > 0 ? (
                    filteredData?.map((item) => (
                      <div key={item.campaignId}>
                        <CampaignDetailedBox
                          data={item}
                          settings
                          refetch={refetchCampaigns}
                        />
                      </div>
                    ))
                  ) : (
                    <NoDataComponent text='Campaign' />
                  )}
                </div>
              </PrintComponent>
            </>
          )}
        </WhiteContainer>
      </WrappingContainer>
    </>
  );
};

export default CampaignPage;

import React, { useContext, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
import SingleTabGroup from "../../../SingleApplicant/SingleTabGroup";
import styles from "./FieldOfInterest.module.css";
import { useFetchFieldOfInterestByType } from "../../../../../Hooks/Dashboard";
import Loader from "../../../Loader/Loader";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import NoDataDashboard from "../../NoData/NoDataDashboard";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const FieldOfInterest = () => {
  const chartRef = useRef(null);

  const { applicationStart, date } = useContext(ApplicationContext);

  const [selected, setSelected] = useState(0);
  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);

  const { data, refetch, isLoading } = useFetchFieldOfInterestByType({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: selected,
  });

  const tabContainer = [
    {
      text: "Undergraduate",
      value: 0,
    },
    {
      text: "Graduate",
      value: 1,
    },
    {
      text: "Visiting",
      value: 2,
    },
  ];
  useEffect(() => {
    if (applicationStart != -1) {
      setSelected(applicationStart);
    } else {
      setSelected(0);
    }
  }, [applicationStart]);

  useEffect(() => {
    refetch({
      startDate: date.startDate,
      endDate: date.endDate,
      applicationStart: selected,
    });
  }, [selected]);

  useEffect(() => {
    if (data?.data) {
      const extractedCategories = data.data.map((item) => item.label);
      const extractedSeries = data.data.map((item) => item.value);

      setCategories(extractedCategories);
      setSeriesData([{ data: extractedSeries }]);
    }
  }, [data]);

  const options = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        barHeight: "90%",
        columnWidth: data?.data?.length > 9 ? "50%" : "15%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#6FB8F9"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val) {
        return val ? val.toString() : "";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1b224c"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        style: {
          fontWeight: "600",
          colors: ["#1b224c"],
          fontSize: "12px",
        },
        formatter: function (val) {
          if (!val) return "";
          const values = val.split(" ");
          if (values.length > 1) {
            const array = values
              .map((name) => name.charAt(0).toUpperCase())
              .join(".");
            return array;
          } else {
            return values[0];
          }
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const categoryName = w?.globals?.labels[dataPointIndex];
        return `
        <div style="padding: 10px; font-size: 12px;">
          <strong>${categoryName}</strong>
          
        </div>
      `;
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
          dataLabels: {
            style: {
              fontSize: "10px",
            },
            offsetY: -15,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
          },
          dataLabels: {
            style: {
              fontSize: "9px",
            },
            offsetY: -10,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "9px",
              },
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
            },
          },
          dataLabels: {
            style: {
              fontSize: "8px",
            },
            offsetY: -8,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
        },
      },
    ],
  };
  const isMobile = () => window.innerWidth <= 800;

  const handleDownload = () => {
    const title = "FieldOfInterest_Graph";
    downloadCSV(seriesData[0]?.data, categories, title);
  };

  return (
    <DashboardContainer
      handleExport={handleDownload}
      chartRef={chartRef}
      title='Field of Interest'
      hidden={categories?.length > 9 || (categories?.length > 3 && isMobile)}
    >
      <SingleTabGroup
        tabContainer={tabContainer}
        selected={selected}
        setSelected={setSelected}
      />
      {isLoading ? (
        <Loader />
      ) : categories?.length === 0 ? (
        <NoDataDashboard />
      ) : (
        <div
          className={`${styles["stacked_graph"]} ${
            categories?.length > 9
              ? styles["stacked_graph_scrollable"]
              : categories?.length > 3 && isMobile
              ? styles["stacked_graphMobile_scrollable"]
              : ""
          }`}
          ref={chartRef}
        >
          <Chart
            options={options}
            series={seriesData}
            type='bar'
            height={380}
          />
        </div>
      )}
    </DashboardContainer>
  );
};

export default FieldOfInterest;

import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
import moment from "moment";
const PersonalInformation = ({ applicant, isApplicant }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3> Personal Information </h3>
      <div
        className={` ${
          isApplicant ? styles["grid-container"] : styles["grid"]
        } `}
      >
        <div className={styles["personal-container2"]}>
          <TextContainer
            title='Title'
            data={applicant?.intersted?.title_Display}
          />
          <TextContainer
            title='First Name'
            data={applicant?.intersted?.firstName}
          />

          <TextContainer
            title='Middle Name'
            data={applicant?.intersted?.middleName}
          />

          <TextContainer
            title='Last Name'
            data={applicant?.intersted?.lastName}
          />
          <TextContainer title='Email' data={applicant?.intersted?.email} />
        </div>
        <div className={styles["personal-container2"]}>
          {isApplicant && (
            <TextContainer
              title='Date of Birth'
              data={moment(applicant?.intersted?.dob).format("MMM D, YYYY")}
              date
            />
          )}

          {isApplicant && (
            <TextContainer
              title='Gender '
              data={applicant?.intersted?.gender_Display}
            />
          )}

          <TextContainer
            title='Nationality '
            data={applicant?.intersted?.nationality_Display}
          />

          <TextContainer
            title='Mobile '
            data={applicant?.intersted?.mobile}
            mobile
          />
          {isApplicant && (
            <>
              <TextContainer
                title='WhatsApp Number'
                data={applicant?.intersted?.whatsAppNumber}
                mobile
              />

              <TextContainer
                title='Telephone'
                mobile
                data={applicant?.intersted?.phoneNumber}
              />
            </>
          )}
          <TextContainer
            title='How did you hear'
            data={applicant?.intersted?.howDoYouHear_Display}
          />
        </div>
        {isApplicant ? (
          <>
            {" "}
            <div className={styles["personal-container2"]}>
              <TextContainer
                title='Passport No.'
                data={applicant?.intersted?.passportNumber}
              />
              {applicant?.intersted?.nationality_Display === "Emirate" && (
                <>
                  <TextContainer
                    title='Emirates ID No.'
                    data={applicant?.intersted?.emiratesId}
                  />
                  <TextContainer
                    title='Ethbara No.'
                    data={applicant?.intersted?.etibharaNo}
                  />
                </>
              )}
            </div>
            <div className={styles["personal-container2"]}>
              <TextContainer
                title='Family Book No.'
                data={applicant?.intersted?.familyBookNumber}
              />

              {/* <div>
                <span>Family Book</span>
                <div>
                  {" "}
                  <img src={attachment} alt='attachment' /> :{" "}
                  {applicant?.familyBook ? (
                    <>
                      {applicant.familyBook}{" "}
                      <img src={availableIcon} alt='available' />{" "}
                    </>
                  ) : (
                    <>
                      No Attachment{" "}
                      <img src={UnavailableIcon} alt='unavailable' />
                    </>
                  )}
                </div>
              </div> */}
              <TextContainer
                title='Family No.'
                data={applicant?.intersted?.familyNo}
              />
              <TextContainer
                title='City No.'
                data={applicant?.intersted?.cityNo}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PersonalInformation;

import React, { useRef, useState } from "react";
import Chart from "react-apexcharts";
import styles from "./StackedColumnGraph.module.css";
import DashboardContainer from "../../DashboardContainer";

const StackedColumnGraph = () => {
  const chartRef = useRef(null);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      stacked: false,
      stackType: "100%",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: "60px",
      },
    },
    xaxis: {
      categories: [],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    colors: ["#9FD5F3", "#0078E6"],
  });

  const [series, setSeries] = useState([
    // {
    //   name: "Interested",
    //   data: [44, 55, 41, 44],
    // },
    // {
    //   name: "Converted",
    //   data: [13, 15, 9, 4],
    // },
  ]);
  const additionalWidth =
    options?.xaxis?.categories.length > 9
      ? (options?.xaxis?.categories.length - 9) * 100
      : 0;
  const calculatedWidth = 400 + additionalWidth;

  return (
    <DashboardContainer
      title='Converted to Interest per Campaign'
      hidden={options?.xaxis?.categories.length > 9}
      chartRef={chartRef}
    >
      <div
        className={`${styles["stacked_graph"]} ${
          options?.xaxis?.categories.length > 9
            ? styles["stacked_graph_scrollable"]
            : ""
        } `}
        style={{ minWidth: calculatedWidth }}
      >
        <div ref={chartRef}>
          <Chart options={options} series={series} type='bar' height={350} />
        </div>
      </div>
    </DashboardContainer>
  );
};

export default StackedColumnGraph;

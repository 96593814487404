import React, { useEffect, useRef, useState } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import styles from "../ApplicantTemplate/ApplicantPage.module.css";
import inactiveUSerIcon from "../../../assets/Imagess/inactiveUserIconTab.svg";
import inactiveRolesIcon from "../../../assets/Imagess/inactiveRolesIcon.svg";
import TableTopHeader from "../../Moecules/TableTopHeader/TableTopHeader";
import DeletePopup from "../../Moecules/Custom/DeletePopup";
import UserModal from "../../Moecules/Users/UserModal";
import AddRolesModal from "../../Moecules/Users/RolesModal/AddRolesModal";
import AssignUserModal from "../../Moecules/Users/RolesModal/AssignUserModal";
import assignuserICon from "../../../assets/Imagess/assignUser.svg";
import plus from "../../../assets/Imagess/plusvector.svg";
import {
  useDeleteUser,
  useExportTableUsers,
  useFetchUsers,
} from "../../../Hooks/Users";
import { toast } from "react-toastify";
import ApplicantGroupTab from "../../Atoms/Tabs/ApplicantGroupTab";
import Buttons from "../../Atoms/Buttons/Buttons";
import * as tableHeader from "../../Moecules/TableHeader/UserHeader";
import TableComponent from "../../Moecules/TableComponent/TableComponent";
import {
  useDeleteRoles,
  useExportTableRoles,
  useFetchRoles,
} from "../../../Hooks/Roles";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";

const UsersPage = () => {
  const printRef = useRef();

  const [selected, setSelected] = useState("users");
  const [mode, setMode] = useState("users");
  const [deleteMode, setDeleteMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [assignRole, setAssignRole] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("asc");
  const [usersPage, setUsersPage] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);
  const [selectedUser, setSelectedUser] = useState({});
  const [search, setSearch] = useState("");
  const [roleDetails, setRoleDetails] = useState([]);

  const {
    data: users,
    refetch: refetchUsers,
    isLoading: isUsersLoading,
  } = useFetchUsers({
    pageSize: usersPageSize,
    pageNumber: usersPage,
    searchBy: search,
    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
    selected: selected == "users",
  });
  const {
    data: roles,
    isLoading: isRolesLoading,
    refetch: refetchRoles,
  } = useFetchRoles({
    selected: selected == "users" ? false : true,
    searchBy: search,
    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
  });
  const exportTableUsers = useExportTableUsers();
  const exportRolesTable = useExportTableRoles();
  const { mutate: deleteUser, isLoading: isDeletingUser } = useDeleteUser();
  const { mutate: deleteRole, isLoading: isDeletingrole } = useDeleteRoles();

  const handleAddClick = () => {
    setIsModalOpen(true);
    setModalMode("add");
    setRoleDetails([]);
  };

  const handleEditClick = (e, item) => {
    e.stopPropagation();
    setSelectedUser(item);
    setIsModalOpen(true);
    setModalMode("edit");
  };

  const handleDelete = () => {
    if (selected == "users") {
      deleteUser(selectedUser?.userId, {
        onSuccess: () => {
          setDeleteMode(false);
          refetchUsers();
          toast.success("User is deleted successfully");
          setSelectedUser({});
        },
        onError: (error) => {
          console.error("Error deleting email", error);
          toast.error("Something went wrong");
          setDeleteMode(false);
          setSelectedUser({});
        },
      });
    } else {
      deleteRole(selectedUser?.roleId, {
        onSuccess: () => {
          setDeleteMode(false);
          refetchRoles();
          toast.success("Role is deleted successfully");
          setSelectedUser({});
        },
        onError: (error) => {
          console.error("Error deleting email", error);
          toast.error(
            "There are users assigned to this role, so it can't be deleted."
          );
          setDeleteMode(false);
          setSelectedUser({});
        },
      });
    }
  };

  const handleExport = async (exportFunction, fileName) => {
    try {
      const data = await exportFunction.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleExportUsersTable = () => {
    handleExport(exportTableUsers, "UsersTable");
  };

  const handleExportRolesTable = () => {
    handleExport(exportRolesTable, "RolesTable");
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (selected === "users") {
      setMode("users");
      refetchUsers();
    } else {
      setMode("roles");
    }
    setSearch("");
  }, [selected]);

  useEffect(() => {
    return setSelectedUsers([]);
  }, []);

  useEffect(() => {
    if (selected === "users") {
      setMode("users");
    } else {
      setMode("roles");
    }
  }, [usersPage, usersPageSize]);

  useEffect(() => {
    if (selected == "users") {
      refetchUsers({
        orderBy: orderBy,
        orderDir: orderDir,
        pageSize: usersPageSize,
        pageNumber: usersPage,
      });
    } else {
      refetchRoles({
        orderBy: orderBy,
        orderDir: orderDir,
      });
    }
  }, [selected, orderBy, orderDir, usersPageSize, usersPage]);

  useEffect(() => {
    setSelectedUsers([]);
    setSearch("");
    setOrderBy("");
    setUsersPage(1);
    setUsersPageSize(10);
  }, [selected]);

  const userTabContainer = [
    {
      text: "Users",
      icon: inactiveUSerIcon,
      value: "users",
      number: users?.data?.totalCount,
    },
    {
      text: "Roles",
      icon: inactiveRolesIcon,
      value: "roles",
      number: roles?.data?.length,
    },
  ];

  const additionalButtons = (
    <Buttons
      text={mode === "users" ? "New User" : "New Role"}
      icon={plus}
      rounded={true}
      color='#F3223C'
      handleOnClick={handleAddClick}
    />
  );

  const tableData =
    selected === "users"
      ? {
          data: {
            ...users?.data,
            items: users?.data?.items.map((item) => ({
              ...item,
              id: item.userId,
            })),
          },
          refetch: refetchUsers,
          isLoading: isUsersLoading,
          tableHeader: tableHeader.userHeader,
        }
      : {
          data: {
            ...roles?.data,
            items: roles?.data?.map((item) => ({
              ...item,
              id: item.roleId,
            })),
          },
          refetch: refetchRoles,
          isLoading: isRolesLoading,
          tableHeader: tableHeader.rolesHeader,
        };

  const assignUserIcon = (item) => (
    <Tooltip content='Assign Users'>
      <img
        src={assignuserICon}
        alt='icon'
        onClick={() => (setAssignRole((prev) => !prev), setSelectedUser(item))}
      />
    </Tooltip>
  );

  return (
    <>
      <WrappingContainer>
        <div>
          <div className={styles["tab-container"]}>
            <ApplicantGroupTab
              tabContainer={userTabContainer}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div className={styles["table-container"]}>
            <TableTopHeader
              isSearch
              handleOnAdd={handleAddClick}
              handleExport={
                selected === "users"
                  ? handleExportUsersTable
                  : handleExportRolesTable
              }
              search={search}
              setSearch={setSearch}
              additionalButtons={additionalButtons}
              handlePrint={handlePrint}
            />
            <PrintComponent
              ref={printRef}
              title={selected === "users" ? "Users Table" : "Roles Table"}
            >
              <TableComponent
                tableData={tableData}
                visibleColumn={{
                  firstNameEn: true,
                  lastNameEn: true,
                  email: true,
                }}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
                page={usersPage}
                setPage={setUsersPage}
                setSelectedUser={setSelectedUser}
                setOrderBy={setOrderBy}
                setOrderDir={setOrderDir}
                orderDir={orderDir}
                orderBy={orderBy}
                pageSize={usersPageSize}
                setPageSize={setUsersPageSize}
                handleDelete={(e, item) => (
                  setDeleteMode(true),
                  setSelectedUser(item),
                  e.stopPropagation()
                )}
                handleEditClick={handleEditClick}
                editAdd={true}
                // checkBox={selected === "users"}
                pagination={selected === "users"}
                additionalIcon={selected !== "users" ? assignUserIcon : null}
                isOrder
              />
            </PrintComponent>
          </div>
        </div>

        {/* {selectedUsers?.length > 0 && (
          <CustomizedUserBar
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        )} */}
      </WrappingContainer>
      {deleteMode && (
        <DeletePopup
          title={selected === "users" ? "user" : "role"}
          handleOnClose={() => setDeleteMode(false)}
          handleDelete={handleDelete}
          isLoading={isDeletingUser || isDeletingrole}
        />
      )}
      {isModalOpen && mode === "users" && (
        <UserModal
          mode={modalMode}
          title='User'
          handleOnClose={() => (setIsModalOpen(false), setSelectedUser({}))}
          refetchUsers={refetchUsers}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
      {isModalOpen && mode === "roles" && (
        <AddRolesModal
          mode={modalMode}
          title='User'
          handleOnClose={() => (
            setIsModalOpen(false), setSelectedUser({}), setRoleDetails([])
          )}
          refetch={refetchRoles}
          roleDetails={roleDetails}
          setRoleDetails={setRoleDetails}
          selected={selectedUser}
        />
      )}
      {assignRole && (
        <AssignUserModal
          handleOnClose={() => setAssignRole(false)}
          selected={selectedUser}
          setSelected={setSelectedUser}
          refetch={refetchRoles}
        />
      )}
    </>
  );
};

export default UsersPage;

// Queries.js
import React, { useEffect, useState } from "react";
import styles from "./QueryComponent.module.css";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import CustomizedSelectwithCheckbox from "../../../Atoms/Inputs/CustomizedSelectwithCheckbox";
import { useGetValueAPI } from "../../../../Hooks/Audience";
import plusIcon from "../../../../assets/Imagess/Users/UsersAssignment/plusIcon.svg";
import closeIcon from "../../../../assets/Imagess/Users/UsersAssignment/closeIcon.svg";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import Loader from "../../Loader/Loader";
import { useParams } from "react-router-dom";
import Spinner from "../../Loader/Spinner";
const andOrOptions = [
  { value: true, text: "and" },
  { value: false, text: "or" },
];

const operatorOptions = [
  { value: true, text: "In" },
  { value: false, text: "Not In" },
];

const Queries = ({
  index,
  query,
  handleRemoveQuery,
  handleValueIdsChange,
  handleUpdateQuery,
  handleAddQuery,
  disabled,
  fieldsOptionsById,
  queries,
}) => {
  const { id } = useParams();
  const [valueApiState, setValueApiState] = useState({});
  const [filteredValues, setFilterValues] = useState([]);
  const [valueId, setValueId] = useState("");
  const [valueLabel, setValueLabel] = useState("");

  const {
    data: values,
    refetch: refetchValues,
    isLoading: isValuesLoading,
  } = useGetValueAPI(filteredValues && filteredValues[0]?.valueAPI);

  useEffect(() => {
    setFilterValues(
      fieldsOptionsById?.data?.filter(
        (item) => item.audienceOptionId === valueApiState
      )
    );
    setValueId(filteredValues && filteredValues[0]?.id);
    setValueLabel(filteredValues && filteredValues[0]?.label);
  }, [valueApiState, fieldsOptionsById]);

  useEffect(() => {
    setValueApiState(query.audienceOptionId);
  }, [query.audienceOptionId]);

  useEffect(() => {
    refetchValues(filteredValues && filteredValues[0]?.valueAPI);
    setValueId(filteredValues && filteredValues[0]?.id);
    setValueLabel(filteredValues && filteredValues[0]?.label);
  }, [filteredValues, valueId, valueLabel]);


  return (
    <div className={styles["queryContainer"]}>
      <CustomizedSelect
        label='And/Or'
        options={andOrOptions}
        placeholder='Select'
        value={query.andOr === "" ? undefined : query.andOr}
        onChange={(value) => handleUpdateQuery(index, "andOr", value)}
        height='38px'
        disabled={disabled || index === 0}
        grey={index === 0}
        notAbsolute
      />
      <CustomizedSelect
        label='Fields'
        placeholder='Select'
        options={fieldsOptionsById?.data?.map(
          ({ audienceOptionId, fieldDisplay }) => ({
            value: audienceOptionId,
            text: fieldDisplay,
          })
        )}
        value={query.audienceOptionId}
        onChange={(value) =>
          handleUpdateQuery(index, "audienceOptionId", value)
        }
        height='38px'
        disabled={disabled}
        notAbsolute
      />
      <CustomizedSelect
        label='Operator'
        options={operatorOptions}
        placeholder='Select'
        value={query.inNotIn === "" ? undefined : query.inNotIn}
        onChange={(value) => handleUpdateQuery(index, "inNotIn", value)}
        height='38px'
        disabled={disabled}
        notAbsolute
      />

<CustomizedSelectwithCheckbox
  label='Value'
  options={values?.data?.map((item) => ({
    value: item[valueId] != null ? item[valueId].toString() : "", // Convert to string
    text: item[valueLabel],
  })) || []} // Default to an empty array if values is undefined
  placeholder='Select'
  value={query.valueIds}
  onChange={(selectedValues) =>
    handleValueIdsChange(selectedValues, index)
  }
  minHeight='38px'
  disabled={!query.audienceOptionId || disabled}
  notAbsolute
  isLoading={isValuesLoading}
/>
      {!disabled && (
        <div className={styles["queryContainer_icons"]}>
          {queries.length !== 1 && (
            <Tooltip content='Remove'>
              <img
                src={closeIcon}
                alt='close'
                onClick={() => handleRemoveQuery(queries.length - 1)}
              />
            </Tooltip>
          )}
          <Tooltip content='Add'>
            <img src={plusIcon} alt='plus' onClick={handleAddQuery} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Queries;

import React, { useEffect, useRef, useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import TableComponent from "../../TableComponent/TableComponent";
import * as tableHeader from "../../TableHeader/CampaignAudienceHeader";
import TableTopHeader from "../../TableTopHeader/TableTopHeader";
import ColumnVisibility from "../../TableTopHeader/ColumnVisibility";
import {
  useExportAudienceTable,
  useFetchAudienceById,
  useShowAudienceResult,
} from "../../../../Hooks/Audience";
import EmptyText from "../../Custom/EmptyText";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../Custom/ExportComponent/PrintComponent";
import { useExportCampaignAudience } from "../../../../Hooks/Campaign";
import { useParams } from "react-router-dom";
import NoDataComponent from "../../Custom/NoData/NoDataComponent";

const CampaignAudience = ({ campaignAudience }) => {
  const printRef = useRef();
  const { id } = useParams(); // Ensure that id is coming from useParams
  const [usersPage, setUsersPage] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);
  const [visibleColumn, setVisibleColumn] = useState({
    name: true,
    termName: true,
    nationality: true,
    email: true,
    mobile: true,
    activatedOn: true,
  });

  const { mutateAsync: exportCampaignAudience } = useExportCampaignAudience();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleExport = async () => {
    if (!id) {
      console.error("Campaign ID is undefined");
      return;
    }
    try {
      const data = await exportCampaignAudience(id);
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "CampaignAudienceTable.xlsx";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected Blob.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const tableData = {
    data: {
      items: campaignAudience.map((item) => ({
        ...item,
        id: item.applicantId,
      })),
    },
    // refetch: refetchTypes,
    // isLoading: isOptionsLoading || isOptionsParentLoading,
    tableHeader: tableHeader.campaignAudienceTableHeader,
  };

  const rightButtons = (
    <ColumnVisibility
      tableHeader={tableHeader.campaignAudienceTableHeader}
      visibleColumn={visibleColumn}
      setVisibleColumn={setVisibleColumn}
    />
  );

  return (
    <WhiteContainer white>
      {campaignAudience?.length > 0 ? (
        <>
          <TableTopHeader
            handleExport={handleExport}
            leftComponent={
              <div>
                <h1 style={{ fontWeight: "700", fontSize: "16px" }}>
                  Future Learners League
                </h1>
                <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
                  Total: {campaignAudience?.length}
                </h3>
              </div>
            }
            rightButtons={rightButtons}
            handlePrint={handlePrint}
          />
          <PrintComponent ref={printRef} title='Campaign Audience'>
            <TableComponent
              tableData={tableData}
              isLoading={false}
              // pagination
              // page={usersPage}
              // setPage={setUsersPage}
              // pageSize={usersPageSize}
              // setPageSize={setUsersPageSize}
              visibleColumn={visibleColumn}
              columnVisibility
            />
          </PrintComponent>
        </>
      ) : (
        // <EmptyText text="Audience" />
        <NoDataComponent text='Audience' />
      )}
    </WhiteContainer>
  );
};

export default CampaignAudience;

import React, { useEffect, useState } from "react";
import styles from "./ApplicantLogs.module.css";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import SingleTab from "../../SingleApplicant/SingleTab";
import Logs from "../../Custom/Logs/Logs";

const options = [
  { value: 0, text: "Latest" },
  { value: 1, text: "Earliest" },
];

const ApplicantLogs = ({ logs, isProspectLead, applicantLeadLogs }) => {
  const [selected, setSelected] = useState("Prospect");
  const [logsToMap, setLogsToMap] = useState(logs);
  const [sortOrder, setSortOrder] = useState(0);

  const handleSortChange = (selectedOption) => {
    const order = selectedOption.value;
    setSortOrder(order);

    const sortedLogs = [...logsToMap].sort((a, b) => {
      if (order === 0) {
        return new Date(b.logDate) - new Date(a.logDate);
      } else {
        return new Date(a.logDate) - new Date(b.logDate);
      }
    });

    setLogsToMap(sortedLogs);
  };

  useEffect(() => {
    if (selected === "Prospect") {
      setLogsToMap(logs);
    } else {
      setLogsToMap(applicantLeadLogs);
    }
  }, [selected, logs, applicantLeadLogs]);

  return (
    <div className={styles["logs-cont"]}>
      <div className={styles["logsTimeline-cont"]}>
        <div className={styles["history"]}>
          <h4>History Logs</h4>
          <CustomizedSelect
            noborder={true}
            placeholder='Most Recent'
            options={options}
            value={sortOrder}
            onChange={handleSortChange}
            unClear
          />
        </div>
        {isProspectLead && (
          <div className={styles["tab-singleapp-container"]}>
            <SingleTab
              text='Prospect'
              handleOnClick={() => setSelected("Prospect")}
              active={"Prospect" === selected}
              fontSize={true}
            />
            <SingleTab
              text='Lead'
              handleOnClick={() => setSelected("Lead")}
              active={"Lead" === selected}
              fontSize={true}
            />
          </div>
        )}

        <Logs logs={logsToMap} />
      </div>
    </div>
  );
};

export default ApplicantLogs;

import React, { useState } from "react";
import styles from "./RolesDropDown.module.css";
import Toggle from "../../Atoms/Inputs/Toggle";
import CheckBox from "../../Atoms/Inputs/CheckBox";
import { useFetchSidbarPages } from "../../../Hooks/SideBAr";
import Loader from "../Loader/Loader";
import { useFetchPageActions } from "../../../Hooks/Roles";
import upArrow from "../../../assets/Imagess/uparrow.svg";
import dropdownicon from "../../../assets/Imagess/dropdownicon.svg";
import dashboardInactive from "../../../assets/Imagess/dashboardInactive.svg";
import dashboardactive from "../../../assets/Imagess/dashboardactive.svg";
const RolesDropdownWithChildPages = ({
  roleDetails,
  setRoleDetails,
  isAddingRole,
}) => {
  const [openDiv, setOpenDiv] = useState("");

  const { data: pages, isLoading } = useFetchSidbarPages();
  const { data: actionPages } = useFetchPageActions();
  console.log("Vsvnskd", actionPages);
  const handlePageCheckboxChange = (pageId) => {
    const existingPage = roleDetails?.find((item) => item.pageId === pageId);

    if (!existingPage) {
      setRoleDetails([...roleDetails, { pageId, pageActionId: "" }]);
    } else {
      const updatedRoleDetails = roleDetails.filter(
        (item) => item.pageId !== pageId
      );
      setRoleDetails(updatedRoleDetails);
    }
  };

  const handleChildPageCheckboxChange = (pageId, pageActionId) => {
    const existingEntryIndex = roleDetails?.findIndex(
      (item) => item.pageId === pageId && item.pageActionId === pageActionId
    );

    let updatedRoleDetails;

    if (existingEntryIndex === -1) {
      updatedRoleDetails = [...roleDetails, { pageId, pageActionId }];
    } else {
      updatedRoleDetails = roleDetails?.filter(
        (item, index) => index !== existingEntryIndex
      );
    }

    setRoleDetails(updatedRoleDetails);
  };

  const isPageSelected = (pageId) =>
    roleDetails.some((item) => item.pageId === pageId);

  const isChildPageSelected = (pageId, childPageId) =>
    roleDetails.some(
      (item) => item.pageId === pageId && item.pageActionId === childPageId
    );

  const handleOnClick = (value) =>
    setOpenDiv((prevOpenDiv) => (prevOpenDiv === value ? "" : value));

  if (isAddingRole || isLoading) {
    return <Loader />;
  }

  console.log("Role Details:", roleDetails);

  return (
    <div className={styles.Rolescontainer}>
      <div className={styles.subTitleContainer}>
        <h4>Privilege Actions</h4>
      </div>

      {actionPages?.data?.pageActions?.map((page) => (
        <div key={page.pageId}>
          <div
            className={styles.container}
            onClick={() => handleOnClick(page.pageName)}
          >
            <div className={styles.subTitleContainer}>
              <CheckBox
                onChange={() => handlePageCheckboxChange(page.pageId)}
                value={isPageSelected(page.pageId)}
              />
              <h4
                className={
                  openDiv === page.pageName
                    ? styles.activeTitle
                    : styles.inactiveTitle
                }
              >
                {page.name}
              </h4>
            </div>{" "}
            {page.childPages.length > 0 && (
              <img
                src={
                  openDiv === page.pageName &&
                  roleDetails?.find((item) => item.pageId === page.pageId)
                    ? upArrow
                    : dropdownicon
                }
                alt='dropdown'
              />
            )}
          </div>

          {openDiv === page.pageName && isPageSelected(page.pageId) && (
            <div
              className={`${styles.openBox} ${
                openDiv === page.pageName ? styles.active : ""
              }`}
            >
              <div className={styles.subopenBox}>
                {page.childPages.length > 0 &&
                  page.childPages.map((child) => (
                    <div
                      key={child.pageId}
                      className={styles.subTitleContainer}
                    >
                      <CheckBox
                        onChange={() => handlePageCheckboxChange(child.pageId)}
                        value={isPageSelected(child.pageId)}
                      />
                      <h4
                        className={
                          isPageSelected(child.pageId)
                            ? styles.activeTitle
                            : styles.inactiveTitle
                        }
                      >
                        {child.name}
                      </h4>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RolesDropdownWithChildPages;

import React from "react";
import WhiteBoxContainer from "../../../HOC/WrappingContainer/WhiteBoxContainer";
import styles from "./QueryComponent.module.css";
import arrowBox from "../../../../assets/Imagess/Users/UsersAssignment/expandArrow.svg";
import QueryBar from "./QueryBar";
import TitleImageComponent from "../Title_SubTitle/TitleImageComponent";
const QueryComponent = ({
  data,
  onClick,
  isOpen,
  disabled,
  color,
  queryType,
  setQueryState,
  queries,
  isReset,
}) => {
  return (
    <WhiteBoxContainer>
      <div className={styles["container"]}>
        <div
          className={`${styles["title_container"]} ${
            isOpen ? styles["title_openContainer"] : ""
          }`}
          onClick={onClick}
          style={{
            backgroundColor: color ? "#ECF4FF" : "",
            padding: color ? "12px" : "",
            borderRadius: color ? "4px" : "",
          }}
        >
          <TitleImageComponent
            icon={data.icon}
            text={data.title}
            fontSize='20px'
            fontWeight='600'
          />
          <img
            src={arrowBox}
            alt='arrowBox'
            className={`${styles["img"]} ${isOpen ? styles["rotate_img"] : ""}`}
          />
        </div>
        <div
          className={`${styles["queryBar_container"]} ${
            isOpen ? styles.open : ""
          }  `}
        >
          <QueryBar
            disabled={disabled}
            queryType={queryType}
            setQueryState={setQueryState}
            fetchedQueries={queries}
            isReset={isReset}
          />
        </div>
      </div>
    </WhiteBoxContainer>
  );
};

export default QueryComponent;

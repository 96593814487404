import React, { useContext } from "react";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { useFetchGenderGraphData } from "../../../../../Hooks/Dashboard";
import GenderGraph from "./GenderGraph";

const GenderGraphComponent = () => {
  const { date, setDate, applicationStart } = useContext(ApplicationContext);

  const { data, isLoading } = useFetchGenderGraphData({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  return <GenderGraph isLoading={isLoading} genderData={data?.data} />;
};

export default GenderGraphComponent;

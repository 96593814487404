import React, { useEffect, useRef, useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import styles from "../../../Template/SettingsTemplate/SettingsPage.module.css";
import uploadbulk from "../../../../assets/Imagess/bulkicon.svg";
import DeletePopup from "../../Custom/DeletePopup";
import SettingsTopHeader from "../SettingsMolecules/SettingsTopHeader";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import TitlePageText from "../../../Atoms/Texts/TitlePageText";
import { useDeleteTerm, useFetchTerms } from "../../../../Hooks/Settings";
import { toast } from "react-toastify";
import TermsModal from "../SettingsModal/TermsModal";
import ViewTermModal from "../SettingsModal/ViewTermModal";
import TableTerms from "../TableSettings/TableTerms";
import { useReactToPrint } from "react-to-print";
const TermSettings = ({ title, type, search, setSearch, canAdd }) => {
  const printRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [deletMode, setDeleteMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("desc");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: termsData,
    refetch: refetchTerms,
    isLoading: isLoadingTerms,
  } = useFetchTerms({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: search,
    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
    type: type,
  });
  const { mutate: deleteTerm, isLoading: isDeleteTerm } = useDeleteTerm();
  const handleAddClick = () => {
    setIsModalOpen(true);
    setModalMode("add");
  };

  const handleEditClick = (e, item) => {
    e.stopPropagation();

    setIsModalOpen(true);
    setModalMode("edit");
    setSelectedItem(item);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem({});
  };

  const handleDelete = () => {
    deleteTerm(selectedItem?.academicTermId, {
      onSuccess: () => {
        setDeleteMode(false);
        refetchTerms();
        toast.success("Deleted successfully");
        setSelectedItem({});
      },
      onError: (error) => {
        console.error("Error deleting email", error);
        toast.error(
          "This option has been used by an applicant and cannot be deleted."
        );
        setDeleteMode(false);
        setSelectedItem({});
      },
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    refetchTerms({ orderBy: orderBy, orderDir: orderDir, search: search });
  }, [orderBy, orderDir]);

  const tableData = {
    data: {
      ...termsData?.data,
      items: termsData?.data?.items?.map((item) => ({
        ...item,
        id: item.academicTermId,
      })),
    },
    refetch: refetchTerms,
    isLoading: isLoadingTerms,
  };

  return (
    <>
      <SettingsTopHeader
        title={title === "External Contact" ? "New Contact" : title}
        type={type}
        search={search}
        setSearch={setSearch}
        handleAddClick={handleAddClick}
        data={termsData}
        canAdd={canAdd}
      />
      <TableTerms
        termsData={tableData}
        handleEditClick={handleEditClick}
        setDeleteMode={setDeleteMode}
        setSelectedItem={setSelectedItem}
        isLoadingTerms={isLoadingTerms}
        withBorders={true}
        orderBy={orderBy}
        orderDir={orderDir}
        setOrderBy={setOrderBy}
        setOrderDir={setOrderDir}
        refetch={refetchTerms}
        setModalMode={setModalMode}
        setIsModalOpen={setIsModalOpen}
        pagination
        editAdd={canAdd ? true : false}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
      />
      {isModalOpen && modalMode !== "View" && type === 19 ? (
        <TermsModal
          handleOnClose={handleCloseModal}
          type={type}
          mode={modalMode}
          refetchTerms={refetchTerms}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      ) : isModalOpen && modalMode == "View" && type === 19 ? (
        <ViewTermModal
          selectedItem={selectedItem}
          handleOnClose={handleCloseModal}
          handleDelete={() => (setDeleteMode(true), setIsModalOpen(false))}
          handleEdit={(e) => (
            handleEditClick(e, selectedItem), setModalMode("Edit")
          )}
        />
      ) : (
        ""
      )}{" "}
      {deletMode && (
        <DeletePopup
          handleOnClose={() => (setDeleteMode(false), setSelectedItem({}))}
          title={title}
          handleDelete={handleDelete}
          isLoading={isDeleteTerm}
        />
      )}
      {/* <div style={{ display: "none" }}>
        <PrintComponent ref={printRef} title={title} data={tableData} />
      </div> */}
    </>
  );
};

export default TermSettings;

import React, { useEffect, useState } from "react";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import TitlePageHeader from "../../../Moecules/Settings/SettingsMolecules/TitlePageHeader";
import TextInput from "../../../Atoms/Inputs/TextInput";
import folder from "../../../../assets/Imagess/folderICon.svg";
import LPCQuery from "../../../Moecules/Custom/QueryComponent/LeadsProspectContactQuery.jsx/LPCQuery";
import WhiteBoxContainer from "../../../HOC/WrappingContainer/WhiteBoxContainer";
import NewAudienceTable from "../../../Moecules/Audience/NewAudienceTable";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import {
  useAddNewAudience,
  useFetchAudienceById,
  useShowAudienceResult,
} from "../../../../Hooks/Audience";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Moecules/Loader/Loader";
import Spinner from "../../../Moecules/Loader/Spinner";

const NewAudiencePage = ({ mode }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [audienceTitle, setaudienceTitle] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [audienceData, setAudienceData] = useState([]);
  const [queryState, setQueryState] = useState({
    0: [],
    1: [],
    2: [],
  });
  const [isMounted, setIsMounted] = useState(false);
  const [valuesToExport, setValuesToExport] = useState({});

  const {
    mutate: showResult,
    isLoading: isShowingAudience,
    refetch: refetchshowingResult,
  } = useShowAudienceResult();
  const { mutate: addNewAudience, isLoading: isAddingAudience } =
    useAddNewAudience();
  const { data: audienceByIdData, isLoaing: isAudienceLoading } =
    useFetchAudienceById(id);

  const isQueryStateNotEmpty = Object.values(queryState).some(
    (queries) => queries?.length > 0
  );

  useEffect(() => {
    if (id) {
      setaudienceTitle(audienceByIdData?.data?.audience?.audienceName);
      setQueryState({
        0: audienceByIdData?.data?.audienceDetails
          ?.filter((item) => item.audienceType == 0)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
        1: audienceByIdData?.data?.audienceDetails
          ?.filter((item) => item.audienceType == 1)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
        2: audienceByIdData?.data?.audienceDetails
          ?.filter((item) => item.audienceType == 2)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
      });
    }
  }, [audienceByIdData]);

  const handleShowResult = () => {
    const mappedQueryState = {
      prospectFilters: queryState[0],
      leadFilters: queryState[1],
      externalContactFilters: queryState[2],
      isAudience: true,
    };
    setValuesToExport(mappedQueryState);
    const hasEmptyValueIds = (filters) => {
      return filters.some(
        (filter) =>
          filter.andOr == undefined ||
          !filter.audienceOptionId ||
          filter.inNotIn == undefined ||
          !filter.valueIds
      );
    };

    if (
      hasEmptyValueIds(mappedQueryState.prospectFilters) ||
      hasEmptyValueIds(mappedQueryState.leadFilters) ||
      hasEmptyValueIds(mappedQueryState.externalContactFilters)
    ) {
      toast.error("Please fill all query fields before proceeding.");
      return;
    }

    showResult(mappedQueryState, {
      onSuccess: (data) => {
        setAudienceData(data?.data);
        if (
          !data?.data?.leads?.length > 0 &&
          !data?.data?.applicants?.length > 0 &&
          !data?.data?.externalContacts?.length > 0
        ) {
          toast.error("No Audience Found");
        }
      },
    });
  };

  const isShowResult =
    audienceData?.leads?.length > 0 ||
    audienceData?.applicants?.length > 0 ||
    audienceData?.externalContacts?.length > 0;

  const handleSave = () => {
    if (!audienceTitle.trim()) {
      toast.error("Please enter a title for the audience.");
      return;
    }

    if (!isQueryStateNotEmpty) {
      toast.error("Please add at least one query state.");
      return;
    }

    addNewAudience(
      {
        campaignAudienceId: id || "00000000-0000-0000-0000-000000000000",
        audienceName: audienceTitle,
        audienceFilters: {
          prospectFilters: queryState[0],
          leadFilters: queryState[1],
          externalContactFilters: queryState[2],
        },
      },
      {
        onSuccess: () => {
          navigate("/home/campaign-audience");
          if (id) {
            toast.success(`${audienceTitle} is updated successfully`);
          } else {
            toast.success(`${audienceTitle} is added successfully`);
          }
        },
        onError: (error) => {
          toast.error(`${error.response.data}`);
        },
      }
    );
  };

  const handleReset = () => {
    setQueryState({
      0: [],
      1: [],
      2: [],
    });
    setAudienceData([]);
    setIsReset(true);
    setTimeout(() => {
      setIsReset(false);
    }, 1000);
  };

  const secondButton = isShowingAudience ? (
    <Spinner />
  ) : (
    <div style={{ display: "flex", gap: "5px" }}>
      <ColoredButton
        unfilled
        title='Reset Result'
        rounded
        handleOnClick={handleReset}
      />
      <ColoredButton
        blue
        title={"Show Result"}
        rounded
        handleOnClick={handleShowResult}
      />
    </div>
  );

  return (
    <WrappingContainer>
      <WhiteBoxContainer>
        <TitlePageHeader
          handleOnClick={handleSave}
          title={id ? `Update Audience` : "Create New Audience"}
          buttonText={id ? "Update" : "Save"}
          secondButton={isQueryStateNotEmpty ? secondButton : null}
          isLoading={isAddingAudience}
        />
        <TextInput
          label='Title'
          placeholder='Enter Title'
          icon={folder}
          value={audienceTitle}
          onChange={(value) => setaudienceTitle(value)}
        />
      </WhiteBoxContainer>

      <LPCQuery
        queryState={queryState}
        setQueryState={setQueryState}
        isReset={isReset}
      />
      {isShowingAudience ? (
        <Loader />
      ) : (
        isShowResult && (
          <NewAudienceTable
            isLoading={isShowingAudience}
            refetch={refetchshowingResult}
            audienceData={audienceData}
            valuesToExport={valuesToExport}
          />
        )
      )}
    </WrappingContainer>
  );
};

export default NewAudiencePage;

import React from "react";
import styles from "./CAmpaignReflection.module.css";
import DashboardContainer from "../DashboardContainer";
import RateContainer from "../ApplicationAnalysis/Rate/RateChart/RateContainer/RateContainer";
import CampaignStatistics from "./CampaignStatistics/CampaignStatistics";
import StackedColumnGraph from "./StackedColumGraph/StackedColumnGraph";

const CampaignReflection = () => {
  return (
    <div className={styles["gridTwo"]}>
      <CampaignStatistics />
      <StackedColumnGraph />
    </div>
  );
};

export default CampaignReflection;

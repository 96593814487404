import React, { useEffect, useState } from "react";
import styles from "../RightSideComp.module.css";
import { useFormikContext } from "formik";
import CustomizedSelect from "../../../../../../Atoms/Inputs/CustomizedSelect";
import {
  useGetFieldOption,
  useGetValueAPI,
} from "../../../../../../../Hooks/Audience";
const operatorOptions = [
  { value: 1, text: "In" },
  { value: 0, text: "Not In" },
];

const ConditionalQueries = ({ view }) => {
  const formik = useFormikContext();

  const [valueApiState, setValueApiState] = useState({});
  const [filteredValues, setFilterValues] = useState([]);
  const [valueId, setValueId] = useState("");
  const [valueLabel, setValueLabel] = useState("");
  const { data: fieldsOptionsById, refetch: refetchFieldsOptionsById } =
    useGetFieldOption(0);
  const {
    data: values,
    refetch: refetchValues,
    isLoading: isValuesLoading,
  } = useGetValueAPI(filteredValues && filteredValues[0]?.valueAPI);

  useEffect(() => {
    setFilterValues(
      fieldsOptionsById?.data?.filter(
        (item) => item.audienceOptionId === valueApiState
      )
    );
    setValueId(filteredValues && filteredValues[0]?.id);
    setValueLabel(filteredValues && filteredValues[0]?.label);
  }, [valueApiState, fieldsOptionsById]);

  useEffect(() => {
    setValueApiState(formik.values.field);
  }, [formik.values.field]);

  useEffect(() => {
    refetchValues(filteredValues && filteredValues[0]?.valueAPI);
    setValueId(filteredValues && filteredValues[0]?.id);
    setValueLabel(filteredValues && filteredValues[0]?.label);
  }, [filteredValues, valueId, valueLabel]);

  return (
    <div className={styles["conditionsCont"]}>
      <div className={styles["email_subContainer"]}>
        <CustomizedSelect
          label='Field'
          placeholder='Select'
          value={formik.values.field}
          name='field'
          options={fieldsOptionsById?.data?.map(
            ({ audienceOptionId, fieldDisplay }) => ({
              value: audienceOptionId,
              text: fieldDisplay,
            })
          )}
          required={true}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.field}
          disabled={view}
          touched={formik.touched.field}
        />
        <CustomizedSelect
          label='Operation'
          placeholder='Select'
          options={operatorOptions}
          value={formik.values.operation}
          required={true}
          name='operation'
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.operation}
          disabled={view}
          touched={formik.touched.operation}
        />
      </div>
      <CustomizedSelect
        label='Value'
        placeholder='Select'
        options={values?.data?.map((item) => ({
          value: item[valueId]?.toString(),
          text: item[valueLabel],
        }))}
        name='value'
        value={formik.values.value}
        required={true}
        onChange={(name, value) => {
          formik.setFieldValue(name, value);
        }}
        errors={formik.errors.value}
        disabled={view}
        touched={formik.touched.value}
      />
    </div>
  );
};

export default ConditionalQueries;

import React, { useContext, useRef } from "react";
import DashboardContainer from "../../DashboardContainer";
import Chart from "react-apexcharts";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { useFetchApplicationCategories } from "../../../../../Hooks/Dashboard";
import styles from "./ApplicationCategories.module.css";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const ApplicationCategories = () => {
  const chartRef = useRef(null);

  const { date, setDate, applicationStart } = useContext(ApplicationContext);

  const { data, isLoading } = useFetchApplicationCategories({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });
  const appliationCategories = data?.data || [];
  const series = appliationCategories?.map((i) => i.value) || [];
  const categories = appliationCategories?.map((i) => i.label) || [];

  const options = {
    series: [
      {
        data: series,
      },
    ],
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: data?.data?.length === 1 ? "10%" : "50%",
        barHeight: "100%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#F3223C", "#015699", "#7BB1F6"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1b224c"],
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          fontWeight: "600",
          colors: ["#1b224c"],
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      categories: ["Undergraduate", "Graduate", "Visiting"],
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const categoryName = w?.globals?.labels[dataPointIndex];
        return `
        <div style="padding: 10px; font-size: 12px;">
          <strong>${categoryName}:${series[dataPointIndex]}</strong>
          
        </div>
      `;
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            bar: {
              barHeight: "40px",
            },
          },
          dataLabels: {
            style: {
              fontSize: "8px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
        },
      },
    ],
  };
  const handleDownload = () => {
    const title = "ApplicationCategories_Graph";
    downloadCSV(series, categories, title);
  };

  return (
    <DashboardContainer
      title='Application Categories'
      handleExport={handleDownload}
      chartRef={chartRef}
      noData={appliationCategories?.length === 0}
      isLoading={isLoading}
    >
      <div
        className={`${styles["stacked_graph"]} ${
          appliationCategories?.length > 5
            ? styles["stacked_graph_scrollable"]
            : ""
        }`}
        ref={chartRef}
      >
        <Chart
          options={options}
          series={options?.series}
          type='bar'
          height={300}
        />{" "}
      </div>
    </DashboardContainer>
  );
};

export default ApplicationCategories;

export const newAudienceTableHeader = [
  { title: "Name", key: "fullName" },
  { title: "Contact Type", key: "contactType_Display" },
  { title: "Term", key: "selectedTerm_Display" },
  { title: "Field of Interest", key: "fieldOfInterest_Display" },
  { title: "Program", key: "applicationStart_Display" },
  { title: "Nationality", key: "nationality_Display" },
  { title: "Type", key: "type" },
  { title: "Status", key: "statusText", flag: "status" },

];

import React from "react";
import MoreButton from "../../Custom/PopOver/MoreButton";
import PopOver from "../../Custom/PopOver/PopOver";
import moreIcon from "../../../../assets/Imagess/moreicon.svg";
import pdfIcon from "../../../../assets/Imagess/Dashboard/pdf.svg";
import iconFile from "../../../../assets/Imagess/Dashboard/icon _file xls.svg";
import printicon from "../../../../assets/Imagess/printIcon.svg";
const ExportDashboardButton = ({
  handlePrint,
  handleExport,
  handleDownloadPDF,
}) => {
  const options = [];

  if (handleExport) {
    options.push({
      title: "Export as Excel",
      icon: iconFile,
      handleFunction: handleExport,
    });
  }

  if (handleDownloadPDF) {
    options.push({
      title: "Export as PDF",
      icon: pdfIcon,
      handleFunction: () => handleDownloadPDF(),
    });
  }

  if (handlePrint) {
    options.push({
      title: "Print",
      icon: printicon,
      handleFunction: handlePrint,
    });
  }

  return (
    <MoreButton icon={moreIcon} toolTipText='More'>
      <PopOver stop info={options} />
    </MoreButton>
  );
};

export default ExportDashboardButton;

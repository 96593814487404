import React from "react";
import EmptyText from "../EmptyText";
import moment from "moment";
import AcademicIngoComleteLolgo from "../../../../assets/Imagess/Logs/AcademicInfoCompleteLogo.svg";
import ApplicantCreatedLogo from "../../../../assets/Imagess/Logs/ApplicantCreatedLogo.svg";
import ApplicantInfoSentLogo from "../../../../assets/Imagess/Logs/ApplicantInfoSentLogo.svg";
import ApplicantionCompleteLogo from "../../../../assets/Imagess/Logs/ApplicationCompleteLogo.svg";
import ApplicationCompletePAyment from "../../../../assets/Imagess/Logs/ApplicationCompletePAymentLogo.svg";
import CampaignEmailLogo from "../../../../assets/Imagess/Logs/CampaignEmailLogo.svg";
import DEclarationInfoCompleteLogo from "../../../../assets/Imagess/Logs/DeclarationInfoCompleteLogo.svg";
import LeadLogo from "../../../../assets/Imagess/Logs/LeadLogo.svg";
import NotesLogo from "../../../../assets/Imagess/Logs/NotesLogo.svg";
import PErsonalInfoCompleteLogo from "../../../../assets/Imagess/Logs/PersonalInfoCompleteLogo.svg";
import ReferenceEmailLogo from "../../../../assets/Imagess/Logs/ReferenceEMailLogo.svg";
import ReferenceUploadedDocumentLogo from "../../../../assets/Imagess/Logs/ReferenceUploadedDocumentLogo.svg";
import ShowedInterestEmailSent from "../../../../assets/Imagess/Logs/ShowedInterestEmailSentLogo.svg";
import ShowedInterestLogo from "../../../../assets/Imagess/Logs/ShowedInterestLogo.svg";
import StatusLogo from "../../../../assets/Imagess/Logs/StatusLogo.svg";
import campaignCreated from "../../../../assets/Imagess/Logs/campaignCreated.svg";
import campaignLaunched from "../../../../assets/Imagess/Logs/campaignLaunched.svg";
import campaignOnHold from "../../../../assets/Imagess/Logs/campaignOnHold.svg";
import campaignDuplicated from "../../../../assets/Imagess/Logs/campaignDuplicated.svg";
import campaignEdited from "../../../../assets/Imagess/Logs/campaignEdited.svg";
import campaignDeleted from "../../../../assets/Imagess/Logs/campaignDeleted.svg";
import campaignCancelled from "../../../../assets/Imagess/Logs/campaignCancelled.svg";
import campaignStopped from "../../../../assets/Imagess/Logs/campaignStopped.svg";
import campaignResumed from "../../../../assets/Imagess/Logs/campaignResumed.svg";
import applicantUnsubscribed from "../../../../assets/Imagess/Logs/applicantUnsubscribed.svg";
const Logs = ({ logs }) => {
  const LogoFunction = (logID) => {
    switch (logID) {
      case 1:
        return ShowedInterestLogo;
      case 2:
        return ShowedInterestEmailSent;
      case 3:
        return ApplicantCreatedLogo;
      case 4:
        return ApplicantInfoSentLogo;
      case 5:
        return PErsonalInfoCompleteLogo;
      case 6:
        return AcademicIngoComleteLolgo;
      case 7:
        return DEclarationInfoCompleteLogo;
      case 8:
        return ApplicationCompletePAyment;
      case 9:
        return CampaignEmailLogo;
      case 10:
        return NotesLogo;
      case 11:
        return StatusLogo;
      case 12:
        return LeadLogo;
      case 13:
        return ReferenceEmailLogo;
      case 14:
        return ReferenceUploadedDocumentLogo;
      case 15:
        return ApplicantionCompleteLogo;
      case 16:
        return campaignCreated;
      case 17:
        return campaignLaunched;
      case 18:
        return campaignOnHold;
      case 19:
        return campaignDuplicated;
      case 20:
        return campaignEdited;
      case 21:
        return campaignDeleted;
      case 22:
        return campaignStopped;
      case 23:
        return campaignCancelled;
      case 24:
        return campaignResumed;
      case 28:
        return applicantUnsubscribed;
      default:
        return StatusLogo;
    }
  };

  return (
    <div className='timeline'>
      {logs?.length > 0 ? (
        <>
          {logs?.map((item) => {
            return (
              <div
                className='timeline__event timeline__event--type1'
                key={item.applicantLogId}
              >
                <div className='timeline__event__icon '>
                  <img src={LogoFunction(item.logId)} alt='icon' />
                </div>
                <div className='timeline__event__content '>
                  <h3>{item.logStatus}</h3>
                  <p>{moment(item.logDate).format("MMM D, YYYY h:mm A")}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <EmptyText text='Logs' />
      )}
    </div>
  );
};

export default Logs;

import React from "react";
import styles from "./CampaignCharts.module.css";
import ColumnChart from "./ColumnChart";
import StackedBarChart from "./StackedBarChart";
import CampaignAgeGraph from "./CampaignAgeGraph";
import CampaignLevelOfEducation from "./CampaignLevelOfEducation";
import CampaignGenderGraoh from "./CampaignGenderGraoh";
const CampaignCharts = ({ campaignDetails }) => {
  return (
    <>
      <div className={styles["chart-div"]}>
        <ColumnChart campaignDetails={campaignDetails} />
        <StackedBarChart
          groupedInterested={campaignDetails?.groupedInterested}
        />
      </div>{" "}
      <div className={styles["chart-div"]}>
        {" "}
        <CampaignAgeGraph />
        <CampaignLevelOfEducation />
        <CampaignGenderGraoh />{" "}
      </div>
    </>
  );
};

export default CampaignCharts;

import React from "react";
import SingleTab from "./SingleTab";
import styles from "./SinglaTab.module.css";
const SingleTabGroup = ({
  tabContainer,
  selected,
  setSelected,
  noPadding,
  rounded,
}) => {
  return (
    <div
      className={styles["tab-singleapp-subcontainer"]}
      style={{
        paddingTop: noPadding || rounded ? "5px" : "",
        paddingBottom: noPadding || rounded ? "5px" : "",
      }}
    >
      {tabContainer.map((item, index) => {
        return (
          <div key={index}>
            <SingleTab
              text={item.text}
              handleOnClick={() => setSelected(item.value)}
              active={item.value === selected ? true : false}
              icon={item.icon}
              blue={item.isNotes}
              rounded={rounded}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SingleTabGroup;

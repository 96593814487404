import React from "react";
import styles from "./CampaignDetailedBox.module.css";
import Title_Subtitle from "../../Custom/Title_SubTitle/Title_Subtitle";
import TitleImageComponent from "../../Custom/Title_SubTitle/TitleImageComponent";
import houseIcon from "../../../../assets/Imagess/Campaign/houseIcon.svg";
import DateContainer from "../../Custom/DateContainer/DateContainer";
import StatusContainer from "../../Custom/StatusContainer";
import CampaignStatisticsBox from "./CampaignStatisticsBox";
import CampaignSettings from "./CampaignSettings";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import { useNavigate } from "react-router-dom";
import FlagComponent from "../../../Atoms/CustomizedAtoms/FlagComponent";

const CampaignDetailedBox = ({ data, settings, contact, refetch }) => {
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/home/campaign-details/${id}`);
  };

  return (
    <Tooltip content='Click for more details about the campaign'>
      <div
        className={styles["campaignDetailsBox_cont"]}
        style={{
          backgroundColor: contact ? "#FFFF" : "",
          // border: contact ? "none" : "",
          cursor: "pointer",
        }}
        onClick={() => handleNavigate(data?.campaignId)}
      >
        <div className={styles["campaignDetailsBox"]}>
          <div className={styles["campaignDetailsBox_titleContainer"]}>
            <div className={styles["notes"]}>
              <Title_Subtitle
                title={data?.campaignName}
                subTitle={data?.notes}
                notes
                opacity='0.8'
              />
            </div>
            {!contact && (
              <div className={styles["term"]}>
                {data?.termText && (
                  <TitleImageComponent
                    icon={houseIcon}
                    text={data?.termText}
                    width='16px'
                    height='16px'
                    fontSize='14px'
                    fontWeight='500'
                  />
                )}
              </div>
            )}
          </div>
          <div className={styles["dateStatus_container"]}>
            <div className={styles["stat_container"]}>
              <div className={styles["datecontainer"]}>
                <DateContainer
                  date={data?.startDate?.substring(0, 10)}
                  label='Start'
                />
                <DateContainer
                  date={data?.endDate?.substring(0, 10)}
                  label='End'
                />

                <StatusContainer status={data?.statusText} />
                {data?.isDeleted && <FlagComponent text='Deleted' />}
              </div>
              {/* {data?.delivered && data?.clicked && data?.responses && (
              <div className={styles["dateStatus_container"]}>
                <CampaignStatisticsBox
                  data={{ number: data?.delivered, text: "Delivered" }}
                />
                <CampaignStatisticsBox
                  data={{ number: data?.clicked, text: "Clicked" }}
                />
                <CampaignStatisticsBox
                  data={{ number: data?.responses, text: "Responses" }}
                />
                <CampaignStatisticsBox
                  data={{ number: data?.bounce, text: "Bounced" }}
                  noBorder
                />
              </div>
            )} */}

              {!contact && (
                <div className={styles["number"]}>
                  <CampaignStatisticsBox
                    data={{ number: data?.delivered ?? 0, text: "Delivered" }}
                  />
                  <CampaignStatisticsBox
                    data={{ number: 0, text: "Clicked" }}
                  />
                  <CampaignStatisticsBox
                    data={{ number: 0, text: "Responses" }}
                  />
                  <CampaignStatisticsBox
                    data={{ number: 0, text: "Bounced" }}
                    noBorder
                  />
                </div>
              )}
            </div>
            <div className={styles["mobile"]}>
              <StatusContainer status={data?.statusText} />
              {data?.isDeleted && (
                <span className={styles["isDeleted_type"]}>
                  {/* <img alt='deleted icon' /> */}
                  <h5>Deleted</h5>
                </span>
              )}
            </div>
            {settings && !data?.isDeleted && (
              <CampaignSettings
                id={data?.campaignId}
                title={data?.campaignName}
                refetch={refetch}
                status={data?.statusText}
              />
            )}{" "}
          </div>
        </div>
        <div className={styles["datecontainer_mobile"]}>
          <DateContainer
            date={data?.startDate?.substring(0, 10)}
            label='Start'
          />
          <DateContainer date={data?.endDate?.substring(0, 10)} label='End' />
        </div>
      </div>
    </Tooltip>
  );
};

export default CampaignDetailedBox;
